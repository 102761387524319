import React, { useState,useEffect, useCallback, useContext} from "react"
import {  IconButton, Tooltip, Menu, MenuItem,ListItemIcon} from '@mui/material'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { useNavigate  } from "react-router-dom";
import { EquilyContext } from "../../common/Context"

export function AccountMenu(props){
   
    const [anchorEl, setAnchorEl] = useState(null)
    const [menuItems, setMenuItems] = useState([])
    const open = Boolean(anchorEl);

    const {user,logout} = useContext(InvestorAccountContext)
    const {updateInvestor} = useContext(EquilyContext)
    
    const navigate = useNavigate ();


    const logoutInvestor = useCallback(() => {
      logout()
      updateInvestor(null)
    },[logout,updateInvestor])

    const myAccount = useCallback(() => {
      navigate("/myaccount")
    },[navigate])

    useEffect(() => {
      if(user.username){
        var items = []
        const logoutItem =   <MenuItem key="logout" onClick={logoutInvestor}>
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>

      const myAccountItem = <MenuItem key="myaccount" onClick={myAccount}>
                          <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                          </ListItemIcon>
                          My Account
                        </MenuItem>

        
        items.push(myAccountItem)
        items.push(logoutItem)
        setMenuItems(items)
      }
    }, [user,logoutInvestor,myAccount]);




    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

   

    return(
        <React.Fragment>
        <Tooltip title="Account settings">
            <IconButton
                color="inherit"  onClick={handleClick}
            >
                <AccountCircleIcon />
            </IconButton>
        </Tooltip>
         <Menu
         anchorEl={anchorEl}
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
           elevation: 0,
           sx: {
             overflow: 'visible',
             filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
             mt: 1.5,
             '& .MuiAvatar-root': {
               width: 32,
               height: 32,
               ml: -0.5,
               mr: 1,
             },
             '&:before': {
               content: '""',
               display: 'block',
               position: 'absolute',
               top: 0,
               right: 14,
               width: 10,
               height: 10,
               bgcolor: 'background.paper',
               transform: 'translateY(-50%) rotate(45deg)',
               zIndex: 0,
             },
           },
         }}
         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
       >

      {menuItems}

      </Menu>
       </React.Fragment>
    )


}