import React, { useState, useContext } from 'react'

import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material'
import FormColumn from '../../common/ui/FormColumn'
import CurrencySelect from "../../common/ui/CurrencySelect";
import {EquilyContext} from '../../common/Context'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { CallAPI } from '../../common/services/api/CallAPI'

export default function ModelPortfolioSubscribe(props){


    const {portfolios,refreshBenchmarks,refreshPortfolios,refreshModelAllocations,refreshReblanceTrackers} = useContext(EquilyContext);
    const {getSession} = useContext(InvestorAccountContext)

    const modelPortfolio = props.modelPortfolio
    const close = props.handleClose
    const open = props.open

    const [portfolioName, setPortfolioName] = useState("")
    const [portfolioValue, setPortfolioValue] = useState(0.0)
    const [currencyCode, setCurrencyCode] = useState("")
    const [subscribing, setSubscribing] = useState(false)
    const [error,setError] = useState(null)
    
    const closeForm = () => {
        setPortfolioName("")
        setError()
        setPortfolioValue(0.0)
        setCurrencyCode("")
        close()
    }
   
    const subscribe = () =>{
        // check if they already have the subscription
        const portfolioSub = portfolios.some( p => p.portfolioName===portfolioName)
        if(portfolioSub)setError(<Alert severity="error">You already have a portfolio of that name!</Alert>)
        else{
            
            setSubscribing(true)
            getSession().then( ({headers}) => {
                 headers['Content-Type'] =  'application/json'
                 var subReq = {
                     modelPortfolioId: modelPortfolio.id,
                     portfolioName: portfolioName,
                     portfolioValue: portfolioValue,
                     currencyCode: currencyCode
                 }
                CallAPI('/store/modelportfolios/subscribe/',{
                    method: 'POST',
                    headers, 
                    body: JSON.stringify(subReq)
                }).then( data =>{
                    setSubscribing(false)
                    refreshModelAllocations()
                    refreshBenchmarks()
                    refreshPortfolios()
                    refreshReblanceTrackers()
                    closeForm()
                })
                .catch((error) => {
                    setSubscribing(false)
                    console.log("There was an error " + error)
                    setError(<Alert severity="error">Error subscribing</Alert>)
                })
            })
        
        }
     

       
    }



    return(
        <React.Fragment>
            <Dialog open={open} onClose={closeForm} aria-labelledby="Subscribe">
                <DialogTitle id="subscribe-title">Subscribe</DialogTitle>
                {error}
                <DialogContent>
                    <FormColumn>
                        <FormControl variant="outlined" fullWidth  >
                            <TextField autoFocus value={portfolioName} onChange={e => setPortfolioName(e.target.value)} id="portfolioName" label="What shall we call your new portfolio" type="text" />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth> 
                            <TextField  value={portfolioValue} onChange={ e=>setPortfolioValue(e.target.value)} id="value" label="How much are you investing"  type="number"/>
                        </FormControl>
                        <FormControl variant="outlined" fullWidth> 
                             <CurrencySelect label="What currency is the portfolio?" setCurrency={setCurrencyCode}/>
                        </FormControl>  
                    </FormColumn >
                 </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={closeForm} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={subscribe} color="primary" autoFocus>
                        {subscribing && <CircularProgress size={14} />}
                        {!subscribing && "Subscribe"}
                    </Button>
                </DialogActions>
     
            </Dialog>
        </React.Fragment>
    )
        
}