import {  Button, Drawer, Grid, IconButton, List, ListItemButton, Typography } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import React from 'react'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { EquilyListItemText } from '../common/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { useMemo } from 'react';
//  { title: "Some Help Here", url: "http://equily.io",contexts:["/","/modelportfoliostore"]},
//{ title: "Some More Help", url: "http://equily.io",contexts:["/","/modelportfoliostore"]},
const allHelpItems = [
   
]



export const HelpButton = styled(Button)(({ theme }) => ({
    maxWidth:'150px', 
    minWidth:'150px', 
    paddingLeft:0,
    paddingRight:0,   
    transformOrigin: '100% 0',
    transform: 'rotate(-90deg) translate(0, -100%)',
    msTransform: 'rotate(-90deg)',
    webkitTransform: 'rotate(-90deg)',
    textAlign: 'center',
    position: 'absolute', 
    right: 0, 
    top: '45%',
  }));



export default function HelpDrawer() {

    const [isOpen, setIsOpen] = React.useState(false);
    const [helpItems, setHelpItems] = useState([])
    const location = useLocation()
  
    const tour = useContext(ShepherdTourContext);
    const defaultHelpItems = useMemo(() => [
        { title: "Start welcome tour", execute: ()=>{tour.start()} ,contexts:["/"]},
        { title: "Build your own smart portfolio in Equily", url: "https://equily.io/equily-onboarding-support/equily-how-to-build-a-model-portfolio",contexts:["/"]},
        { title: "Optimise your portfoli and create a backtest strategy", url: "https://equily.io/equily-onboarding-support/equily-onboarding-how-to-optimize-your-portfolio-step-1",contexts:["/"]},
        { title: "Backtest and analyse multiple results", url: "https://equily.io/equily-onboarding-support/equily-onboarding-step-3-analyzing-back-test-results",contexts:["/"]},
        { title: "Modify your portfolio allocations and then re-test", url: "https://equily.io/equily-onboarding-support/how-to-modify-your-portfolio-allocation-and-then-re-test",contexts:["/"]},
        { title: "Run additional tests and compare results", url: "https://equily.io/equily-onboarding-support/equily-onboarding-step-4-optimizing-running-additional-back-tests-and-comparing-results",contexts:["/"]},
        { title: "Copy allocations", url: "https://equily.io/equily-onboarding-support/how-to-duplicate-allocations",contexts:["/"]},
        { title: "Investments 101", url: "https://equily.io/insights",contexts:["/"]},
    ],[tour])

    useEffect( ()=>{
        const path = location.pathname
        var items  = allHelpItems.filter( (item) => {
            const contexts = item.contexts
            return contexts.includes(path)
        })
        if(items.length===0) items = defaultHelpItems
        setHelpItems(items)
    },[location,defaultHelpItems])

    const executeItem = (item) => {
        if(item.url)window.open(item.url, '_blank').focus();
        else item.execute()
        
    }


  return (
    <>
     <HelpButton  variant="contained"
        color="secondary"
        onClick={() => setIsOpen(true)} sx={{ }}>
        <Typography variant='h6'>Show Me How</Typography>
    </HelpButton>
        <Drawer
        PaperProps={{
            sx: {
              height: '40%',
              top: '35%',
              
            },
          }}
        anchor='right'
        open={isOpen}
        onClose={()=> setIsOpen(false)}
        >

        <Grid direction="column" container>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center" p={1} sx={{backgroundColor: "pantone.pantone1235"}}>
                <Typography variant='h5'>Show me how</Typography> 
                <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="close help"
                      onClick={() => setIsOpen(false)}
                  >
                      <CancelIcon />
                  </IconButton>
            </Grid>
            <Grid p={1} item>
                <List key="HelpMenu"  component="div" disablePadding>
                        {helpItems.map( (item) => {
                            return (
                                <ListItemButton  key={item.title} >
                       
                                <PlayCircleIcon color="secondary" sx={{marginRight:1 }}/>
                                <EquilyListItemText  onClick={() => executeItem(item)} primary={item.title} />
                                </ListItemButton>

                            )
                        })}
                </List>
            </Grid>
        </Grid>


    </Drawer> 
    </>
  )
}
