import { CircularProgress, Grid } from "@mui/material";



export default function Loader(props){


    return(
        <Grid container justifyContent="center">
            <Grid item>
            <CircularProgress/>
            </Grid>
        </Grid>
    )

}