import React, {useState,useContext,useEffect} from "react";



import { Button,FormControl,CircularProgress} from '@mui/material'



import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import FormColumn from '../../common/ui/FormColumn'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';


export default function MyPortfolioEditCash(props){

  const cash = props.cash
  const portfolioName = props.portfolioName
  const updatePortfolio = props.updatePortfolio


  const [amount,setAmount] = useState()
  const [error, setError] = useState()
  const [adding, setAdding] = useState(false)
  const {getSession} = useContext(InvestorAccountContext)

  const handleClose = () => {
      setAdding(false)
      setError()
      props.closeForm()
   }


  const updateCash = () => {
      setAdding(true)
      const cashReq = [{
          "currencyCode": cash.currencyCode, 
          "amount": parseFloat(amount),
      }]
      getSession().then( ({headers}) => {
        headers['Content-Type'] =  'application/json'
        CallAPI('/engine/portfolios/'+portfolioName+'/cash/'+cash.cashId,{
          method: 'POST',
          headers,
          body: JSON.stringify(cashReq)
        })
        .then( data =>{
          updatePortfolio(data)
          handleClose()
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setError(<Alert severity="error">Could not update Cash</Alert>)
          setAdding(false)
        })
      })
    
      
  }

 
  useEffect(() => {
    if(cash){
      setAmount(cash.amount)
    }
   },[cash])
   
  return(

      <Dialog  open={props.open} onClose={handleClose} aria-labelledby="Edit Cash">
      <DialogTitle id="add-cash-title">{"Edit Cash in Portfolio"}
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
      </DialogTitle>
      {error}
      <DialogContent>
          <FormColumn>
              <FormControl variant="outlined" > 
                  <TextField autoFocus value={amount} onChange={ e=>setAmount(e.target.value)} margin="dense" id="amount" label="Amount"  type="number"/>
              </FormControl>
              <FormControl variant="outlined" > 
                  <TextField autoFocus value={cash? cash.currencyCode  :""} disabled={true} margin="dense" id="currency" label="Currency Code" type="text" />
              </FormControl>
         </FormColumn>
      </DialogContent>
     
      <DialogActions>
        <Button onClick={updateCash} color="primary" autoFocus>
        {adding && <CircularProgress size={14} />}
                  {!adding && "Update"}
        </Button>
      </DialogActions>
    </Dialog>

  )
}