import React, { useRef, useEffect, useCallback} from 'react'

import {Box} from '@mui/material'
import Sunburst from '../../sunburst-chart'
import { infographicColour } from '../../common/ui/Colours'

export default function ModelPortfolioAllocationsChart(props){


    const modelPortfolio = props.modelPortfolio
    const container = useRef()
    const sunburstLibrary = useRef(null)
   
    const labelNode = useCallback((mpalloc) => {
        var label = mpalloc.allocationName  + " (" + Math.round(mpalloc.modelWeighting) + "%)"
        return label
    },[])

    
    const buildChartData = useCallback((mpalloc) => {
        var node = {}
         if(mpalloc){ 
            node.name = mpalloc.allocationName
            node.label = labelNode(mpalloc)
            node.children = []
            if(mpalloc.children && mpalloc.children.length>0){
                mpalloc.children.forEach( (child) => {
                    var childnode = buildChartData(child)
                    node.children.push(childnode)
                })
            }else{
                node.value = mpalloc.modelWeighting
            }
        }
        return node
    },[labelNode])

    useEffect(() => {
        if(modelPortfolio && modelPortfolio.investments){
                var data = buildChartData(modelPortfolio.modelPortfolioAllocation)
                if(!sunburstLibrary.current){ 
                    sunburstLibrary.current = Sunburst()(container.current).excludeRoot(true).imageSize(50).width(window.innerWidth*0.6).height(window.innerHeight*0.75).label(d => d.label)
                }
                sunburstLibrary.current.data(data).color(d => infographicColour(d.name))
        }
        
    },[modelPortfolio,buildChartData])


    return(
                <React.Fragment>
                   
                   <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}} ref={container}></Box>
                </React.Fragment>
    )
        
}