import { Grid, Typography } from "@mui/material"
import React from "react"





const TrackerItem = (props) => {

    const tracker = props.tracker
   
    if(tracker && tracker.threshold){
    return(
         <React.Fragment>

            <Grid container direction="column" spacing={2}>
            <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                        <Typography variant="h6" gutterBottom component="div">
                            Rebalance Rule
                        </Typography>
                        </Grid>
                        <Grid item>
                        {tracker.threshold.thresholdType} @ {tracker.threshold.value}%
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                        <Typography variant="h6" gutterBottom component="div">
                            Trade Efficiency
                        </Typography>
                        </Grid>
                        <Grid item>
                        {100.0-(tracker.costEfficiency.maxPercentageCostOfTrade)}%
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                        <Typography variant="h6" gutterBottom component="div">
                           Platform Costs
                        </Typography>
                        </Grid>
                        <Grid item>
                           {tracker.costPerTrade}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            </Grid>
      
            </Grid>
        </React.Fragment>
    )}
    else{
        return(
            <React.Fragment>
                No Tracker
            </React.Fragment>
        )
    }
}

export default TrackerItem