
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Amplify, Auth } from 'aws-amplify';


const defaultPoolId = 'eu-west-1_OPFexcEfG'
const defaultClientId = '4o5g427ekcea2nva773e1r53i'
const defaultRedirect =  'http://localhost:3000'
const defaultDomain =  'dev-equily.auth.eu-west-1.amazoncognito.com'

const poolId = process.env.REACT_APP_POOL_ID || defaultPoolId 
const clientId = process.env.REACT_APP_CLIENT_ID || defaultClientId 
const redirectURI =  process.env.REACT_APP_REDIRECT || defaultRedirect 
const domain = process.env.REACT_APP_DOMAIN || defaultDomain

Amplify.configure({
  Auth: { 
         userPoolId: poolId,
         userPoolWebClientId: clientId,     
  },
  oauth: {
    redirectSignIn: redirectURI,
    redirectSignOut: redirectURI,
    domain: domain,
    responseType: 'code'
  }
})


export const authUser = async (Username, Password) => {
     const user = await Auth.signIn(Username, Password);
     return user
}

export const googleSignIn = async () => {
  return await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
  
}

export const facebookSignIn = async () => {
  return await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })
  
}

export const resendConfirmationCode = async (username) => {
  return await Auth.resendSignUp(username);
}

export const sendCode = async (username)  => {
  return await Auth.forgotPassword(username)
} 

export const forgotPasswordReset = async (username, code, new_password) => {
  return await Auth.forgotPasswordSubmit(username, code, new_password)
}

export const updateUser = async (firstName, surname) => {
    
  const attributes = {
     given_name: firstName,
     family_name: surname,
  }
  const user = await Auth.currentAuthenticatedUser();

  const result = await Auth.updateUserAttributes(user, attributes)
  return result
}

export const updateAttribute = async (updates) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(user, updates)
  return await Auth.currentAuthenticatedUser()

}

export const createUser = async (username, password, attributes) => {
    const { user } = await Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
        }
    })
    return user
}

export const changePassword = async (oldpassword, newpassword) => {
  const user = await Auth.currentAuthenticatedUser()
  const result = await Auth.changePassword(user, oldpassword, newpassword)
  return result
}


export async function verifyCode(username, code) {
  return await Auth.confirmSignUp(username,code)
}

export const logout = async () => {
  await Auth.signOut()
}
export const getSession = async () =>{
    const user = await Auth.currentUserPoolUser();
    const idtoken = user.signInUserSession.getIdToken()
    const token = idtoken.getJwtToken()
    return {
      user,
      headers: {
        Authorization: "Bearer "+ token,
      },
    }
}
/*   

await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute
                    results[Name] = Value
                  }

                  resolve(results)
                }
              })
            })
            const idtoken = session.getIdToken()
            const token = idtoken.getJwtToken()
            var groups = idtoken.payload['cognito:groups']
            if(groups && groups.includes("EquilyAdmin"))user.admin = "EquilyAdmin"
            resolve({
              user,
              headers: {
                Authorization: "Bearer "+ token,
              },
              ...session,
              ...attributes,
              ...groups,
            })
          }
        })
      } else {
        reject()
      }

    
    })
*/