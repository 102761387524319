import { useState } from "react";
import { MainItem } from "../common/ui/MainItem";
import { Route, Routes, useNavigate } from 'react-router-dom';
import BackTestSummary from "./details/BackTestSummary";
import BackTestResult from "./results/BackTestResult";

export default function BackTestView({backTest,refreshBackTests,setBackTest,benchmarks}){

     let navigate = useNavigate ()
    const [selectedResult, setSelectedResult] = useState()

    const handleResultSelect = ( (result) => {
        setSelectedResult(result)
        navigate("/backtest/results")
    })

    const handleClearSelectedResult = () =>{
        setSelectedResult()
        navigate("/backtest/detail")
    }

    return(
        <Routes>
        <Route path="/backtest/results" element={
        <MainItem>   
            {backTest && <BackTestResult backTest={backTest} backTestResultDetails={{backTestResult: selectedResult, backTestName: backTest.backTestName }} handleClearSelectedResult={handleClearSelectedResult}/>}
          </MainItem>
      }/>

      <Route path="/backtest/detail" element={
        <MainItem>   
          <BackTestSummary key={backTest && backTest.id} handleResultSelect={handleResultSelect} backTest={backTest} refreshBackTests={refreshBackTests} setBackTest={setBackTest} benchmarks={benchmarks}/>
         </MainItem> 
      }/>
        </Routes>

    )
}