import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { InvestorAccountContext } from "../auth/InvestorAccounts";
import { CallAPI } from "../common/services/api/CallAPI";
import BannerAlert from "../common/ui/BannerAlert";


export default function BackTestCopy({backTestName, open, handleClose, handleStatus, setBackTest,refreshBackTests}){

    const {getSession} = useContext(InvestorAccountContext)


    const [saving, setSaving] = useState(false)
    const [name, setName] = useState("")
    
    const copyBackTest = () =>{
        setSaving(true)
        handleStatus()
        getSession().then( ({headers}) => {
            CallAPI('/engine/backtest/'+backTestName+'/copy/'+name,{
            method: 'GET',headers
            })
            .then( data =>{
                setSaving(false)
                setBackTest(data)
                refreshBackTests()
                setName("")
            })
            .catch((error) => {
                setSaving(false)
                setName("")
                error.json().then( text => {
                    const msg = text.message.split(':')[1];
                    handleStatus(<BannerAlert severity="error" timed>{msg}</BannerAlert>)
                }).catch((error) => {
                    handleStatus(<BannerAlert severity="error" timed>Could not copy backtest</BannerAlert>)
                })
            })
        })

    }


    return(
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-backtest-copy"
        aria-describedby="confirm backtest copy"
      >
        <DialogTitle id="confirm-backtest-delete">{"Copy BackTest"}</DialogTitle>
        <DialogContent>
        <form  noValidate autoComplete="off">
            <FormControl > 
                <TextField autoFocus value={name} onChange={ e=>setName(e.target.value)}  id="backTestName" label="BackTest Name" type="text"  variant="outlined" fullWidth/>
            </FormControl>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={copyBackTest} color="primary" autoFocus>
                     {saving && <CircularProgress size={14} />}
                     {!saving && "Copy"}
          </Button>
        </DialogActions>
      </Dialog>

    )
}