import React from "react";


import { List} from '@mui/material'

import {PortfolioMenu} from '../portfolios/menu/PortfolioMenu'
import {ModelAllocationMenu} from '../modelallocations/menu/ModelAllocationMenu'
import {BenchmarkMenu} from '../benchmark/menu/BenchmarkMenu'
import { ManagedNestedParentListItemLink } from "../common/Link";
import {EquilyBuildIconWhite} from '../icons/EquilyIcons'

export default function BuildMenu(props) {



    const children = [ <ModelAllocationMenu key="modelAllocMenu"/>,
    <PortfolioMenu key="portfolioMenu"/>,
    <BenchmarkMenu key="benchmarkMenu"/>]
   

        return(
            <React.Fragment>
            <List key={'buildMenu'} sx={{pb: 0}}>
             <ManagedNestedParentListItemLink 
              sx={{
                backgroundColor: 'pantone.pantone645', 
                color: 'text.light',
                '&:hover': {
                    backgroundColor: 'pantone.pantone645Dark', 
                },
                }}
              primary="Design"
              icon={<EquilyBuildIconWhite />} 
              children={children}
              open={props.open}
              setOpen={props.setOpen} 
              setClose={props.setClose}/>
             </List>
            </React.Fragment>
        )
  
}