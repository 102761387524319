import {useContext} from "react";
import {EquilyContext} from '../../common/Context'

import { useNavigate  } from "react-router-dom";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'



import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { EquilyListItemText } from "../../common/Link";




export function PortfolioMenuItem(props){
    
   
    const {setInvestorPortfolio} = useContext(EquilyContext);
    let navigate = useNavigate ();

    const setPortfolio = () => {
        setInvestorPortfolio(props.portfolio)
        navigate("/portfolios");
    }


    return(
        
            <ListItem key={props.portfolio.portfolioName+"Port"} button sx={{ pl: 4 }} onClick={setPortfolio}>
                <ListItemIcon>
                     <DashboardCustomizeIcon />
                </ListItemIcon>
                <EquilyListItemText key={props.portfolio.portfolioName+'PortText'} primary={props.portfolio.portfolioName} />
            </ListItem>
       
    )

}