import { Card, CardContent, Grid, Typography, TextField, MenuItem} from "@mui/material";
import React, { useEffect,useState,useContext} from "react";
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { CallAPI } from "../../common/services/api/CallAPI";
import TrackerActionItem from "./TrackerActionItem";
import TrackerItem from "./TrackerItem";

export default function MyPortfolioActions(props){
    
    const benchmark = props.benchmark
    const rebalanceTrackers = props.rebalanceTrackers
    
    const {getSession} = useContext(InvestorAccountContext)

    const [benchmarkTracker, setBenchmarkTracker] = useState()
    const [actionItems, setActionItems] = useState([])
    const [trackerItems, setTrackerItems] = useState([])
    const [currentResult, setCurrentResult] = useState([])
    const [items, setItems] = useState([])
    const [value, setValue] = useState('actions')

    const status = [
        {
            value: 'actions',
            label: 'Actions'
        },
        {
            value: 'tracker',
            label: 'Tracker'
        },
    ]


    useEffect( () =>{
        if(rebalanceTrackers){
            const trackers = rebalanceTrackers.filter( t => t.benchmarkId===benchmark.benchmarkId)
            setBenchmarkTracker(trackers[0])
        }
    },[rebalanceTrackers,benchmark])

    useEffect( () => {
        if(benchmarkTracker){
            getSession().then( ({headers}) => {
                CallAPI('/engine/rebalancetracker/'+benchmarkTracker.trackerName + '/results',{
                  method: 'GET',
                  headers,
                })
                .then( data =>{
                  data.sort((a, b) => (a.runDate < b.runDate) ? 1 : -1)
                  setCurrentResult(data[0])
                  data.splice(0,1)
                  //setHistoricResults(data)
                
                })
                .catch((error) => {
                  console.log("There was an error " + error)
                  
                });
              })
            const titem = <TrackerItem tracker={benchmarkTracker}/>
            setTrackerItems([titem])
        }
    },[benchmarkTracker,getSession])

    
    useEffect( () => {
        if(currentResult && currentResult.actions){
            const actions = currentResult.actions.map( (action) => {
                return <TrackerActionItem action={action}/>
            })
            setActionItems(actions)
        }
    },[currentResult])


    useEffect( () => {
        if(value==='actions'){
            setItems(actionItems)
        }else{
            setItems(trackerItems)
        }
    },[value,actionItems,trackerItems])

    return(
        <React.Fragment>
            <Card sx={{
                   borderRadius: '16px'
                }}>
                <CardContent>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Action Tracker</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {items}
                        </Grid>
                    </Grid>
                </CardContent>
           </Card>
        </React.Fragment>
    )
}