import {useEffect,useRef} from 'react'
import Sunburst from '../../sunburst-chart'
import { Box,Paper } from '@mui/material'
import { infographicColour } from '../../common/ui/Colours'



export default function BenchmarkChart(props){

    const benchmarkData = props.benchmarkData

    const container = useRef()
    const sunburstLibrary = useRef(null)
   
   
    useEffect(() => {
      
        if(benchmarkData){
                if(!sunburstLibrary.current){ 
                    sunburstLibrary.current = Sunburst()(container.current).excludeRoot(true).imageSize(50).width(window.innerWidth*0.6).height(window.innerHeight*0.75).label(d => d.label)
                }
                sunburstLibrary.current.data(benchmarkData).color(d => infographicColour(d.name))
        }
    },[benchmarkData])

    return(

        <Paper>
        <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}} ref={container}></Box>
        </Paper>
    
    )

}