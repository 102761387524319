import { Button, Grid, Typography } from "@mui/material"

export default function Success({login}){
   
  
    return(
        <>
        <Grid sx={{mb:'75px'}} direction="column" container justifyContent="center" spacing={2} alignItems="center" >
            <Grid item>
                <Typography align="center" variant="h5">Welcome To Equily</Typography>
            </Grid>
            <Grid item>
                <Typography align="center" variant="body1">You're all set.</Typography>
            </Grid>
        </Grid>
       

        
        <Grid sx={{mb:'175px'}} container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button variant="contained" onClick={login} color="primary">
                    Let's Go!
                </Button>
            </Grid>
        </Grid>
       
        </>
    )
}