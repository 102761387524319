import React, {useState,useEffect} from 'react';

import { Grid, Fab, Box, Paper , FormControl, TextField} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import Title from '../common/ui/Title'


export default function MyAccountSocial(props){


    const investor = props.investor
    

  
    
    const [firstName, setFirstName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
   
    
    console.log(investor)
    
    useEffect( () =>{
        if(investor){
            setFirstName(investor.firstName)
            setSurname(investor.lastName)
            setEmail(investor.email)
        }
    },[investor])

   


    
    return(
        <React.Fragment>

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={9}>
          <Title>
           My Investor Account - {firstName}&nbsp;{surname}
          </Title> 

        
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                
                <Tooltip title="Delete Account" aria-label="Delete">
                    <Fab  color="" aria-label="delete" zindex="tooltip" >
                        <DeleteForeverIcon />
                    </Fab>
                </Tooltip>
             
            </Grid>
          </Grid>
        </Grid>

        <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
          <Paper>
          <Box
                component="form"
                display="flex" flexDirection="column"
                sx={{
                    '& > :not(style)': { m:1, width: props.width },
                }}
                noValidate
                autoComplete="off"
                >
                    
                    <FormControl>
                        <TextField   disabled={true} value={firstName} onChange={e => setFirstName(e.target.value)} id="firstName" label="First Name" type="text" />
                    </FormControl>
                    <FormControl>
                        <TextField   disabled={true}  value={surname} onChange={e => setSurname(e.target.value)} id="surname" label="Family Name" type="text"  />
                   </FormControl>
                    <FormControl>
                        <TextField  disabled={true} value={email} id="email" label="Email" type="text"  />
                    </FormControl>
             </Box>
          </Paper>
        </Box>
   
    
     
     
    </React.Fragment>

    )



}