import React, { useState, useEffect} from 'react'

import {Grid, Typography} from '@mui/material'

export default function ModelPortfolioAllocationsTable(props){


    const modelPortfolio = props.modelPortfolio
    const [data, setData] = useState([])

  
    useEffect(() => {
        if(modelPortfolio && modelPortfolio.investments){
                var countData = {
                    equityCount: 0,
                    fundCount: 0,
                    eftCount: 0,
                    other: 0,
                    cash: 0,
                }
             
                modelPortfolio.investments.forEach( inv => {
                    if(inv.stock){
                    switch(inv.stock.type) {
                        case "Common Stock":
                            countData.equityCount++
                          break
                        case "ETF":
                            countData.eftCount++
                          break
                        case "FUND":
                            countData.fundCount++
                            break    
                        default:
                            countData.other++
                      }
                    }else{
                        countData.cash++
                    }
                })
                setData(countData)
        }
        
    },[modelPortfolio])


    return(
                <React.Fragment>
                  {data && 
                    <Grid container alignItems="center" direction="column" spacing={2}>
                        <Grid item>
                                    <Typography variant="h6">
                                         Allocation Types
                                    </Typography>
                        </Grid>
                    
                    <Grid item>
                    <Grid container direction="row" spacing={2}>
                        
                       
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="subtitle">
                                         ETF
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle">
                                        {data.eftCount===0 ? "-" : data.eftCount}
                                    </Typography>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="subtitle">
                                         Stock
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle">
                                        {data.equityCount===0 ? "-" : data.equityCount}
                                    </Typography>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="subtitle">
                                         Fund
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle">
                                        {data.fundCount===0 ? "-" : data.fundCount}
                                    </Typography>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="subtitle">
                                         Cash
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle">
                                        {data.cash===0 ? "-" : data.cash}
                                    </Typography>
                                </Grid>
                            </Grid>    
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="subtitle">
                                         Other
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle">
                                        {data.other===0 ? "-" : data.other}
                                    </Typography>
                                </Grid>
                            </Grid>    
                        </Grid>
                    </Grid>

                    </Grid>
                    </Grid>
                  }
                </React.Fragment>
    )
        
}

