import React, {useState,useContext,useEffect} from "react";



import { Button,FormControl,CircularProgress} from '@mui/material'



import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';

import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import FormColumn from '../../common/ui/FormColumn'

export default function MyPortfolioEditInvestment(props){

    const investment = props.investment
    const portfolioName = props.portfolioName
    const updatePortfolio = props.updatePortfolio
    
    const [error,setError] = useState()
    const [adding,setAdding] = useState(false)
    const [quantity, setQuantity] = useState(investment.volume)
   

    const {getSession} = useContext(InvestorAccountContext)
    
    
    const updateInv = () => {

        setAdding(true)
        const req = [{
          "symbol": investment.stock.symbol,
          "quantity": quantity,
        }]
        getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/portfolios/'+portfolioName+'/'+investment.investmentId,{
            method: 'POST',
            headers,
            body: JSON.stringify(req)
          })
          .then( data =>{
            console.log(data)
            updatePortfolio(data)        
            handleClose()
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not update Investment</Alert>)
            setAdding(false)
          })
        })
      
       
    }

   
     
    const handleClose = () => {
        setQuantity(investment.volume)
        setError()
        setAdding(false)
        props.closeForm()
     }
     
     useEffect(() => {
      if(investment){
        setQuantity(investment.volume)
      }

     },[investment])


    return(

        <Dialog  open={props.open} onClose={handleClose} aria-labelledby="Edit Investment">
        <DialogTitle id="edit-investment-title">
          {"Edit Investment"}
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
        </DialogTitle>
        {error}
        <DialogContent>
        <FormColumn >              
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={investment? investment.stock.symbol :""} disabled={true}  id="symbol" label="Symbol" type="text"  />
                </FormControl>  
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={investment? investment.stock.currencyCode  :""} disabled={true}  id="currency" label="Currency Code" type="text"  />
                </FormControl>
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={quantity} onChange={ e=>setQuantity(e.target.value)} id="quantity" label="Quantity"  type="number"/>
                </FormControl>
          </FormColumn>
        </DialogContent>
       
        <DialogActions>
          <Button onClick={updateInv} color="primary" autoFocus>
          {adding && <CircularProgress size={14} />}
                    {!adding && "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    )
}