import React, {useState,useContext,useEffect} from "react";

import Alert from '@mui/material/Alert';
import { Paper, Grid } from '@mui/material'
import ModelAllocationTree from "./tree/ModelAllocationTree";
import ModelAllocationEditPanel from "./ModelAllocationEditPanel";
import { CallAPI } from '../common/services/api/CallAPI'

import {InvestorAccountContext} from '../auth/InvestorAccounts'



export default function ModelAllocationEdit(props){
    
    
    
    const modelAllocation = props.modelAllocation
    const setModelAllocation = props.setModelAllocation

   
    const {getSession} = useContext(InvestorAccountContext)

    const [selectedAllocation, setSelectedAllocation] = useState(modelAllocation.allocation)
    const [addingAllocation, setAddingAllocation] = useState(false)
    const [error,setError] = useState()
    const [parent, setParent] = useState(true)


    useEffect(() => {
        if(modelAllocation){
            setSelectedAllocation(modelAllocation.allocation)
        }
      },[modelAllocation])

    const addAllocation = (allocationName, uri, weighting) =>{
        setAddingAllocation(true)
        var req = [{
            "allocationName": allocationName,
            "parentAllocationName": uri,
            "weighting": weighting
        }]
       
        getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/models/'+modelAllocation.modelAllocationName,{
                method: 'POST',
                headers,
                body: JSON.stringify(req)
            })
            .then( data =>{
                setAddingAllocation(false)
                setModelAllocation(data)
                selectAllocationFromURI(uri)
                setError()
            })
            .catch((error) => {
                console.log(error)
                setError(<Alert severity="error">Could not add Allocation</Alert>)
                setAddingAllocation(false)
            })
         })
        
    }


    const removeAllocation = (allocationName, uri) =>{
        setAddingAllocation(true)

        var i = uri.lastIndexOf(".")
        if(i!==-1){  
            uri = uri.slice(0,i)
        }
      
        var req = [{
            "allocationName": allocationName,
            "parentAllocationName": uri,
            "weighting": '10',
        }]
      
        getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/models/'+modelAllocation.modelAllocationName+"/allocations",{
            method: 'DELETE',
            headers,
            body: JSON.stringify(req)
            })
            .then( data =>{
            setAddingAllocation(false)
            setModelAllocation(data)
            selectAllocationFromURI(uri)
            setError()
            })
            .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not delete Allocation</Alert>)
            setAddingAllocation(false)
            })
        })
        
    }

  
    const checkAllocationMatchURI  = (allocation, uri) => {
        if(allocation.uri===uri) return true
        return false
    }


    const findAllocation = (allocation, allocationName,checkAllocationMatch) => {
       
        var match
        if(checkAllocationMatch(allocation, allocationName)){
            match = allocation
        } 
        else{  
            if(allocation.childAllocations){
                for( var i = 0; i < allocation.childAllocations.length; i++ ){
                    const child = allocation.childAllocations[i];   
                    if( checkAllocationMatch(child,allocationName)){
                         match = child
                    }else{  
                        match = findAllocation(child, allocationName,checkAllocationMatch)
                    }
                    if(match) break
                }
            }
        }
        return match
    
    }
    
    const selectAllocationFromURI = (allocationURI,newModelAllocation) => {
        if(!newModelAllocation){
            newModelAllocation = modelAllocation
        }
        var alloc = findAllocation(newModelAllocation.allocation, allocationURI,checkAllocationMatchURI)
        if(allocationURI===modelAllocation.uri){
          setParent(true)
        }else{
          setParent(false)
        }
        setSelectedAllocation(alloc)
    }



   

    const updateAllocation = (name,uri,weighting) => {
        setAddingAllocation(true)
       
        if(!weighting) weighting= 100.0
        var parent_uri = uri
        const i = uri.lastIndexOf('.')
        if(i!==-1){
             parent_uri = uri.slice(0,i)
             uri = parent_uri + "." + name
        }else{
             uri = name
        }
        var req = {
            "allocationName": name,
            "parentAllocationName": parent_uri,
            "weighting": weighting
        }
        getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/models/'+modelAllocation.modelAllocationName+'/'+selectedAllocation.allocationName,{
                method: 'POST',
                headers,
                body: JSON.stringify(req)
            })
            .then( data =>{
            setAddingAllocation(false) 
            setModelAllocation(data)  
            selectAllocationFromURI(uri,data)
            })
            .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not update Allocation</Alert>)
            setAddingAllocation(false)
            })
        })
    }

    return(
        <React.Fragment>
            
            <Grid container spacing={2} direction="row" justifyContent="space-between"  sx={{'paddingBottom': '1rem','paddingTop': '1rem'}}>
                <Grid item xs={6}>
                    <Paper sx={{padding:1}}>
                        <ModelAllocationTree selectedAllocation={selectedAllocation} selectAllocation={selectAllocationFromURI} modelAllocation={modelAllocation}></ModelAllocationTree>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{padding:1}}>
                        {error}
                        <ModelAllocationEditPanel isParent={parent} actionActive={addingAllocation} addAllocation={addAllocation} removeAllocation={removeAllocation} allocation={selectedAllocation} updateAllocation={updateAllocation}></ModelAllocationEditPanel>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}