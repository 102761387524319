import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {CircularProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

export function ListItemLink(props) {
    const { icon, primary, to } = props;
  
    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
      [to],
    );
  
    return (
      <li>
        <ListItem key={primary} button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText key={primary+"Text"} primary={<Typography variant="subtitle1">{primary}</Typography>} />
        </ListItem>
      </li>
    );
  }


  export function NestedParentListItemLink(props) {
    const { icon, primary, children } = props;
    const [open, setOpen] = React.useState(false);
    
    const handleClick = () => {
      setOpen(!open);
    };
      
    return (
      <>
        <ListItemButton onClick={handleClick} sx={props.sx}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText id={primary} primary={<Typography variant="subtitle1">{primary}</Typography>} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
             {children}
        </Collapse>
      </>
    );
  }

  export function ManagedNestedParentListItemLink(props) {
    const { icon, primary, children, open, setOpen, setClose } = props;
    
    const handleClick = () => {
      if(!open){
        setOpen()
      }else{
        setClose()
      }
    };
      
    return (
      <li>
        <ListItemButton onClick={handleClick} sx={props.sx}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText id={primary} primary={<Typography variant="subtitle1">{primary}</Typography>} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
             {children}
        </Collapse>
      </li>
    );
  }


  export const EquilyListItemText = (props) =>{
    const {primary,onClick} = props
    return(
      <ListItemText id={primary} onClick={onClick}  primary={<Typography variant="subtitle1">{primary}</Typography>} />
        
    )
  }


  export function SpinnerItem(props){
 
    return(
      <ListItem key='spinnera' id='spinner' button sx={{ pl: 4 }}>
       <CircularProgress/>
      </ListItem>
    )
   
  }