import {useContext} from "react";
import {EquilyContext} from '../../common/Context'

import { useNavigate  } from "react-router-dom";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import StackedLineChartSharpIcon from '@mui/icons-material/StackedLineChartSharp';
import { EquilyListItemText } from "../../common/Link";

export function RebalanceTrackerMenuItem(props){
    
    
    const {setRebalanceTracker} = useContext(EquilyContext);
    let navigate = useNavigate ();

    const selectTheTracker = () => {
        setRebalanceTracker(props.tracker)
        navigate("/rebalancetracker");
    }


    return(
        
            <ListItem key={props.tracker.trackerName+"Tracker"} button sx={{ pl: 4 }} onClick={selectTheTracker}>
                <ListItemIcon>
                     <StackedLineChartSharpIcon />
                </ListItemIcon>
                <EquilyListItemText key={props.tracker.trackerName+'TrackerText'} primary={props.tracker.trackerName} />
            </ListItem>
       
    )

}