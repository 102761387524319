
import {  Grid,  Paper } from "@mui/material"

import BackTestResultRiskData from "./BackTestResultRiskData";
import BackTestResultPerformanceNVD3 from "./BackTestResultPerformanceNVD3";

export default function BackTestResultPerformance({backTestResultActions,backTestResult,handleStatus}){

   
    return(
       
      <Paper>
        <Grid container spacing={2} p={1} direction="column">
       
        <Grid item>
            <BackTestResultRiskData  backTestResult={backTestResult}/>  
        </Grid>
        
        <Grid item>
            <BackTestResultPerformanceNVD3 backTestResultActions={backTestResultActions} backTestResult={backTestResult} handleStatus={handleStatus}/>  
        </Grid>
       
        </Grid>
        </Paper>
   
    )
}

