
const fetchFromObject = (obj, prop) => {

  if(typeof obj === 'undefined') {
      return false;
  }

  var _index = prop.indexOf('.')
  if(_index > -1) {
      return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
  }

  return obj[prop];
}


export const renderNumber = (data, dataIndex, attr) =>{
    var value = ""
  //  if(data[dataIndex][attr]) value = data[dataIndex][attr].toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) 
  const rowData = data[dataIndex]
  if(rowData) value = fetchFromObject(rowData, attr)
  if(value) value = value.toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) 
  return value
}
export const renderInteger = (data, dataIndex, attr) =>{
  var value = ""
const rowData = data[dataIndex]
if(rowData) value = fetchFromObject(rowData, attr)
if(value) value = value.toLocaleString(undefined,{maximumFractionDigits: 0, minimumFractionDigits: 0}) 
return value
}

const lossStyle = {color: '#9B3259'}
const gainStyle =  {color: '#4F868E'}

export const renderNumberColourCoded = (data, dataIndex, attr) =>{
  var value = renderNumber(data,dataIndex,attr)
  if(value<0){
    return <p style={lossStyle}>{value}</p> 
  }else{
    return <p style={gainStyle}>{value}</p>
  }
}



export const renderDate = (data, dataIndex, attr) =>{
  var value = ""
  if(data[dataIndex][attr]){
    var item = data[dataIndex][attr]
    if(typeof item.toLocaleDateString !== 'function') item = new Date(item)
    value = item.toLocaleDateString() 
  } 
  return value
}

export const renderDateTime = (data, dataIndex, attr) =>{
  var value = ""
  if(data[dataIndex][attr]){
    var item = data[dataIndex][attr]
    if(typeof item.toLocaleDateString !== 'function') item = new Date(item)
    value = item.toLocaleString()
  } 
  return value
}


export const renderCurrencyNumber = (data, dataIndex, attr, symbol) =>{
  
  var start = ""
  var end = ""

  if(symbol==='GBX') end = "p"
  
  const s = getSymbol(symbol)
  if(s!==symbol) start = s

  var value = ""
  if(data[dataIndex][attr]) value = data[dataIndex][attr].toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) 
  
  return start + value + end
}
export const renderCurrency = ( input, symbol, digits) =>{
  if(digits) digits = 2
  var start = ""
  var end = ""

  if(symbol==='GBX') end = "p"
  
  const s = getSymbol(symbol)
  if(s!==symbol) start = s

  var value = ""
  value = input.toLocaleString(undefined,{maximumFractionDigits: digits, minimumFractionDigits: digits}) 
  
  return start + value + end
}



export const getSymbol = (currency) => {  
  const value = 1.0
  if(currency && value){
      const symbol = new Intl.NumberFormat('en', { style: 'currency', currency }).formatToParts(value).find(x => x.type === 'currency');   
      return symbol && symbol.value; 
  }
}


export const nonSelectTableOptions = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    caseSensitive: false,
    resizableColumns: false,
    selectableRows: 'none',
    draggableColumns:{
      enabled:true, 
      transitionTime: 1000
    },
    searchProps: {
      spellcheck: "false",
      autocomplete: "off",
      autocorrect: "off",
      autocapitalize: "off"
    },
  }

  