import React,{useContext} from "react";

import {IconButton, Badge, Button, Typography} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'

import { AccountMenu } from "./AccountMenu";

import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { HelpMenu } from "./HelpMenu";

export default function InvestorToolBar(props) {
      
    const {user} = useContext(InvestorAccountContext)

    if(user.username){
    return(
        <div id="investortoolbar">      
                <FeedbackButton/>
                <IconButton color="inherit">
                      <Badge badgeContent={0} color="secondary">
                      <NotificationsIcon />
                      </Badge>
                  </IconButton>
                  <HelpMenu></HelpMenu>
                  <AccountMenu></AccountMenu>
        </div>
    )
    }else{
        return(
            <div/>
        )
    }
}


function FeedbackButton(){


    return(

        <Button
        variant="contained"
        size="large"
        color="secondary"
        target="_top"
        rel="noopener noreferrer"
        href={`mailto:feedback@equily.io`}
        >
        <Typography variant="button" style={{ fontSize: '0.69rem' }}>
            Feedback
        </Typography>
        </Button>


    )
}