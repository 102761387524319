import { Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function RegisterForm({investorID, register,isRegistering}){
    
    const [fname, setFName] = useState("")
    const [lname, setLName] = useState("")
    const [password, setPassword] = useState("")

    return(
        <>
        
<Grid sx={{mb:'1px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
<Grid item sx={{width: '100%'}} >
        <Typography align="center" variant="h5">Welcome To Equily</Typography>
    </Grid>
    <Grid item sx={{width: '100%'}} >
        <Typography align="center" variant="body1">Select a password and tell us a little more about yourself</Typography>
    </Grid>
</Grid>
<Grid sx={{mb:'1px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">        
    <Grid item sx={{width: '100%'}}>
     <TextField  variant="standard" fullWidth value={investorID}  id="reginvestorid" label="Email"  disabled />  
    </Grid>
</Grid>
<Grid sx={{mb:'1px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
    <Grid item sx={{width: '100%'}}>
     <TextField   variant="standard" fullWidth value={password} id="regpassword" label="Password" type="password" onChange={e => setPassword(e.target.value)} />
    </Grid>
</Grid>
<Grid sx={{mb: '1px'}} container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
                <Typography variant="caption">Password must be greater than 8 characters in length and contain an uppercase letter, special character number </Typography>
            </Grid>
</Grid>
<Grid sx={{mb:'5px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
    <Grid item sx={{width: '100%'}}>
     <TextField   variant="standard" fullWidth onChange={e => setFName(e.target.value)} value={fname} id="firstname" label="First Name"/>
    </Grid>
</Grid>
<Grid sx={{mb:'5px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
    <Grid item sx={{width: '100%'}}>
     <TextField  variant="standard" fullWidth onChange={e => setLName(e.target.value)} value={lname} id="lastname" label="Last Name"/>
    </Grid>
</Grid>

<Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
    <Grid item>
        <Button variant="contained" onClick={() => register(fname,lname,password)} color="primary">
            {isRegistering && <CircularProgress size={14} />}
            {!isRegistering && "Register"}
        </Button>
    </Grid>
</Grid>
</>

    )
}