import React, {useEffect,useCallback, useContext} from 'react';

import {styled} from '@mui/material/styles';
import {AppBar, Container, Toolbar, IconButton, Divider, CssBaseline, Grid, Typography} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon  from '@mui/icons-material/ChevronLeft';
import { Route, Routes} from 'react-router-dom';
import {EquilyContext} from '../common/Context'
import Investors from '../investors/Investors'

import Stocks from '../stocks/Stocks'
import Portfolios from '../portfolios/Portfolios'
import ModelAllocation from '../modelallocations/ModelAllocation'
import RebalanceTracker from '../rebalance/RebalanceTracker'
import Benchmark from '../benchmark/Benchmark'
import { CallAPI } from '../common/services/api/CallAPI'
import { Dashboard } from '../dashboard/Dashboard';
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import { Box } from '@mui/system';
import {MainItem,MainPaperItem} from '../common/ui/MainItem';
import MyAccount from '../account/MyAccount';
import InvestorToolBar from './appbar/InvestorToolBar';
import SideMenu from '../menu/SideMenu';
import { ModelPortfolioStore } from '../store/ModelPortfolioStore';
import { MyPorfoliosDash } from '../subscriber/MyPortfoliosDash';
import { PrivacyPolicy } from '../privacy/PrivacyPolicy';

import BackTestView from '../backtests/BackTestView';
import HelpDrawer from '../help/HelpDrawer';
import { ShepherdTourContext } from 'react-shepherd';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});



const EquilyAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const EquilyDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function Equily(props) {

    const {getSession,user,checkUser} = useContext(InvestorAccountContext)
    
    
    const [open, setOpen] = React.useState(true)
    const [investor, setInvestor] = React.useState()
    const [portfolio, setPortfolio] = React.useState()
    const [modelAllocations, setModelAllocations] = React.useState()
    
    const [modelAllocation, setModelAllocation] = React.useState()
    const [benchmarks, setBenchmarks] = React.useState()
    const [benchmark, setBenchmark] = React.useState()
    const [backTests, setBackTests] = React.useState([])
    const [backTest, setBackTest] = React.useState()
    
    const [rebalanceTrackers, setRebalanceTrackers] = React.useState()
    const [rebalanceTracker, setRebalanceTracker] = React.useState()
    
    const [portfolios, setPortfolios] = React.useState()

    const tour = useContext(ShepherdTourContext);
   // setup shepherd tour
   if(!localStorage.getItem('shepherd-toura')) {
    tour.start();
    localStorage.setItem('shepherd-toura', 'yes');
  }


    useEffect(() => {
      if(user.username){
        getSession().then( ({headers}) => {  
          CallAPI('/engine/investors/',{method: 'GET',headers}).then( data =>{
            setInvestor(data)
            window.heap.identify(user.attributes.email);
          }).catch(err => {})
       })
      }else{
       // check if there's actually a cognito user
        checkUser()
      }
    },[user,getSession,checkUser])

   

    const updateInvestor = (inv) => {
      setInvestor(inv)
    }

    useEffect(() => {
      if(investor){
        getSession().then( ({headers}) => {  
          loadModels(headers)
          loadPortfolios(headers)
          loadBenchmarks(headers)
          loadRebalanceTrackers(headers)
          loadBackTests(headers)
        })
      }
    }, [investor,getSession]);

  
    const loadBackTests = (headers) =>{
      CallAPI('/engine/backtest',{method: 'GET',headers}).then( data =>{
        setBackTests(data)
      })
      .catch((error) => {
        console.log("There was an error getting backtests for investor error " + error)
      });
    }
    const refreshBackTests = useCallback(() =>{
      if(investor){
        getSession().then( ({headers}) => {  
          loadBackTests(headers)
        })
      }
    },[getSession,investor])


    const loadRebalanceTrackers = (headers) =>{
      CallAPI('/engine/rebalancetracker',{method: 'GET',headers}).then( data =>{
        setRebalanceTrackers(data)
      })
      .catch((error) => {
        console.log("There was an error getting trackers for investor error " + error)
      });
    }
    const refreshReblanceTrackers = () =>{
      if(investor){
        getSession().then( ({headers}) => {  
          loadRebalanceTrackers(headers)
        })
      }
    }

    const loadModels = (headers) =>{
      CallAPI('/engine/models',{method: 'GET',headers}).then( data =>{
        setModelAllocations(data)
      })
      .catch((error) => {
        console.log("There was an error getting allocations for investor error " + error)
      });
    }
    const refreshModelAllocations = () =>{
      if(investor){
        getSession().then( ({headers}) => {  
          loadModels(headers)
        })
      }
    }

    const refreshBenchmarks = useCallback(() =>{
      if(investor){
        getSession().then( ({headers}) => {  
          loadBenchmarks(headers)
        })
      }
    },[investor,getSession])


    const loadBenchmarks = (headers) => {
      CallAPI('/engine/benchmark',{method: 'GET',headers}).then( data =>{
        setBenchmarks(data)
      })
      .catch((error) => {
        console.log("There was an error getting benchmarks for investor error " + error)
      });
    }

    
    const loadPortfolios = (headers) => {
      
      CallAPI('/engine/portfolios',{method: 'GET',headers}).then( data =>{
        setPortfolios(data)
      })
      .catch((error) => {
        console.log("There was an error getting portfolios for investor error " + error)
      });

    }
    const refreshPortfolios = () =>{
      if(investor){
        getSession().then( ({headers}) => {  
          loadPortfolios(headers)
        })
      }
    }

    const handleDrawerOpen = () => {
      setOpen(true)
    }
    const handleDrawerClose = () => {
      setOpen(false)
    }

   
    const setInvestorPortfolio = (aportfolio) => {
      setPortfolio(aportfolio)
      var ports = portfolios.map(p => p.portfolioName===aportfolio.portfolioName ? aportfolio : p);
      setPortfolios(ports)
    }


    const buildURIs = useCallback((nameprefix, modelallocation,getName,getChildren) => {
      if(modelallocation){
        var root = ""
        if(nameprefix!==""){
          root = nameprefix+"."
        } 
        var uri = root + getName(modelallocation)
        modelallocation.uri = uri
        var children = getChildren(modelallocation)
        if(children){
          children.forEach(child => {
            buildURIs(uri,child,getName,getChildren)
          })
        }
      }
    },[])

    
    const selectModelAllocation = (amodelallocation) => {
      buildURIs("",amodelallocation.allocation, function(m){ return m.allocationName},function(m){return m.childAllocations})
      
      setModelAllocation(amodelallocation)
      var modelallocs = modelAllocations.map(alloc => alloc.modelAllocationName===amodelallocation.modelAllocationName ? amodelallocation : alloc);
      setModelAllocations(modelallocs)
     }

   const addModelAllocation = (amodelallocation) => {
      selectModelAllocation(amodelallocation)
      setModelAllocations([...modelAllocations, amodelallocation])
   }    

    const selectBenchmark = useCallback((abenchmark) => {
      buildURIs("",abenchmark.benchmarkAllocation, function(bm){ return bm.allocation.allocationName},function(bm){return bm.childBenchmarkAllocations})
      setBenchmark(abenchmark)
      var bms = benchmarks.map(bm => bm.benchmarkName===abenchmark.benchmarkName ? abenchmark : bm);
      setBenchmarks(bms)
    },[benchmarks,buildURIs])

    const deleteBenchmark = useCallback((abenchmark) => {
      var bms = benchmarks.filter(bm => bm.benchmarkName!==abenchmark.benchmarkName);
      setBenchmarks(bms)
      refreshBackTests()
    },[benchmarks,refreshBackTests])


    const deleteModelAllocation = useCallback((amodel) => {
      var allocs = modelAllocations.filter(m => m.modelAllocationName!==amodel.modelAllocationName);
      setModelAllocations(allocs)
      refreshBenchmarks()
      refreshBackTests()
    },[modelAllocations,refreshBackTests,refreshBenchmarks])

    const addBenchmark = useCallback((abenchmark) => {
      selectBenchmark(abenchmark)
      setBenchmarks([...benchmarks, abenchmark])
    },[benchmarks,selectBenchmark])


    const addRebalanceTracker = useCallback((atracker) => {
      setRebalanceTracker(atracker)
      setRebalanceTrackers([...rebalanceTrackers, atracker])
    },[rebalanceTrackers])

    const addBackTest = useCallback((abacktest) => {
      setBackTest(abacktest)
      setBackTests([...backTests, abacktest])
    },[backTests])

    return (
        
        <EquilyContext.Provider value={{updateInvestor,setInvestorPortfolio,portfolios,setPortfolios,refreshPortfolios, selectModelAllocation,addModelAllocation,refreshModelAllocations,deleteModelAllocation,selectBenchmark,addBenchmark,deleteBenchmark, refreshBenchmarks, portfolio, modelAllocations, modelAllocation, benchmarks, benchmark, investor, rebalanceTrackers,setRebalanceTracker,rebalanceTracker,addRebalanceTracker,refreshReblanceTrackers}}>
       <Box sx={{ display: 'flex' }}>
        <CssBaseline />
              <EquilyAppBar color="primary" position="absolute" open={open}>
                  <Toolbar  sx={{
                    pr: '24px', 
                  }}>
                  <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                      }}
                  >
                      <MenuIcon />
                  </IconButton>

                  
                  <Grid container  direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>

                         
                  <Box
                      component="img"
                      sx={{
                        height: 37,
                        width: 143.5,
                      }}
                      alt="Power up your financial future"
                      src="/Equily_Logo.png"
                    />
                  <Typography variant='body'>(Beta)</Typography>
                   </Grid>
                  <Grid item>
                  <InvestorToolBar></InvestorToolBar> 
                  </Grid>
                  </Grid>
                  </Toolbar>
                  
              </EquilyAppBar>
              
              <EquilyDrawer variant="permanent" open={open} >
                  <Toolbar
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                      }}
                    >
                      <IconButton onClick={handleDrawerClose}>
                          <ChevronLeftIcon/>
                      </IconButton>
                  </Toolbar>
                  <Divider/>
                  <SideMenu backTests={backTests} addBackTest={addBackTest} setBackTest={setBackTest} benchmarks={benchmarks}></SideMenu>
                  
              </EquilyDrawer>
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.primary.backgroundTint
                      : theme.palette.primary.backgroundTint,
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
              <Toolbar />
              <Container maxWidth="lg" sx={{ height: '100%', mt: 4, mb: 4 }}>
                 
               <Routes>
                    <Route path="/investors" element={
                      <MainItem>
                        <Investors /> 
                      </MainItem>
                    }/>
                    <Route path="/stocks" element={ 
                      <MainItem>              
                        <Stocks></Stocks>  
                      </MainItem>        
                    }/>
                   
                    <Route path="/modelallocation" element={  
                      <MainItem>               
                        <ModelAllocation/> 
                      </MainItem>                 
                     }/>
                    <Route path="/benchmark" element={ 
                      <MainItem>                   
                        <Benchmark/>
                      </MainItem>  
                     }/>
                   


                    <Route path="/rebalancetracker" element={ 
                      <MainItem>               
                        <RebalanceTracker/> 
                      </MainItem>                 
                     }/>
                    <Route path="/modelportfoliostore" element={ 
                      <MainItem>               
                        <ModelPortfolioStore/> 
                      </MainItem>                 
                     }/>
                    <Route path="/myportfolios" element={  
                      <MainItem>               
                        <MyPorfoliosDash/> 
                      </MainItem>                 
                     }/>
                   
                    <Route path="/myaccount" element={
                      <MainItem>
                        <MyAccount/> 
                      </MainItem>  
                     }/>
                    <Route exact path="/" element={
                      <MainItem>
                        <Dashboard investor={investor}/> 
                      </MainItem> 
                     }/> 
                    <Route path="/privacy" element={
                    <MainPaperItem>
                        <PrivacyPolicy/>
                    </MainPaperItem>
                     }/>
                    </Routes>
                    <BackTestView backTest={backTest} refreshBackTests={refreshBackTests} setBackTest={setBackTest} benchmarks={benchmarks}/>
                    <MainItem>                   
                       <Portfolios/>
                    </MainItem>
                     <HelpDrawer/>
                  </Container>
              </Box>
    </Box>
        </EquilyContext.Provider>
      
       
     
    );
  }
  
  export default Equily;