import {  Grid, Stack,Box } from "@mui/material";
import React, { useContext, useState, useEffect ,useCallback} from "react";

import Title from "../common/ui/Title";
import {EquilyContext} from '../common/Context'
import MyPortfolioSummaryCard from './MyPortfolioSummaryCard'
import MyPortfolioPerformance from './MyPortfolioPerformance'
import MyPortfolioAllocation from './MyPortfolioAllocation'
import { InvestorAccountContext } from "../auth/InvestorAccounts";
import { CallAPI } from "../common/services/api/CallAPI";
import MyPortfolioInvestments from "./dashinvestments/MyPortfolioInvestments";
import MyPortfolioActions from "./dashtrackers/MyPortfolioActions";
export function MyPorfoliosDash(props){

    const {benchmark,portfolios,refreshPortfolios,rebalanceTrackers} = useContext(EquilyContext);
    const {getSession} = useContext(InvestorAccountContext)

    const [portfolio, setPortfolio] = useState()
    const [portfolioHistory, setPortfolioHistory] = useState()

    const updatePortfolio = useCallback((newportfolio) => {
        setPortfolio(newportfolio)
        refreshPortfolios()
    },[refreshPortfolios])

    useEffect( () => {
        if(benchmark){
            const portfolioId = benchmark.portfolioId
            const p = portfolios.find( port => port.portfolioId===portfolioId)
            setPortfolio(p)
           
            getSession().then( ({headers}) => {  
                CallAPI('/engine/portfolios/history/'+p.portfolioId,{method: 'GET',headers}).then( data =>{
                    setPortfolioHistory(data)
                }).catch(err => {})
             })


        }

    },[benchmark,portfolios,getSession])

   
  
    return(
          <React.Fragment>

            <Box >
                <Title>
                    {benchmark.benchmarkName}
                </Title>
            </Box>
            <Stack spacing={2}>
               
                <Box>
                <MyPortfolioSummaryCard portfolio={portfolio} portfolioHistory={portfolioHistory}/>
                </Box>

                <Box sx={{paddingBottom: '16px'}}>
                <Grid  container direction="column" spacing={2}>
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={7}>
                            <MyPortfolioPerformance portfolio={portfolio} portfolioHistory={portfolioHistory}/>
                        </Grid>
                        <Grid item xs={5}>
                            <MyPortfolioAllocation portfolio={portfolio} benchmark={benchmark}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={5}>
                            <MyPortfolioActions benchmark={benchmark} rebalanceTrackers={rebalanceTrackers} portfolio={portfolio}/>
                        </Grid>
                        <Grid item xs={7}>
                        <MyPortfolioInvestments updatePortfolio={updatePortfolio} portfolio={portfolio}/>
                        </Grid>
                    </Grid>
                </Grid>
               
            </Grid> 

                </Box>
            </Stack>


             
           
        </React.Fragment>
    )

}