import { Avatar, Collapse, Divider, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { toCurrency } from "../common/ui/StringUtils"

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'

const PortfolioCardItem = (props) => {

    const {benchmark, portfolio} = props

    const [totalValue,setTotalValue] = useState()
    const [investmentTotal, setInvestmentTotal] = useState()
    const [cashTotal, setCashTotal] = useState()
    const [expanded,setExpanded] = useState(false)
    
    useEffect( () => {
        
        if(portfolio){
            var totalinv = 0
            if(portfolio.investments){
                portfolio.investments.forEach( (inv) => {
                    totalinv = totalinv + inv.value
                })
            }
            const totalinvst = toCurrency(Number(totalinv.toFixed(2)),portfolio.portfolioCurrencyCode)
            setInvestmentTotal(totalinvst)

            const totalval =  toCurrency(Number(portfolio.portfolioValue.toFixed(2)),portfolio.portfolioCurrencyCode)
            setTotalValue(totalval)
            var totalcash = 0
            if(portfolio.cash){
                portfolio.cash.forEach( (c) => {
                    totalcash = totalcash + c.value
                })
            }

            const totalcashst = toCurrency(Number(totalcash.toFixed(2)),portfolio.portfolioCurrencyCode)
            setCashTotal(totalcashst)
        }
    },[portfolio])


    return(
       
        <React.Fragment>
        <Grid container direction="column">
        <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={7}>
                    <Typography variant="subtitle1" color="primary">
                        {benchmark.benchmarkName}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                        
                        <Grid item xs={10}>
                            <Typography variant="subtitle1" color="primary">
                            {totalValue}
                            </Typography>
                        </Grid>
                       
                        <Grid item xs={2}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '5px',
                                    marginLeft: 1.875
                                }}
                                onClick={ () => setExpanded(!expanded)}
                            >
                                {expanded? <KeyboardArrowUpOutlinedIcon fontSize="small" color="inherit" />: <KeyboardArrowDownOutlinedIcon fontSize="small" color="inherit" />}
                            </Avatar>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item>                   
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Grid container direction="row" spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle2" color="secondary" sx={{  }}>
                                Invesments
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="secondary" sx={{  }}>
                               {investmentTotal}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>   
                    <Grid container direction="row"  spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle2" color="secondary" sx={{  }}>
                            Cash
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="secondary" sx={{  }}>
                            {cashTotal}
                        </Typography>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Collapse>
    </Grid>
    <Divider sx={{ my: 1.5 }} />
    </React.Fragment>
    )
}


export default PortfolioCardItem