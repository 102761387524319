import React, {} from "react";


import {List,ListItem,ListItemIcon} from '@mui/material'

import { EquilyListItemText, ManagedNestedParentListItemLink } from "../common/Link";
import {EquilyShareIconGrey, EquilyShareIconWhite} from '../icons/EquilyIcons'
export default function ShareMenu(props) {



    const children = [<ShareEmailLink key="shareLink"/>]



     return(
            <React.Fragment>
            <List key='shareMenu'>
             <ManagedNestedParentListItemLink 
              sx={{
                backgroundColor: 'pantone.pantone1235', 
                color: 'text.light',
                '&:hover': {
                    backgroundColor: 'pantone.pantone1235Dark', 
                },
                }}
              key="shareNestedLink"
              primary="Curate"
              icon={<EquilyShareIconWhite />} 
              children={children} 
              open={props.open}
              setOpen={props.setOpen} 
              setClose={props.setClose}/>
             </List>
            </React.Fragment>
        )

}

function ShareEmailLink(){

    return (
       
        <ListItem key="ShareLink" sx={{ pl: 4, backgroundColor:'pantone.pantone1235Light', color: 'inherit'}} component="a" href="mailto:support@equily.io">
        <ListItemIcon key="ShareLinkIcon">
        <EquilyShareIconGrey />
        </ListItemIcon>
        <EquilyListItemText key="ShareLinkText" primary={"Contact Us"} />
         </ListItem>

    )
}