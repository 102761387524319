import React, {useState,useContext} from "react";

import {Button,FormControl} from '@mui/material'
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


import FormColumn from '../../common/ui/FormColumn'
import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import CurrencySelect from "../../common/ui/CurrencySelect";


export default function AddCash(props){


    const [amount,setAmount] = useState()
    const [currency,setCurrency] = useState()
    const [error, setError] = useState()
    const {getSession} = useContext(InvestorAccountContext)

    const handleClose = () => {
        setAmount()
        setCurrency()
        setError()
        props.closeForm()
     }


    const addCash = () => {

        const cashReq = [{
            "currencyCode": currency, 
            "amount": parseFloat(amount),
        }]
        getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/portfolios/'+props.portfolio.portfolioName +'/cash',{
            method: 'POST',
            headers,
            body: JSON.stringify(cashReq)
          })
          .then( data =>{
            props.setInvestorPortfolio(data)
            handleClose()
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not add Cash</Alert>)
          })
        })
      
        handleClose()
    }

    return(

        <Dialog  open={props.open} onClose={handleClose} aria-labelledby="Add Cash">
        <DialogTitle id="add-cash-title">{"Add Cash to Portfolio"}</DialogTitle>
        {error}
        <DialogContent>
            <FormColumn>
                <FormControl variant="outlined" > 
                    <TextField autoFocus value={amount} onChange={ e=>setAmount(e.target.value)} margin="dense" id="amount" label="Amount"  type="number"/>
                </FormControl>
                <FormControl variant="outlined" > 
                  <CurrencySelect setCurrency={setCurrency}></CurrencySelect>         
                </FormControl>
           </FormColumn>
        </DialogContent>
       
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addCash} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>


    )

}