import {useEffect, useState, useContext} from "react";

import AppsIcon from '@mui/icons-material/Apps';
import List from '@mui/material/List'

import {NestedParentListItemLink} from '../../common/Link'
import {EquilyContext} from '../../common/Context'
import { MyPorfolioMenuItem } from "./MyPorfolioMenuItem";


export function MyPortfoliosMenu(props){

    const {benchmarks} = useContext(EquilyContext)
    const [myPortfolioItems, setMyPortfolioItems] = useState(null)

    useEffect(() => {

       if(benchmarks){
            const items = benchmarks.map((bm,index) =>
             <MyPorfolioMenuItem key={index} benchmark={bm}/>
            )
            const itemlist = 
            <List key="myportfoliolist" component="div" disablePadding>
                {items}
            </List>
            setMyPortfolioItems(itemlist)
       }
      }, [benchmarks]);
    
    return(
        <NestedParentListItemLink primary="My Portfolios" icon={<AppsIcon />} children={myPortfolioItems} />
    )

}