import React, {useState,useContext} from "react"

import Alert from '@mui/material/Alert';

import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormColumn from "../common/ui/FormColumn";
import { useNavigate  } from "react-router-dom";

export default function AddBackTest({open,handleClose,handleSave,benchmarks}) {

    
    const {getSession} = useContext(InvestorAccountContext)
   
   
    let navigate = useNavigate ();

    const [error,setError] = useState()
    const [saving, setIsSaving] = useState(false)
   
    const [backTestName, setBackTestName] = useState("")
    const [benchmarkName, setBenchmarkName] = useState("")
   
    let benchmarkMenuItems = []
    if(benchmarks) benchmarkMenuItems = benchmarks.map((bm,index) =>
              <MenuItem key={index} value={bm.benchmarkName}>{bm.benchmarkName}</MenuItem>
    )
          
    const saveBackTest = () => {
      const backTestReq = {
        "backTestName": backTestName, 
        "benchmarkName": benchmarkName,
      }
      getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          setIsSaving(true)
          CallAPI('/engine/backtest',{
            method: 'POST',
            headers,
            body: JSON.stringify(backTestReq)
          })
          .then( data =>{
            setIsSaving(false)
            handleSave(data)
            setBackTestName("")
            setBenchmarkName("")
            navigate("/backtest/detail")
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setIsSaving(false)
            setError(<Alert severity="error">Could not save BackTest</Alert>)
          });
        })
    }


    const cancel = () => {
      setBackTestName("")
      setBenchmarkName("")
      handleClose()
    }
   

    return(


      
      <Dialog open={open} onClose={cancel} aria-labelledby="BackTest">
      <DialogTitle id="add-stock-title">Add BackTest</DialogTitle>
      {error}
      <DialogContent>
          <FormColumn>
              <FormControl variant="outlined" fullWidth  >
                  <TextField autoFocus value={backTestName} onChange={e => setBackTestName(e.target.value)} id="backTestName" label="BackTest Name" type="text" />
              </FormControl>
              <FormControl variant="outlined" fullWidth  >
                  <InputLabel id="benchmark-label">Model Portfolio</InputLabel>
                  <Select
                      labelId="benchmark-label"
                      id="benhcmark--select"
                      value={benchmarkName}
                      onChange={e => setBenchmarkName(e.target.value)}
                      variant="outlined"
                      label="Model Portfolio"
                  >
                      {benchmarkMenuItems}
                  </Select>
              </FormControl>
           
              </FormColumn >
      </DialogContent>

      <DialogActions>
          <Button autoFocus onClick={cancel} color="primary">
              Cancel
          </Button>
          <Button onClick={saveBackTest} color="primary" autoFocus>
               {saving && <CircularProgress size={14} />}
               {!saving && "Create"}
          </Button>
      </DialogActions>
     
    </Dialog>
  
    )


}