import { Button, Grid, Typography } from "@mui/material";
import { SignInBox } from "./SignInBox";
import { Box } from "@mui/system";
import { useState } from "react";
import ForgottenPasswordCard from "./ForgottenPasswordCard";
import SignInContent from "./SignInContent";


export default function SignInCard({handleStatus,handleRegisterClick,investorID,setInvestorID,password,setPassword,handleLogin,facebookSignIn,googleSignIn}){


    const [hasForgotten, setHasForgetton] = useState(false)
  

    return(
        <SignInBox>
        <Grid sx={{mt:'5px'}} container wrap="nowrap" spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                <Typography variant="h6">Sign In</Typography>
                </Grid>
                <Grid item>
                <Button variant="outlined" color="primary">
                    <Typography variant="button" onClick={handleRegisterClick}>Register</Typography>
                </Button>
                </Grid>
        </Grid>
       
        {hasForgotten? <ForgottenPasswordCard login={handleLogin} initialEmail={investorID} handleStatus={handleStatus}/> : <SignInContent investorID={investorID} setInvestorID={setInvestorID} password={password} setPassword={setPassword} handleRegisterClick={handleRegisterClick} handleForgottenClick={()=> setHasForgetton(true)} handleLogin={handleLogin} facebookSignIn={facebookSignIn} googleSignIn={googleSignIn}/>}


        <Grid container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
        <Grid item>
        <Box
          component="img"
          sx={{
            mt: 1,
            height: 37,
            width: 143.5,
          }}
          alt="Equily"
          src="/Equily_Alternate_logo.png"
        />
          </Grid>
        </Grid>
        
        </SignInBox>
    )
}