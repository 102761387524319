import React, {useState} from "react";
import {TreeView,TreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';



export default function ModelAllocationTree(props){
    
   
    const modelAllocation = props.modelAllocation
    const [expanded, setExpanded] = useState([modelAllocation.allocation.uri])
    const [selected, setSelected] = useState([modelAllocation.allocation.uri])
   
  
    const label = (allocation) => {
      var root = allocation.allocationName + " - " + allocation.weighting.toFixed(2)+"%"
      if(allocation.modelWeighting) root = root + " (" + allocation.modelWeighting.toFixed(2) + "%)"
      return root
    }

    const renderTree = (allocation) => {
        if(allocation){ 
        return(
          <TreeItem  key={allocation.uri} nodeId={allocation.uri} label={label(allocation)}>
            {Array.isArray(allocation.childAllocations) ? allocation.childAllocations.map((node) => renderTree(node)) : null}
          </TreeItem>
        )
        }
        
    }

    const selectNode = ((event, allocationuri) => {
      props.selectAllocation(allocationuri)
      setSelected([allocationuri])
    })

    const handleToggle = (event, nodeIds) => {
      setExpanded(nodeIds);
    };
  


    return(
       
            <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={[modelAllocation.allocation.uri]}
            onNodeSelect={selectNode}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            >
             {renderTree(modelAllocation.allocation)}
            </TreeView>
    )

}