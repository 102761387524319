import React, { useEffect, useState,useMemo,useContext} from "react";


import { Fab,Paper,Button, Grid, Box, FormControl } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Tooltip from '@mui/material/Tooltip';


import MUIDataTable from "mui-datatables";


import { CallAPI} from '../common/services/api/CallAPI'
import {ExcelUploadDialog} from '../common/excel/ExcelUpload'
import { nonSelectTableOptions,renderDate,renderNumber } from "../common/tables/Table";
import LoadingTableBody from "../common/tables/LoadingTableBody";
import Title from '../common/ui/Title'
import FormColumn from '../common/ui/FormColumn'
import {InvestorAccountContext} from '../auth/InvestorAccounts'


function Stocks(props) {


    // form stuff
    const [alert, setAlert] = useState(); 
    const [open, setOpen] = useState(false)
    const [stocks, setStocks] = useState([])
    const [symbol, setSymbol] = useState()
    const [currencyCode, setCurrencyCode] = useState()
    const [price, setPrice] = useState()
    const [saveResult, setSaveResult] = useState()
    const [loading, setLoading] = useState(true)
  
    // excel form
    const [excelOpen, setExcelOpen] = useState(false)
    const [excelFile,setExcelFile] = useState()

    const {getSession} = useContext(InvestorAccountContext)


    const columns = [
      { name: 'symbol', label: 'Stock Symbol' },
      { name: 'currencyCode', label: 'Currency Code' },
      { name: 'price', label: 'Price',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(stocks,dataIndex,'price') }}},
      { name: 'historyStartDate', label: 'History Start Date',options: {customBodyRenderLite: (dataIndex) => { return renderDate(stocks,dataIndex,'historyStartDate') }}},
   
    ]
  
    const BodyComponent = useMemo(
      () => (props) => (
        <LoadingTableBody loading={loading} {...props} />
      ),
      [loading]
    );
  


    useEffect(() => {
      getSession().then( ({headers}) => {CallAPI('/engine/stocks',{method: 'GET',headers}).then( data =>{
          setStocks(data)
          setLoading(false)
        })
        .catch((error) => {
          console.log("There was an error getting stocks " + error)
          setAlert(<Alert severity="error">Could not fetch Stocks</Alert>)
        })
      })
    
    }, [saveResult,getSession]);
   
   
    const handleClickOpen = () => {
      setOpen(true)
    };
  
    const handleClose = () => {
      setOpen(false)
      resetAddStockForm()
    };

    const resetAddStockForm = () => {
      setSymbol("")
      setPrice("")
      setCurrencyCode("")
    }


    const handleSave = () => {
      setLoading(true)
      const stockObj = {
          "symbol": symbol, 
          "currencyCode": currencyCode,
          "price": parseFloat(price),
      }
      getSession().then( ({headers}) => {
        headers['Content-Type'] =  'application/json'
        CallAPI('/engine/stocks/pricing',{
          method: 'POST',
          headers,
          body: JSON.stringify([stockObj])
        }).then(data => {
          setSaveResult(data)
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setAlert(<Alert severity="error">Could not save Stock</Alert>)
        })
      })
      setOpen(false)
      resetAddStockForm()
    }

    const handleExcelOpen = () => {
      setExcelFile(null)
      setExcelOpen(true)
    }

    const closeExcel = () => {
      setExcelOpen(false)
    }

    const uploadExcel = () => {
     
      setLoading(true)
      const formData = new FormData();
      formData.append('file', excelFile);
      
      getSession().then( ({headers}) => {
        CallAPI('/engine/stocks/pricing/excel',{
          method: 'POST',
          headers,
          body: formData,
        }).then(data => {
          setSaveResult(data)
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setAlert(<Alert severity="error">Could not save Stock</Alert>)
        })
      })
      setExcelOpen(false)
    
    }

   
    



    return(
        <React.Fragment>
      
     
       
       
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
          <Title>
            Stocks
          </Title> 

          {alert} 
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Tooltip title="Add Stock" aria-label="Upload">
                <Fab color="primary" aria-label="add" zindex="tooltip" onClick={handleClickOpen}>
                  <AddIcon/>
                </Fab>
              </Tooltip>
            
              <Tooltip title="Upload via Excel" aria-label="Upload">
                <Fab color="primary" aria-label="add" zindex="tooltip" onClick={handleExcelOpen}>
                  <InsertDriveFileIcon/>
                </Fab>
              </Tooltip>
             
            </Grid>
          </Grid>
        </Grid>

        <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
          <Paper>
          <MUIDataTable data={stocks} columns={columns} options={nonSelectTableOptions}  components={{ TableBody: BodyComponent }}/> 
          </Paper>
        </Box>
   
      <Dialog open={open} onClose={handleClose} aria-labelledby="Add Investor">
        <DialogTitle id="add-stock-title">{"Add Stock"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a Stock to Equily 
          </DialogContentText>
          <FormColumn>
            <FormControl variant="outlined" fullwidth>
              <TextField autoFocus value={symbol} onChange={ e=>setSymbol(e.target.value)}  id="symbol" label="Stock Symbol" type="text"/>
            </FormControl>
            <FormControl variant="outlined">
              <TextField value={currencyCode} onChange={ e=>setCurrencyCode(e.target.value)}  id="code" label="Currency Code" type="text"/>
            </FormControl>
            <FormControl variant="outlined">
              <TextField value={price} onChange={ e=>setPrice(e.target.value)} margin="dense" id="price" label="Price" type="number"/>
            </FormControl>
          </FormColumn>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ExcelUploadDialog setFile={setExcelFile} open={excelOpen} handleClose={closeExcel} handleUpload={uploadExcel}/>
    </React.Fragment>

    )

}

export default Stocks