import React, {useContext,useState,useEffect} from "react";

import Alert from '@mui/material/Alert';
import { Box, Fab, Grid, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';

import Title from "../common/ui/Title";
import { EquilyContext } from "../common/Context";
import DeleteRebalanceTracker from "./DeleteRebalanceTracker";
import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import RebalanceTrackerResults from "./RebalanceTrackerResults";
import { EditRebalanceTracker } from "./EditRebalanceTracker";

export default function RebalanceTracker(props) {

    const {rebalanceTracker} = useContext(EquilyContext);

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [currentResult, setCurrentResult] = useState()
    const [historicResults, setHistoricResults] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
    const {getSession} = useContext(InvestorAccountContext)

    useEffect(() => {
      if (rebalanceTracker) {
        setLoading(true)
        getSession().then( ({headers}) => {
          CallAPI('/engine/rebalancetracker/'+rebalanceTracker.trackerName + '/results',{
            method: 'GET',
            headers,
          })
          .then( data =>{
            data.sort((a, b) => (a.runDate < b.runDate) ? 1 : -1)
            setCurrentResult(data[0])
            data.splice(0,1)
            setHistoricResults(data)
            setLoading(false)
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setLoading(false)
          });
        })
      }
    }, [rebalanceTracker,getSession]);



    const runTracker = () => {
      setLoading(true)
      getSession().then( ({headers}) => {
        CallAPI('/engine/rebalancetracker/'+rebalanceTracker.trackerName + '/run',{
          method: 'GET',
          headers,
        })
        .then( data =>{
          rebalanceTracker.lastRunDate = data.runDate
          if(data.actions){
            rebalanceTracker.lastActionDate = data.runDate
            var newhist = [currentResult].concat(historicResults)
            setHistoricResults(newhist)          
            setCurrentResult(data)
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log("There was an error " + error)
          setError(<Alert severity="error">Could not run rebalance tracker</Alert>)
        });
      })
    }


    return(

        <Box sx={{display: 'flex', flexGrow: '1', 'flexDirection': 'column', 'alignItems': 'left'}}>

         
       
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
               <Grid item xs={9}>
                <Title>
              {rebalanceTracker.trackerName} 
              </Title>
              {error}
              </Grid>

              <Grid item xs={3}>
            <Grid container direction="row" spacing={1} justifyContent="flex-end" alignItems="right">
              <Grid item>
               <Tooltip title="Run Tracker" aria-label="Run">
                <span>
                <Fab color="secondary" aria-label="run" zindex="tooltip" onClick={runTracker}>
                  <PlayArrowIcon />
                </Fab>
                </span>
               </Tooltip>
               </Grid>

               <Grid item>
              <Tooltip title="Edit Tracker" aria-label="Edit">
                <Fab  color="secondary" aria-label="run" zindex="tooltip" onClick={() => setEditOpen(true)}>
                  <EditIcon />
                </Fab>
              </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Delete Tracker" aria-label="Delete">
                <Fab color="secondary" aria-label="delete" zindex="tooltip" onClick={() => setDeleteOpen(true)}>
                    <DeleteForeverIcon />
                </Fab>
                </Tooltip>
                </Grid>
              
               </Grid>
          </Grid>


        </Grid>
       
       
        <Grid sx={{m:1, p:1}} container direction="row" justifyContent="space-between" alignItems="center">
        
        <Grid item>
        
        <Typography variant="h6" gutterBottom component="div">
        Benchmark
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.benchmarkName}
      </Typography>
      </Grid>

      <Grid item>
      
      <Typography variant="h6" gutterBottom component="div">
       Maximum cost of trade
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.costEfficiency.maxPercentageCostOfTrade}%
      </Typography>
      
      </Grid>
      
      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Threshold Type
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.threshold.thresholdType}
      </Typography>
      </Grid>

      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Threshold Value
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.threshold.value}
      </Typography>
      </Grid>
      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Cost Per Trade
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.costPerTrade}
      </Typography>
      </Grid>
    
      </Grid>
        
  
      <Grid sx={{m:1, p:1}} container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Last Run Date
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.lastRunDate ? new Date(rebalanceTracker.lastRunDate).toLocaleString() : "-"}
      </Typography>
      </Grid>
      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Last Action Date
        </Typography>
        <Typography variant="body1" gutterBottom>
        {rebalanceTracker.lastActionDate ? new Date(rebalanceTracker.lastActionDate).toLocaleString() : "-"}
      </Typography>
      </Grid>
      </Grid>
        <RebalanceTrackerResults loading={loading} currentResult={currentResult} historicResults={historicResults}/>
        <DeleteRebalanceTracker trackerName={rebalanceTracker.trackerName} open={deleteOpen} handleClose={() => setDeleteOpen(false)}/>
        <EditRebalanceTracker  open={editOpen} handleClose={() => setEditOpen(false)}/>
  
      </Box>


      


    )


}