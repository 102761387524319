import React, { useState, useCallback} from "react"
import {  IconButton, Tooltip, Menu, MenuItem,ListItemIcon, Typography, Divider} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { EquilyBuildIconGrey, EquilyOptimiseIconGrey } from "../../icons/EquilyIcons"

export function HelpMenu(props){
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
      setAnchorEl(null);
    }

  
 
    

  const openInNewTab = useCallback((url) => { 
      window.open(url, '_blank').focus()
    },[])

    return(
        <React.Fragment>
        <Tooltip title="Help">
            <IconButton
                color="inherit"  onClick={handleClick}
            >
                <HelpIcon />
            </IconButton>
        </Tooltip>
         <Menu
         anchorEl={anchorEl}
         open={open}
         onClose={handleClose}
         onClick={handleClose}
         PaperProps={{
           elevation: 0,
           sx: {
             overflow: 'visible',
             filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
             mt: 1.5,
             '& .MuiAvatar-root': {
               width: 32,
               height: 32,
               ml: -0.5,
               mr: 1,
             },
             '&:before': {
               content: '""',
               display: 'block',
               position: 'absolute',
               top: 0,
               right: 14,
               width: 10,
               height: 10,
               bgcolor: 'background.paper',
               transform: 'translateY(-50%) rotate(45deg)',
               zIndex: 0,
             },
           },
         }}
         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
       >                  
                       
                         <Divider />
                        <MenuItem key="Equily Insights" onClick={() => openInNewTab("https://equily.io/insights/")}>
                          <ListItemIcon>
                            <EquilyBuildIconGrey/>
                          </ListItemIcon>
                          <Typography component="h6">Equily Insights</Typography> 
                        </MenuItem>

                        <MenuItem key="Build a Model Portfolio" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/equily-how-to-build-a-model-portfolio/")}>
                          <ListItemIcon>
                            <EquilyBuildIconGrey/>
                          </ListItemIcon>
                          <Typography component="h6">Build a Model Portfolio</Typography> 
                        </MenuItem>
                        <MenuItem key="Optimise your Portfolio" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/equily-onboarding-how-to-optimize-your-portfolio-step-1/")}>
                          <ListItemIcon>
                            <EquilyOptimiseIconGrey/>
                          </ListItemIcon>
                          Optimize Your Portfolio
                        </MenuItem>
                        <MenuItem key="Backtest" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/equily-onboarding-step-3-analyzing-back-test-results/")}>
                          <ListItemIcon>
                            <EquilyOptimiseIconGrey/>
                          </ListItemIcon>
                          Backtest and Analyze
                        </MenuItem>
                        <MenuItem key="Modify" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/how-to-modify-your-portfolio-allocation-and-then-re-test/")}>
                          <ListItemIcon>
                          <EquilyBuildIconGrey/>
                          </ListItemIcon>
                          Modify & Retest
                        </MenuItem>
                        <MenuItem key="Additional Tests" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/equily-onboarding-step-4-optimizing-running-additional-back-tests-and-comparing-results/")}>
                          <ListItemIcon>
                          <EquilyBuildIconGrey/>
                          </ListItemIcon>
                          Further Testing
                        </MenuItem>
                        <MenuItem key="Duplicate Allocations" onClick={() => openInNewTab("https://equily.io/equily-onboarding-support/how-to-duplicate-allocations/")}>
                          <ListItemIcon>
                          <EquilyBuildIconGrey/>
                          </ListItemIcon>
                          Duplicate Allocations
                        </MenuItem>
                        

      </Menu>
       </React.Fragment>
    )


}