
import React, { useState } from "react";
import { Grid, Typography ,Divider, Tooltip} from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import { toCurrency } from "../../common/ui/StringUtils";
import MyPortfolioEditCash from "./MyPortfolioEditCash";

const MyPortfolioCashItem = (props) => {

    const cash = props.cash
    const portfolioCurrencyCode = props.portfolioCurrencyCode
    const portfolioName = props.portfolioName
    const updatePortfolio = props.updatePortfolio


    const [editOpen, setEditOpen] = useState(false)

   
    return(

        <React.Fragment>
        <Grid container direction="column">
        <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={5}>
                    <Typography variant="subtitle1" color="primary">
                        {cash.currencyCode}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                        <Tooltip title="Amount">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="inherit">
                            { toCurrency(Number(cash.amount.toFixed(2)),cash.currencyCode)}
                            </Typography>
                        </Grid>
                        </Tooltip>
                        <Tooltip title="Value">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="inherit">
                            { toCurrency(Number(cash.value.toFixed(2)),portfolioCurrencyCode)}
                            </Typography>
                        </Grid>
                        </Tooltip>
                        <Grid item xs={1}>
                            <EditIcon   sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '5px',
                                    marginTop: '5px'
                                }}
                                onClick={ () => setEditOpen(true)}variant="rounded"/>
                           
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
       
    </Grid>
    <Divider sx={{ my: 1.5 }} />
    <MyPortfolioEditCash closeForm={() => setEditOpen(false)} open={editOpen} cash={cash}  updatePortfolio={updatePortfolio} portfolioName={portfolioName}/>

    </React.Fragment>
    )


}
 
export default MyPortfolioCashItem