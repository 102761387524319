import React, {useState,useContext,useEffect} from "react"
import {DialogTitle,DialogContent,DialogActions,Dialog} from '@mui/material'
import {CircularProgress, Button, FormControl,TextField } from '@mui/material'
import Alert from '@mui/material/Alert';


import { CallAPI } from '../common/services/api/CallAPI'

import {InvestorAccountContext} from '../auth/InvestorAccounts'
import { EquilyContext } from "../common/Context";


export function CopyModelAllocation(props) {

    const {addModelAllocation} = useContext(EquilyContext)
    
    const modelAllocationName = props.modelAllocationName

    const [name, setName] = useState("")
    const [error, setError] = useState("")
    const [actionActive, setActionActive] = useState(false)

    const {getSession} = useContext(InvestorAccountContext)

    const copyModelAllocation = () => {
      setActionActive(true)
      getSession().then( ({headers}) => {
      CallAPI('/engine/models/'+modelAllocationName+'/copy/'+name,{
        method: 'POST',headers
      })
      .then( data =>{
         addModelAllocation(data)
          setActionActive(false)
          props.handleClose()
      })
      .catch((error) => {
        error.json().then( text => {
          const msg = text.message.split(':')[1];
          setError(<Alert severity="error">{msg}</Alert>)
          setActionActive(false)
        })
      })
    })
    
      setName("")
    }


    useEffect(() => {
      setError("")
    }, [props.open]);





    return(

        <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="confirm-allocation-copy"
        aria-describedby="confirm allocation copy"
      >
        <DialogTitle id="confirm-allocation-copy">{"Copy Allocation"}</DialogTitle>
        {error}
        <DialogContent>
        <form  noValidate autoComplete="off">
            <FormControl > 
                <TextField autoFocus value={name} onChange={ e=>setName(e.target.value)}  id="allocationName" label="Model Allocation Name" type="text"  variant="outlined" fullWidth/>
            </FormControl>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={copyModelAllocation} color="primary" autoFocus>
                     {actionActive && <CircularProgress size={14} />}
                     {!actionActive && "Copy"}
          </Button>
        </DialogActions>
      </Dialog>




    )


}