
import {  Grid, Typography } from "@mui/material"

import BackTestResultARR from "./BackTestResultARR"

export default function BackTestResultRiskData(props){

    const backTestResult = props.backTestResult



    return(
       
      
        <Grid container  justifyContent="space-evenly"  spacing={2} direction="row">
        <Grid item>
                <Grid container   justifyContent="space-between" alignItems="center" direction="column">
                    <Grid item>
                    <Typography  variant="h6">
                    Risk Adjusted Return
                    </Typography>
                    </Grid>
                    <Grid item>
                    {backTestResult.rar? backTestResult.rar.toFixed(2) : "-" }
                    </Grid>
                </Grid>
        </Grid>

        <Grid item>
            <BackTestResultARR backTestResult={backTestResult}/>
        </Grid>


       
        <Grid item>
        <Grid container  justifyContent="center" alignItems="center" direction="column">
            <Grid item>
            <Typography  variant="h6">
               Volatility
            </Typography>
            </Grid>
            <Grid item>
            <Typography  variant="subtitle1">
            {backTestResult.volatility? backTestResult.volatility.toFixed(2) : "-" }
             </Typography>
            </Grid>
            
        </Grid>
        </Grid>
       
        </Grid>
        
   
    )
}

