import { Alert, Collapse, IconButton } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export default function BannerAlert({severity,timed,children}){

    const [open, setOpen] = useState(true);

   
    if(timed){
        setTimeout(()=>{
            setOpen(false)
        },3000)
    }

    return(

        <Collapse in={open}>
            <Alert severity={severity}
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpen(false)
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
          sx={{ mb: 2 }}
            >
            {children}
            </Alert>
        </Collapse>

    )

}