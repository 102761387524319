import React, {useContext,useEffect,useState} from "react";
import { useNavigate  } from "react-router-dom";

import { Box, Grid, Fab, Button, Divider} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert'

import {DialogContentText,DialogTitle,DialogContent,DialogActions,Dialog,CircularProgress} from '@mui/material'

import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import {EquilyContext} from '../common/Context'
import Title from '../common/ui/Title'
import { TabButton, TabPanel } from "../common/grids/TabPanel";
import BenchmarkConfigure from "./BenchmarkConfigure";
import BenchmarkData from "./BenchmarkData";

export default function Benchmark(props){


    const {deleteBenchmark,benchmark,portfolios} = useContext(EquilyContext);
    const {getSession} = useContext(InvestorAccountContext)
  
    const [openDeleteBenchmark, setOpenDeleteBenchmark] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [error, setError] = useState("")
    const [portfolioName, setPortfolioName] = useState()
    const [tabValue,setTabValue] = useState(0)
  
    const navigate = useNavigate ();

  
    useEffect(() => {
        if(benchmark){
            if(benchmark.benchmarkAllocation){
              // get the portfolio for the benchmark     
                var portfolio = portfolios.find( (p) => p.portfolioId===benchmark.portfolioId)
                setPortfolioName(portfolio.portfolioName)
            }
        }       
    }, [benchmark,portfolios]);

   
    const confirmDeleteBenchmark = () => {

        setDeleting(true)
       
        getSession().then( ({headers}) => { 
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/benchmark/'+benchmark.benchmarkName+'/delete',{
            method: 'DELETE',
            headers,
          })
          .then( data =>{
              setDeleting(false)
              setOpenDeleteBenchmark(false)
              deleteBenchmark(benchmark)
              navigate("/");
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not delete allocation mapping</Alert>)
            setDeleting(false)
          })
        })


       
    }
    
    return(

      <React.Fragment>
      <Box sx={{display: 'flex', flexGrow: '1', 'flexDirection': 'column', 'alignItems': 'left'}}>

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Title>
                {benchmark.benchmarkName} - {portfolioName}
                </Title>
                  <Tooltip title="Delete Model Portfolio" aria-label="Delete">
                  <Fab  color="secondary" aria-label="delete" zindex="tooltip" onClick={() => setOpenDeleteBenchmark(true)}>
                      <DeleteForeverIcon />
                  </Fab>
                  </Tooltip>
        </Grid>
  
        <Grid container direction="column" spacing={1}> 
          <Grid item>
            <Grid container spacing={2}>
                <Grid item>
                    <TabButton value={tabValue} index={0} onClick={() => setTabValue(0)}>Create</TabButton>
                </Grid>
                <Grid item>
                    <TabButton value={tabValue} index={1} onClick={() => setTabValue(1)}>Data</TabButton>
                </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider/>
          </Grid>
          <Grid item>
            <TabPanel value={tabValue} index={0}>
              <BenchmarkConfigure/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <BenchmarkData/>
            </TabPanel>
          </Grid>
          </Grid>




      </Box>


        <Dialog
        open={openDeleteBenchmark}
        onClose={confirmDeleteBenchmark}
        aria-labelledby="confirm-backtest-delete"
        aria-describedby="confirm backtest delete"
      >
        <DialogTitle id="confirm-backtest-delete">{"Confirm Delete Model Portfolio?"}</DialogTitle>
        <DialogContent>
          {error}
          <DialogContentText id="onfirm-backtest-delete-description">
           Are you sure you want to delete this Model Porfolio? Any associated Backtests will also be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteBenchmark(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteBenchmark} color="primary" autoFocus>
              {deleting && <CircularProgress size={14} />}
              {!deleting && "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>


    )

}