import React, { useEffect, useState, useContext } from 'react'

import { Grid,Button, Paper, Slider, TextField, Typography } from '@mui/material'

import { InvestorAccountContext } from "../../auth/InvestorAccounts";
import ModelPortfolioOverviewChart from './ModelPortfolioOverviewChart';
export default function ModelPortfolioOverview(props){

   

    const modelPortfolio = props.modelPortfolio
    const modelPortfolioDetail = props.modelPortfolioDetail
    const {getSession} = useContext(InvestorAccountContext)

    const [portfolioSize, setPortfolioSize] = useState(50000)
    const [startDateMilli, setStartDateMilli] = useState(0)
    const [startDateText, setStartDateText] = useState("")
    const [startLimit, setStartLimit] = useState(0)
    const [finishLimit, setFinishLimit] = useState(0)
    const [historyData, setHistoryData] = useState([])
    const [finishValue, setFinishValue] = useState(0)
    const [currencyCode, setCurrencyCode] = useState("")
   // const [indexData, setIndexData] = useState()
    const [index, setIndex] = useState("")
    const [chartStyle,setChartStyle] = useState({ opacity: 0.1})
    const [returnAmount, setReturnAmount] = useState()
    const [returnPercentage, setReturnPercentage] = useState()

    console.log(index)
    useEffect( () => {

        if(modelPortfolioDetail){

            const pcode = modelPortfolioDetail.modelCurrencyCode
            setCurrencyCode(pcode)
            var indexstr =  "MSCIWORLD.INDX"
            if (pcode==='GBP') indexstr = "FTSE.INDX"
            setIndex(indexstr)
            
            const history = modelPortfolioDetail.backTestResult.portfolioHistory    
            if(history){
                const first = history[0].portfolioDate
                const last = history[history.length-1].portfolioDate
                setStartLimit(first)
                setStartDateMilli(first)
                setStartDateText(new Date(first).toLocaleDateString())
                setFinishLimit(last)  
                
                /*
                getSession().then( ({headers}) => {CallAPI('/engine/stocks/history/' + indexstr, {
                    method: 'GET', headers
                })
                    .then(data => {
                    var run = { key: indexstr}
                
                    const timelimited = data.adjustedPriceHistoryData.filter( v => {
                        return (v.recordDate >= first && v.recordDate <= last) 
                    })
                    const priceData = timelimited.map( d =>{
                            const close = parseFloat(d.adjustedClose.toFixed(2))
                            var dataItem = { x: d.recordDate, y: close }
                            return dataItem
                    })
                    run.values= priceData 
                    setIndexData(run.values)
                   // setHistoryData([run])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                })
                */
            }
        }
    },[modelPortfolioDetail,getSession])

    const handleSliderChange = (event,newValue) => {
        if(historyData.length>0)setHistoryData([])
        setStartDateMilli(newValue);
        const d = new Date(newValue)
        setStartDateText(d.toLocaleDateString())
    }
    
    const calculate = () => {
            const history = modelPortfolioDetail.backTestResult.portfolioHistory    
            if(history){
                var run = { key: "Your Portfolio"}
                var runData = []

                const timelimitedPortfolioData = history.filter( hist => {
                    return (hist.portfolioDate>=startDateMilli) 
                })


                const initialValue = timelimitedPortfolioData[0].portfolioValue
                const ratio = portfolioSize/initialValue
                timelimitedPortfolioData.forEach((hist) => {
                    const value = hist.portfolioValue * ratio
                    const portfolioValue = parseFloat(value.toFixed(2))
                    var dataItem = { x: hist.portfolioDate, y: portfolioValue}
                    runData.push(dataItem)
                })
                setFinishValue(runData[runData.length-1].y)
                run.values= runData 

                const start = runData[0]
                const end = runData[runData.length-1]
                //var normalisedIndexRun = { key: index}
               
                const returnValue = end.y - start.y
                setReturnAmount(returnValue)
                const percentage = (returnValue/portfolioSize) * 100.0
                setReturnPercentage(percentage)
/*
                const timelimited = indexData.filter( v => {
                    return (v.x >= start.x && v.x <= end.x) 
                })
               
                const indexFirst = timelimited[0].y
                const indexratio = runData[0].y/indexFirst
             
                const normalisedData = timelimited.map( tl => {
                    const size = tl.y * indexratio
                    var val = { x: tl.x , y: size}
                    return val
                })
              
               normalisedIndexRun.values = normalisedData
  */
        //       setHistoryData([run,normalisedIndexRun])
        setHistoryData([run])
          
        const style  = {opacity: 1.0}
               setChartStyle(style)
            }
    }

    console.log(historyData)
    return(
                <React.Fragment>
                    <Paper>
                    <Grid container  sx={{"paddingLeft":2, paddingBottom:2}} direction="column">
                      
                        <Grid item>
                            <Typography variant="h6">
                               Investment Simulator
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={4} container direction="column" spacing={2}>
                                <Grid item>

                                    <Typography paragraph>
                                         What could my investment pot be worth if I had invested using {modelPortfolio.name}?
                                     </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField autoFocus value={portfolioSize} onChange={e => setPortfolioSize(e.target.value)} id="portfolioSize" label="Portfolio Size" type="number" variant="outlined" fullWidth />
                                </Grid>
                                <Grid item>
                                    <TextField autoFocus value={startDateText} onChange={e => setStartDateText(e.target.value)} id="StartDate" label="Start Date" type="text"  disabled variant="outlined" fullWidth  />
                                </Grid>
                                <Grid item>
                              
                                <Slider
                                    value={startDateMilli}
                                    onChange={handleSliderChange}
                                    aria-labelledby="input-slider"
                                    step={86400000}
                                    min={startLimit}
                                    max={finishLimit}
                                />
                               
                                </Grid>
                                <Grid item>
                                <Button  onClick={calculate} size="small" variant="contained">Calculate</Button>

                                </Grid>
                                 <Grid item>
                                    <Typography paragraph>
                                        {returnAmount? <div>Your Rebalanced Return: {returnAmount.toFixed(2)} ({returnPercentage.toFixed(2)}% profit))</div>: <div></div>}
                                    </Typography>
                            </Grid>
                                </Grid>
                                <Grid item xs={8} sx={chartStyle}>
                                     {historyData.length>0?  <ModelPortfolioOverviewChart currencyCode={currencyCode} historyData={historyData} portfolioSize={portfolioSize} finishValue={finishValue}/> : <div></div> }
                                 </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                       
                      
                    </Paper>
                </React.Fragment>
    )
        
            
                     
}