import React, {  } from 'react'

import { ModelPortfolioDiscover } from './discover/ModelPortfolioDiscover';


export function ModelPortfolioStoreSummary(props){

    const setSelectedModelPortfolio = props.setSelectedModelPortfolio
    
    return(
        // used to be tabs but currently jsut this - holding this component incase we add tabs back in 
        <React.Fragment>
                <ModelPortfolioDiscover selectModelPortfolio={setSelectedModelPortfolio}/>
        </React.Fragment>
    )

}


