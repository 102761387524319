import { Box, CssBaseline } from "@mui/material";

export default function SingleLayerContainer({children}){

    return(


        <Box
        display="flex" 
       justifyContent="center"
       alignItems="center"
       sx={{
         backgroundColor: "pantone.pantone7546",
         height: '100vh',
       }}
     >
       <CssBaseline />
          {children}
          </Box>
     
       
    )

}