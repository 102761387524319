
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'

import TextField from '@mui/material/TextField';



export function ExcelUploadDialog(props){
   
   

 
    
    return(
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="Add Investor">
      <DialogTitle id="add-stock-title">{"Add Stock"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Upload Excel File
        </DialogContentText>
        <form noValidate autoComplete="off">
          <TextField autoFocus onChange={ e => props.setFile(e.target.files[0])} margin="dense" id="symbol" label="Excel File" type="file"/>
        </form>
      </DialogContent>
    
      <DialogActions>
        <Button autoFocus onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.handleUpload} color="primary" autoFocus>
          Upload
        </Button>
      </DialogActions>
      </Dialog>
    )


}