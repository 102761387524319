import React, {}  from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { InvestorAccountContext } from "./InvestorAccounts";




export function AuthorisedRoute({ children}) {
    const {user} = useContext(InvestorAccountContext)
    return (user.attributes && user.attributes["custom:terms"]==="1") ? <>{children}</> : <Navigate to="/auth/terms" />
  }
  

  