import { CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { InfoCardWrapper } from "../common/grids/CardWrappers";
import { toCurrency } from "../common/ui/StringUtils";


export default function MyPorfolioSummaryCard(props){


  
    const portfolio = props.portfolio
    const portfolioHistory = props.portfolioHistory

    const [totalReturn, setTotalReturn] =useState()
    const [totalValue,setTotalValue] = useState()
    const [investmentTotal, setInvestmentTotal] = useState()
    const [cashTotal, setCashTotal] = useState()



    useEffect( () => {
        if(portfolio){
            var totalinv = 0
            if(portfolio.investments){
                portfolio.investments.forEach( (inv) => {
                    totalinv = totalinv + inv.value
                })
            }
            const totalinvst = toCurrency(Number(totalinv.toFixed(2)),portfolio.portfolioCurrencyCode)
            setInvestmentTotal(totalinvst)

            const totalval =  toCurrency(Number(portfolio.portfolioValue.toFixed(2)),portfolio.portfolioCurrencyCode)
            setTotalValue(totalval)

            var totalcash = 0
            if(portfolio.cash){
                portfolio.cash.forEach( (c) => {
                    totalcash = totalcash + c.value
                })
            }

            const totalcashst = toCurrency(Number(totalcash.toFixed(2)),portfolio.portfolioCurrencyCode)
            setCashTotal(totalcashst)
        }
    },[portfolio])

    useEffect( () => {

        if(portfolioHistory && portfolioHistory.values){
            const values = portfolioHistory.values;
            const start = values[0].portfolioValue
            const finish = values[values.length-1].portfolioValue
            const returnAmt = finish-start
            const returnPer = (returnAmt/start)*100
            const returnSt = Number(returnPer.toFixed(2)).toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) + "%"
                               
            setTotalReturn(returnSt)
        }
    },[portfolioHistory])



    return(
        <React.Fragment>
           <InfoCardWrapper>
                <CardContent>
                    <Grid container >
                            <Grid item xs={3}>
                                <Grid item>
                                <Typography component="div" variant="h6" color="primary">
                                Total Value
                                </Typography>
                                </Grid>
                                <Grid item>
                                {totalValue}
                                </Grid> 
                            </Grid>
                            <Grid item xs={3}>
                                <Grid item>
                                <Typography component="div" variant="h6" color="primary">
                                Investments
                                </Typography>
                                </Grid>
                                <Grid item>
                                {investmentTotal}
                                </Grid> 
                            </Grid>
                            <Grid item xs={3}>
                                <Grid item>
                                <Typography component="div" variant="h6" color="primary">
                                Cash
                                </Typography>
                                </Grid>
                                <Grid item>
                                {cashTotal}
                                </Grid> 
                            </Grid>
                            <Grid item xs={3}>
                                <Grid item>
                                <Typography component="div" variant="h6" color="primary">
                                 Total Return
                                </Typography>
                                </Grid>
                                <Grid item>
                                    {totalReturn}
                                </Grid> 
                            </Grid>
                    </Grid>
                </CardContent>
              
           </InfoCardWrapper>
        </React.Fragment>
    )

}