import { Card, CardContent, Grid, Typography, TextField, MenuItem} from "@mui/material";
import React, { useEffect,useState} from "react";
import MyPortfolioInvestmentItem from "./MyPortfolioInvestmentItem"
import MyPortfolioCashItem from "./MyPortfolioCashItem"
export default function MyPortfolioInvestments(props){
    
    const portfolio = props.portfolio
    const updatePortfolio = props.updatePortfolio

    const [investmentItems, setInvestmentItems] = useState([])
    const [cashItems, setCashItems] = useState([])
    const [items, setItems] = useState([])
    const [value, setValue] = useState('investments')

    const status = [
        {
            value: 'investments',
            label: 'Investments'
        },
        {
            value: 'cash',
            label: 'Cash'
        },
    ]

    useEffect( () => {
        if(portfolio){
            var investments = []
            if(portfolio.investments) investments = portfolio.investments

            var invs = investments.map( (investment) => {
                return <MyPortfolioInvestmentItem key={investment.stock.symbol} updatePortfolio={updatePortfolio} portfolioName={portfolio.portfolioName} portfolioCurrencyCode={portfolio.portfolioCurrencyCode} investment={investment}/>
            })
            setInvestmentItems(invs)


            var cash = []
            if(portfolio.cash) cash = portfolio.cash

            var c = cash.map( (cash) => {
                return <MyPortfolioCashItem updatePortfolio={updatePortfolio} portfolioName={portfolio.portfolioName} portfolioCurrencyCode={portfolio.portfolioCurrencyCode} cash={cash}/>
            })
            setCashItems(c)
        }

    },[portfolio,updatePortfolio])


    useEffect( () => {
        if(value==='investments'){
            setItems(investmentItems)
        }else{
            setItems(cashItems)
        }
    },[value,investmentItems,cashItems])

    return(
        <React.Fragment>
            <Card sx={{
                   borderRadius: '16px'
                }}>
                <CardContent>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Portfolio</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {items}
                        </Grid>
                    </Grid>
                </CardContent>
           </Card>
        </React.Fragment>
    )
}