
import { useNavigate  } from "react-router-dom";
import React,{useState,useContext} from "react";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import AddIcon from '@mui/icons-material/Add'
import {DialogTitle,DialogContent,DialogActions,Dialog} from '@mui/material'
import {CircularProgress, Button, FormControl,TextField } from '@mui/material'

import {EquilyContext} from '../../common/Context'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { CallAPI } from '../../common/services/api/CallAPI'
import Alert from '@mui/material/Alert';
import FormColumn from "../../common/ui/FormColumn";
import { EquilyListItemText } from "../../common/Link";




export function AddModelAllocationMenuItem(props){
    
  
    const {addModelAllocation} = useContext(EquilyContext)
    const {getSession} = useContext(InvestorAccountContext)
    let navigate = useNavigate ();

    const [error,setError] = useState()
    const [open,setOpen] = useState(false)
    const [name, setName] = useState("")
    const [desc,setDesc] = useState("")
    const [saving, setSaving] = useState(false)
   
   
    const showDialog = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setName("")
        setDesc("")
        setOpen(false)
    }

    const createModel = () => {
        setSaving(true)
        var req = [{
            "modelAllocationName": name,
            "modelAllocationDescription": desc,
        }]
        getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/models/',{
            method: 'POST',
            headers,
            body: JSON.stringify(req)
          })
          .then( data =>{
              setOpen(false)
              setName("")
              setDesc("")
              setSaving(false)
              addModelAllocation(data)
              navigate("/modelallocation");
          })
          .catch((error) => {
            if(error.statusText){
              setError(<Alert severity="error">{error.statusText}</Alert>)
              setSaving(false)
            }
            else{
              error.text().then( r => {
                setError(<Alert severity="error">{r}</Alert>)
                setSaving(false)
              })
            }
          })
        })
      
       
    }

    return(
        <React.Fragment>
            <ListItem key='AddModelAllocation' id='AddModelAllocation' button sx={{ pl: 4 }}>
                <ListItemIcon>
                     <AddIcon />
                </ListItemIcon>
                <EquilyListItemText key='AddModelAllocationText' primary='Add Allocation' onClick={showDialog}/>
            </ListItem>
      
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirm-backtest-copy"
                aria-describedby="confirm backtest copy"
            >
            <DialogTitle id="confirm-backtest-delete">{"Create Allocation"}</DialogTitle>
                 {error}
            <DialogContent>
            <FormColumn>
                <FormControl > 
                    <TextField autoFocus value={name} onChange={ e=>setName(e.target.value)}  id="modelName" label="Allocation Name" type="text"  variant="outlined" fullWidth/>
                </FormControl>
                <FormControl> 
                    <TextField autoFocus value={desc} onChange={ e=>setDesc(e.target.value)}  id="modelDesc" label="Allocation Description" type="text"  variant="outlined" fullWidth/>
                </FormControl>
            </FormColumn>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={createModel} color="primary" autoFocus>
                     {saving && <CircularProgress size={14} />}
                     {!saving && "Create"}
          </Button>
        </DialogActions>
      </Dialog>


      </React.Fragment>
    )

}