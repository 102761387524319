import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Equily from "./Equily";
import { InvestorAccount } from "../auth/InvestorAccounts";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { PrivateRoute } from "../auth/PrivateRoute";
import { SingleLayerSignIn } from "../auth/signin/SingleLayerSignIn";
import { AuthenticatedRoute } from "../auth/AuthenticatedRoute";
import { Terms } from "../auth/Terms";
import { ShepherdTour } from "react-shepherd";
import { tourOptions, welcomeSteps } from "../help/shepherd/Shepherd";

import "../help/shepherd/shepherd.css"


const theme = createTheme({
  palette: {
    primary: {
      light: "#006072",
      main: "#253746",
      dark: "#1d252c",
      contrastText: "#ffffff",
      backgroundTint: "RGB(157,119,147,.10)",
      decisionButton: "#ffb81d",
      decisionButtonHover: "#f6b436",
      discoverButton: "#9d7793",
      discoverButtonHover: "RGB(52,39,49)",
    },
    secondary: {
      light: "#fff350",
      main: "#ffc107",
      dark: "#c79100",
      contrastText: "#000000",
    },
    pantone: {
      pantone1235: "#ffb81d",
      pantone1235Dark: "#f1a500",
      pantone1235Light: "#ffedc6",

      pantone645: "#7DA1C4",
      pantone645Dark: "#5b89b4",
      pantone645Light: "#dee7f0",

      pantone7546: "#253746",
      pantone7546Dark: "#192631",
      pantone7546Light: "#bdcedc",
    },
    text: {
      light: "white",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "Times New Roman", "Arial"].join(","),
    textTransform: "none",
    body1: {
      fontFamily: "Arial",
    },
  },
});

export default function AuthThemedEquily(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
      <ShepherdTour steps={welcomeSteps} tourOptions={tourOptions}>
        <InvestorAccount>
          <Router>
            <Routes>
              <Route exact path="/auth/login" element={<SingleLayerSignIn />} />

              <Route exact
                path="/auth/terms"
                element={
                  <AuthenticatedRoute>
                    <Terms />
                  </AuthenticatedRoute>
                }
              />
              
                <Route path="/*" 
                  element={<PrivateRoute><Equily/></PrivateRoute>}    
                />
                
            </Routes>
          </Router>
        </InvestorAccount>
        </ShepherdTour>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
