import {  useEffect, useMemo, useState } from "react"


import NVD3Chart from 'react-nvd3'
import { renderCurrency } from "../../common/tables/Table";
import { infographicColour } from "../../common/ui/Colours";
import Loader from "../../common/ui/Loader";
import { Paper } from "@mui/material";

export default function BackTestSummaryChartNVD3({backTestResultData}){
    
    const [chartData, setChartData] = useState([])

    let currencyCode = backTestResultData[0]? backTestResultData[0].result.portfolioCurrencyCode : "USD"
   
    useEffect( () => {
        if(backTestResultData.length>0){
           
                let runData = buildRunData(backTestResultData)
                let currencyCode = "USD"
                // we have runs - get the index data if USD
                if(currencyCode==="USD"){
                  // SHould get the MSCI INDEX HERE BUT DATA PROVIDER CURRENTLY RUBBISH
                    setChartData(runData)  
                }else{
                    setChartData(runData)
                }
        }
    },[backTestResultData])





    const buildRunData = (resultData)=>{
        let runs = []
        resultData.forEach( (pdata) => {
            const result = pdata.result  
            const portfolioHistory = pdata.portfolioData
            var run = { key: "Run " + result.runId}
            if(portfolioHistory){
                var runData = portfolioHistory.map((hist) => {
                    const portfolioValue = parseFloat(hist.portfolioValue.toFixed(2))
                    var dataItem = { x: hist.portfolioDate, y: portfolioValue}
                    return dataItem
                })
                run.values= runData 
                const c = infographicColour(result.runId)
                run.color = c
            }
            runs.push(run)
        })
        return runs
    }

    const item = useMemo( ()=>{
        if(chartData.length===0){
             return <Loader/>
        }else{
            return <NVD3Chart sx={{p:'1rem'}} color={infographicColour("test")} id="line" type="lineWithFocusChart" datum={chartData} x="x" y="y"
            xAxis={{
            tickFormat: (d) => new Date(d).toLocaleDateString(),
            margin: 20,
            }}
            x2Axis={{
            tickFormat: (d) => new Date(d).toLocaleDateString(),
            }}
            yAxis={{
                tickFormat: (d) => renderCurrency(d,currencyCode),
            }}
            height="500"
            margin={{top: 10, right: 50, bottom: 10, left: 90}}
            />
        }

   },[chartData,currencyCode])
   
        return(
          <Paper>
           {item}
         </Paper>
        )
    

}