import {useEffect, useState, useContext} from "react";

import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import List from '@mui/material/List'


import {NestedParentListItemLink} from '../../common/Link'
import {EquilyContext} from '../../common/Context'
import {PortfolioMenuItem} from './PortfolioMenuItem'
import {AddPortfolioMenuItem} from './AddPortfolioMenuItem'



export function PortfolioMenu(props){

    
    const {portfolios} = useContext(EquilyContext)
    const [portfolioitems, setPortfolioItems] = useState(null)


    useEffect(() => {
       
       if(portfolios){

        const items = portfolios.map((portfolio,index) =>
            <PortfolioMenuItem key={index} portfolio={portfolio}/>
        )
        
        const addPort = <AddPortfolioMenuItem key="addPortfolio"></AddPortfolioMenuItem>
        items.push(addPort)
        
        const itemlist = 
          <List key="PortfolioList"  component="div" disablePadding>
              {items}
          </List>

        setPortfolioItems(itemlist)
       }
      }, [portfolios]);
    


    return(

        
        <NestedParentListItemLink primary="Investments" icon={<DashboardCustomizeIcon />} children={portfolioitems} />

    )

}