import React, { useContext, useEffect, useState} from "react"

import {ListItemLink} from '../common/Link'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import {List} from '@mui/material'
import {InvestorAccountContext} from '../auth/InvestorAccounts'

export default function MainMenu(props) {

    const {user} = useContext(InvestorAccountContext)

    const [items,setItems] = useState([])
    useEffect(() => {
        var mits = []
        if(user.username){
            mits.push( <ListItemLink key="dash" to="/" primary="Dashboard" icon={<DashboardIcon />} />)
           
            if(user.admin){
                mits.push( <ListItemLink key="investors" to="/investors" primary="Investors" icon={<PersonOutlineIcon />} />)
                mits.push( <ListItemLink key="stocks" to="/stocks" primary="Stocks" icon={<ShowChartIcon />} />)
            }
        }
        setItems(mits)
    },[user])

    return(

        <List>
            {items}
        </List>


    )

}