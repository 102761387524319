import { Avatar, Collapse, Divider, Grid, Tooltip, Typography } from "@mui/material"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import React, { useState } from "react"

import { toCurrency } from "../../common/ui/StringUtils";

const TrackerActionItem = (props) => {

    const action = props.action
    const [expanded,setExpanded] = useState(false)
   
    return(
        <React.Fragment>
        <Grid container direction="column">
        <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={2}>
                    <Typography variant="subtitle1" color="primary">
                      {action.actionType}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" color="primary">
                      {action.stock.symbol}
                    </Typography>
                </Grid>
                <Grid item xs={6}>

                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item >
                        <Tooltip title="Trade Volume">
                            <Typography variant="subtitle1" color="primary">
                            {action.postVolume - action.preVolume}
                            </Typography>
                        </Tooltip>
                    </Grid>
                
                    <Grid item>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        width: 16,
                                        height: 16,
                                        borderRadius: '5px',
                                        marginLeft: 1.875
                                    }}
                                    onClick={ () => setExpanded(!expanded)}
                                >
                                    {expanded? <KeyboardArrowUpOutlinedIcon fontSize="small" color="inherit" />: <KeyboardArrowDownOutlinedIcon fontSize="small" color="inherit" />}
                                </Avatar>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item>
             <Grid container spacing={2}>
                <Grid item xs={6}> 
                    <Typography variant="subtitle2" color="secondary" sx={{  }}>
                        Price: {action.stock.price}
                    </Typography>                
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" color="secondary" sx={{  }}>
                    Trade Value: { toCurrency(Number(action.stock.price * (action.postVolume - action.preVolume)),action.stock.currencyCode)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" color="secondary" sx={{  }}>
                    Pre %: 
                    { Number(action.prePercentage.toFixed(2)).toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) }
                               
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2" color="secondary" sx={{  }}>
                    Post %:     { Number(action.postPercentage.toFixed(2)).toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) }
                
                    </Typography>
                </Grid>
            </Grid>                      
        </Grid>
       

        </Collapse>
    </Grid>
    <Divider sx={{ my: 1.5 }} />
    </React.Fragment>
    )
}

export default TrackerActionItem