
export const CallAPI = (url,request) => {
   return fetch(encodeURI(getURL(url)), request).then(response => {
         if (response.ok) {
           return response.json()
         } else {
          throw response
         }
      })
      
 }

 export const CallAPIPaginated = (url,request) => {
   return fetchPage(getURL(url), 0, request,[])
 }

const getURL = (url) => {
  const default_api = getDefaultURL(url)
  const API_URL = process.env.REACT_APP_API_URL || default_api 
  // temp for riga work
  //const API_URL = 'https://api.equily.io/dev'
  const baseURL = API_URL + url
  return baseURL
}  

const getDefaultURL = (url) => {
  
  const loc = window.location.href
  const index = loc.indexOf(":3000")
  var port = ":8080"
  if(url.includes("store")) port = ":8081" 
  const default_api = loc.slice(0,index) + port + "/dev"
  return default_api
}


const fetchPage = (url,page,request,previousResponse) =>{
  const purl = url+'?page='+page
  return fetch(encodeURI(purl),request)
  .then(response => response.json())
  .then(newResponse => {
    var response = previousResponse
    if(newResponse.content){
      response = [...previousResponse, ...newResponse.content]
      if (newResponse.content.length===newResponse.pageable.size) {
        page++;
        return fetchPage(url, page, request, response)
      }
    }
    return response;
  }).catch( error => console.log(error))
}