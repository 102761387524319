import { Box, Card, CardContent, Grid, Typography,TextField, MenuItem } from "@mui/material";
import React, { useState,useEffect,useCallback, useRef} from "react";
import Sunburst from '../sunburst-chart'
import { infographicColour } from "../common/ui/Colours";


export default function MyPorfolioAllocation(props){


    const benchmark = props.benchmark
    const portfolio = props.portfolio

    const [allocationChartData, setAllocationChartData] = useState()
    const [value, setValue] = useState('actual')

    const container = useRef()
    const sunburstLibrary = useRef(null)
   
    const status = [
        {
            value: 'actual',
            label: 'Actual'
        },
        {
            value: 'target',
            label: 'Target'
        },
        {
            value: 'difference',
            label: 'Difference'
        }
    ]

    const addInvestmentsToLabel = useCallback((invs) => {
        var label = ""
        if(invs){
            if(invs.length>0) label = label + " ["
            var first = true
            for (var y =0; y<invs.length; y++) {
                if(first){
                    label = label + invs[y]
                    first = false
                }
                else label = label + "," +  invs[y]
            }   
            if(invs.length>0) label = label + "]"
        }
        return label
    },[])

    const labelBenchmark = useCallback((node) => {
        var label = node.name
        if(node.cash.length>0) {
            label = label + addInvestmentsToLabel(node.cash)
        }
        if(node.investments.length>0) label = label + addInvestmentsToLabel(node.investments)
        return label
    },[addInvestmentsToLabel])


    const buildBenchmarkChartData = useCallback((benchmarkAllocation,portfolio) => {
        
        var node = {}
       
        if(benchmarkAllocation){ 
          
            node.name = benchmarkAllocation.allocation.allocationName
            node.weighting = benchmarkAllocation.allocation.weighting
            
            node.modelweighting = benchmarkAllocation.allocation.modelWeighting
            node.uri = benchmarkAllocation.uri
            node.children = []
            node.investments = []
            node.cash = []
            if(benchmarkAllocation.childBenchmarkAllocations){
                benchmarkAllocation.childBenchmarkAllocations.forEach( (child) => {
                    var childnode = buildBenchmarkChartData(child,portfolio)
                    node.children.push(childnode)
                })
            }
            if(benchmarkAllocation.investmentIds){
                benchmarkAllocation.investmentIds.forEach( (id) => {
                  
                    var investment = portfolio.investments.find( inv => inv.investmentId===id)
                    if(investment){
                        node.investments.push(investment.stock.symbol)
                    }
                })
            }
            if(benchmarkAllocation.cashIds){
                benchmarkAllocation.cashIds.forEach( (id) => {
                    var cash = portfolio.cash.find( inv => inv.cashId===id)
                    if(cash){
                        node.cash.push(cash.currencyCode)
                    }
                })
            }
            node.label = labelBenchmark(node)
            if(node.children.length===0)node.value = benchmarkAllocation.allocation.modelWeighting
        }
        return node;
        },[labelBenchmark])
  


    useEffect( () => {
        if(benchmark && benchmark.benchmarkAllocation && portfolio){
            var benchmarkChartdata = buildBenchmarkChartData(benchmark.benchmarkAllocation,portfolio)
            setAllocationChartData(benchmarkChartdata)
        }

    },[benchmark,buildBenchmarkChartData,portfolio])

    useEffect(() => {
        if(allocationChartData){
                if(!sunburstLibrary.current){ 
                    sunburstLibrary.current = Sunburst()(container.current).excludeRoot(true).imageSize(30).width(window.innerWidth*0.28).height(361.5).label(d => d.label)
                }
                sunburstLibrary.current.data(allocationChartData).color(d => infographicColour(d.name))
        }
    },[allocationChartData])

     
    return(
        <React.Fragment>
           <Card sx={{
                   borderRadius: '16px'
                }}>
                <CardContent>
           <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Allocations</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}} ref={container}></Box>
                        </Grid>
                    </Grid>
                </CardContent>
           </Card>
        </React.Fragment>
    )

}