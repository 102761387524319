import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, CircularProgress, MenuItem, Paper, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { InvestorAccountContext } from "../../auth/InvestorAccounts";
import { CallAPI } from "../../common/services/api/CallAPI";
import dayjs from 'dayjs';
import BannerAlert from "../../common/ui/BannerAlert";


export default function EditBackTestDetail({backTest,refreshBackTests,setBackTest}){

    const {getSession} = useContext(InvestorAccountContext)
    
    
    const [status, setStatus] = useState()
    const [saving, setSaving] = useState(false)
    
    let defaultDetail = {
      backTestType: backTest.backTestType? backTest.backTestType : 'Balanced',
      initialPortfolioValue: backTest.initialPortfolioValue,
      startDate: (backTest.startDate)? backTest.startDate: null,
      endDate: (backTest.endDate)? backTest.endDate: null,
      ...(backTest.costEfficiency) && {costEfficiency: {maxPercentageCostOfTrade: backTest.costEfficiency.maxPercentageCostOfTrade}},
      costPerTrade: backTest.costPerTrade,
      freeTrades: backTest.freeTrades,
      freeTradePeriod: backTest.freeTradePeriod?backTest.freeTradePeriod : "MONTH" ,
      ...(backTest.threshold)? {threshold: {
        thresholdType: backTest.threshold.thresholdType,
        value: backTest.threshold.value
      }} : {threshold: { thresholdType: "AbsolutePercentage"}},
    }
  
    const [detail, setDetail] = useState(defaultDetail)
    let valueFieldEnabled = detail.backTestType==='Balanced' || !detail.backTestType


    const readyToSave = detail.threshold.value!==undefined
    
    const updateBackTest = () =>{
        setSaving(true)
        setStatus()
        // map detail to update object
        const update = {
            "backTestName": backTest.backTestName, 
            "benchmarkName": backTest.benchmarkName,
            "thresholdType": detail.threshold.thresholdType, 
            "thresholdLevel": detail.threshold.value,
            "initialPortfolioValue": detail.initialPortfolioValue,
            "startDate": detail.startDate,
            "endDate": detail.endDate,
            "costPerTrade": detail.costPerTrade,
            "freeTrades": detail.freeTrades,
            "backTestType": detail.backTestType,
            "freeTradePeriod": detail.freeTradePeriod
          } 
          if(detail.costEfficiency) update.maxPercentageCostOfTrade= detail.costEfficiency.maxPercentageCostOfTrade
          
            getSession().then( ({headers}) => { 
              headers['Content-Type'] =  'application/json'
              CallAPI('/engine/backtest/'+backTest.backTestName,{
                method: 'POST',
                headers,
                body: JSON.stringify(update)
              })
              .then( data =>{
                  //props.setBackTest(data)
                  refreshBackTests()
                  setBackTest(data)
                  setSaving(false)
                  let alert = <BannerAlert severity="success" timed>Backtest Saved</BannerAlert>
                  setStatus(alert)  
              })
              .catch((error) => {
                setSaving(false)
                console.log("There was an error " + error)
                setStatus(<BannerAlert severity="error">Could not save Backtest</BannerAlert>)
              })
            })
          
    }
    
    const updateThresholdType = (value) => {
      let threshold = {thresholdType: value}
      if(detail.threshold){
        threshold.value = detail.threshold.value
      }
      setDetail({...detail, threshold: threshold})
    }
    const updateThresholdValue = (value) => {
      let threshold = {value: value}
      
      if(detail.threshold){
        threshold.thresholdType = detail.threshold.thresholdType
      }else{
        threshold.thresholdType = "AbsolutePercentage"
      }
      setDetail({...detail, threshold: threshold})
    }
    return(
        
        
        <>
        <Paper  sx={{
           p: 4,
          }}>
        {status}
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
        <div>
        <div>
          Rebalance Test Parameters
        </div>
        <TextField
          id="outlined-select-thresholdtype"
          select
          label="Power-Up"
          name="threshold.thresholdType"
          value={(detail.threshold)? detail.threshold.thresholdType : "AbsolutePercentage"}
          onChange={e => updateThresholdType(e.target.value)}
        >
          <MenuItem value="AbsolutePercentage">Absolute Percentage</MenuItem>
          <MenuItem value="RelativePercentage">Relative Percentage</MenuItem>
          <MenuItem value="Volatility">Volatility</MenuItem>
          <MenuItem value="Value">Value</MenuItem>
          <MenuItem value="Time">Time (Months)</MenuItem>         
        </TextField>
        <TextField required name="threshold.value" value={(detail.threshold)? detail.threshold.value : ""} onChange={e => updateThresholdValue(e.target.value)} id="price" label="Amount" type="number"/>  
        <TextField
          select
          labelid="threshold-type-label"
          id="threshold-type-select"
          value={detail.backTestType}
          onChange={e => setDetail({...detail,backTestType: e.target.value})}
          variant="outlined"
          label="Portfolio at Start"
          name="backTestType"
          defaultValue="Balanced"
        >
              <MenuItem value="Balanced">Balanced</MenuItem>
              <MenuItem value="FixedVolume">Actual Portfolio</MenuItem>
              
        </TextField>
        <TextField name="initialPortfolioValue" value={detail.initialPortfolioValue} onChange={e => setDetail({...detail,initialPortfolioValue: e.target.value})} id="initialPortfolioValue" label="Starting Portfolio Value" type="number"   InputProps={{
            readOnly: !valueFieldEnabled,
          }}/>
        </div>
        </Box>


        <Box
          component="form"
          sx={{
            'paddingTop': '1rem',
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
        <div>
          Date Range (Optional)
        </div>
        <div>
          <DatePicker
            label="Start"
            inputFormat="dd/MM/yyyy"
            value={dayjs(detail.startDate)}
            name="startDate"
            onChange={e => setDetail({...detail,startDate: e})}
          />
          <DatePicker
            label="End"
            inputFormat="dd/MM/yyyy"
            value={dayjs(detail.endDate)}
            name="endDate"
            onChange={e => setDetail({...detail,endDate: e})}
          />
        </div>
        </Box>
        <Box
          component="form"
          sx={{
            'paddingTop': '1rem',
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
        <div>
          Trading Simulation (Optional)
        </div>
        <div>
        <TextField  name="costEfficiency.maxPercentageCostOfTrade" value={(detail.costEfficiency) ? detail.costEfficiency.maxPercentageCostOfTrade : ""} onChange={e => setDetail({...detail,costEfficiency: {maxPercentageCostOfTrade: e.target.value}})} id="price" label="Max Cost of Trade (%)" type="number"/>
        <TextField name="costPerTrade" value={detail.costPerTrade} onChange={e => setDetail({...detail,costPerTrade: e.target.value})} id="price" label="Fee Per Trade" type="number"/>
        <TextField name="freeTrades" value={detail.freeTrades} onChange={e => setDetail({...detail,freeTrades: e.target.value})} id="price" label="Free Trades"  type="number"/>
        <TextField
          select
          labelid="period-type-label"
          id="period-type-select"
          value={detail.freeTradePeriod}
          onChange={e => setDetail({...detail,freeTradePeriod: e.target.value})}
          variant="outlined"
          label="Frequency"
          name="freeTradePeriod"
        >
              <MenuItem key="DAY" value="DAY">Day</MenuItem>
              <MenuItem key="WEEK" value="WEEK">Week</MenuItem>
              <MenuItem key="MONTH" value="MONTH">Month</MenuItem>
              <MenuItem key="YEAR" value="YEAR">Year</MenuItem>
        </TextField>
    
        </div>
        </Box>
        <Box
          component="form"
          sx={{
            'paddingTop': '1rem',
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
        <Button disabled={!readyToSave} variant="contained" onClick={updateBackTest} color="primary">
          {saving && <CircularProgress size={14} />}
          {!saving && "Save"}
        </Button>
        </Box>
        </Paper>
      </>


    )
}