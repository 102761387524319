
import { useNavigate  } from "react-router-dom";
import React,{useEffect, useState,useContext} from "react";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import AddIcon from '@mui/icons-material/Add'
import {DialogTitle,DialogContent,DialogActions,Dialog} from '@mui/material'
import {CircularProgress, Button, FormControl,TextField } from '@mui/material'

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'


import {EquilyContext} from '../../common/Context'
import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import Alert from '@mui/material/Alert';
import FormColumn from "../../common/ui/FormColumn";
import { EquilyListItemText } from "../../common/Link";




export function AddBenchmarkMenuItem(props){
    
   

    const {addBenchmark,modelAllocations,portfolios} = useContext(EquilyContext)
    const {getSession} = useContext(InvestorAccountContext)
    let navigate = useNavigate ();

    const [error,setError] = useState()
    const [open,setOpen] = useState(false)
    const [benchmarkName, setBenchmarkName] = useState("")
    const [modelAllocationName,setModelAllocationName] = useState("")
    const [portfolioName, setPortfolioName] = useState("")
    const [saving, setSaving] = useState(false)
    const [portfolioNames, setPortfolioNames] = useState([])
    const [modelAllocationNames, setModelAllocationNames] = useState([])


    useEffect(() => {
      if(portfolios){
        setPortfolioNames(portfolios.map(p => <MenuItem key={p.portfolioName} value={p.portfolioName}>{p.portfolioName}</MenuItem>))
      }
      if(modelAllocations){
        setModelAllocationNames(modelAllocations.filter(m => m.validModel===true).map( m => <MenuItem key={m.modelAllocationName} value={m.modelAllocationName}>{m.modelAllocationName}</MenuItem>))
      }
      }, [portfolios,modelAllocations]);
    


    const showDialog = () => {
        setOpen(true)
        setError()
    }
    const handleClose = () => {
        setBenchmarkName("")
        setModelAllocationName("")
        setPortfolioName("")
        setOpen(false)
    }

    const createBenchmark = () => {
        setSaving(true)
        var req = {
            "benchmarkName": benchmarkName,
            "modelAllocationName": modelAllocationName,
            "portfolioName": portfolioName,
        }
        getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/benchmark/',{
            method: 'POST',
            headers,
            body: JSON.stringify(req)
          })
          .then( data =>{
              handleClose()
              addBenchmark(data)
              navigate("/benchmark");
          })
          .catch((error) => {
            if(error.statusText){
              setError(<Alert severity="error">{error.statusText}</Alert>)
              setSaving(false)
            }
            else{
              error.text().then( r => {
                setError(<Alert severity="error">{r}</Alert>)
                setSaving(false)
              })
            }
          })
        })      
       
    }
   
    return(
        <React.Fragment>
            <ListItem key='AddModelPortfolio' id='AddModelPortfolio' button sx={{ pl: 4 }}>
                <ListItemIcon>
                     <AddIcon />
                </ListItemIcon>
                <EquilyListItemText key='AddModelPortfolioText' primary='Add Model Portfolio' onClick={showDialog}/>
            </ListItem>
      
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirm-modelportfolio-copy"
                aria-describedby="confirm modelportfolio copy"
            >
            <DialogTitle id="confirm-backtest-delete">{"Create Model Portfolio"}</DialogTitle>
                 {error}
            <DialogContent>
            <FormColumn>
                <FormControl > 
                    <TextField autoFocus value={benchmarkName} onChange={ e=>setBenchmarkName(e.target.value)}  id="benchmarkName" label="Model Portfolio Name" type="text"  variant="outlined" fullWidth/>
                </FormControl>
                <FormControl variant="outlined" >
                        <InputLabel id="portfolioName-label">Allocation</InputLabel>
                        <Select
                            labelId="modelAllocationName-label"
                            id="modelAllocationName-select"
                            value={modelAllocationName}
                            onChange={e => setModelAllocationName(e.target.value)}
                            variant="outlined"
                            label="Allocation"
                        >
                          {modelAllocationNames}
                        </Select>
                </FormControl>
                <FormControl variant="outlined" >
                        <InputLabel id="portfolioName-label">Portfolio</InputLabel>
                        <Select
                            labelId="portfolioName-label"
                            id="portfolioName-select"
                            value={portfolioName}
                            onChange={e => setPortfolioName(e.target.value)}
                            variant="outlined"
                            label="Portfolio"
                        >
                          {portfolioNames}
                        </Select>
                </FormControl>
            </FormColumn>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={createBenchmark} color="primary" autoFocus>
                     {saving && <CircularProgress size={14} />}
                     {!saving && "Create"}
          </Button>
        </DialogActions>
      </Dialog>


      </React.Fragment>
    )

}