import { Box, Divider, Grid } from "@mui/material"
import { useContext, useState } from "react"
import { TabButton, TabPanel } from "../../common/grids/TabPanel"

import BackTestControls from "../BackTestControls"
import { useEffect } from "react"
import { InvestorAccountContext } from "../../auth/InvestorAccounts"
import { CallAPIPaginated } from "../../common/services/api/CallAPI"
import BackTestResultPerformance from "./BackTestResultPerformance"
import BackTestTrades from "./BackTestTrades"
import BackTestResultRebalancing from "./BackTestResultRebalancing"
import { BackTestResultTitle } from "../BackTestTitles"
import BackTestResultVolatilityChart from "./BackTestResultVolatilityChart"

export default function BackTestResult({backTestResultDetails,handleClearSelectedResult}){

   
    const [tabValue,setTabValue] = useState(0)
    const [status,setStatus] = useState()
    const [backTestResultActions, setBackTestResultActions] = useState([])
    const [backTestPortfolioData, setBackTestPortfolioData] = useState([])

    const {getSession} = useContext(InvestorAccountContext)
    
    useEffect( () => {
        if(backTestResultDetails){
            getSession().then( ({headers}) => {
                var pr = CallAPIPaginated('/engine/backtest/'+backTestResultDetails.backTestName+'/result/actions/'+backTestResultDetails.backTestResult.resultId,{method: 'GET',headers})
                pr.then( data => setBackTestResultActions(data))
                var prPortfolio = CallAPIPaginated('/engine/backtest/'+backTestResultDetails.backTestName+'/result/portfolio/'+backTestResultDetails.backTestResult.resultId,{method: 'GET',headers})
                prPortfolio.then( data => setBackTestPortfolioData(data))
            }) 
        }

    },[backTestResultDetails,getSession])

   
    return(
        <>
        <Grid  container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
            <BackTestResultTitle clearResult={handleClearSelectedResult} backTestResult={backTestResultDetails.backTestResult} backTestName={backTestResultDetails.backTestName}/>
            </Grid>
            <Grid item xs={4}>
                <BackTestControls disabled/>
            </Grid>
        </Grid>
       
        <Box sx={{ height: '100%' }}>
        <Grid container direction="column" spacing={1}>
        <Grid item>{status}</Grid> 
        <Grid item>
        <Grid container spacing={2}>
            <Grid item>
                <TabButton value={tabValue} index={0} onClick={() => setTabValue(0)}>Charts</TabButton>
            </Grid>
            <Grid item>
                <TabButton value={tabValue} index={1} onClick={() => setTabValue(1)}>Trades</TabButton>
            </Grid>
            <Grid item>
                <TabButton value={tabValue} index={2} onClick={() => setTabValue(2)}>Results</TabButton>
            </Grid>
            <Grid item>
                <TabButton value={tabValue} index={3} onClick={() => setTabValue(3)}>Volatility</TabButton>
            </Grid>
        </Grid>
        </Grid>
        <Grid item>
        <Divider/>
        </Grid>
        <Grid item>
            <TabPanel value={tabValue} index={0}>
                <BackTestResultPerformance backTestResultActions={backTestResultActions} backTestResult={backTestResultDetails.backTestResult} handleStatus={(astatus) => setStatus(astatus)}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <BackTestTrades backTestResultActions={backTestResultActions}/>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <BackTestResultRebalancing backTestResult={backTestResultDetails.backTestResult}/>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <BackTestResultVolatilityChart backTestPortfolioData={backTestPortfolioData}/>
            </TabPanel>
        </Grid>
        </Grid>
        </Box>
        </>
    )

}