import React, {}  from "react";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { AuthorisedRoute } from "./AuthorisedRoute";



export function PrivateRoute({ children, ...rest }) {
   
     return (
      <AuthenticatedRoute {...rest}> 
          <AuthorisedRoute {...rest}>
          {children}
          </AuthorisedRoute>
      </AuthenticatedRoute>      
    )
  }
  

  