import { Paper } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { nonSelectTableOptions, renderCurrencyNumber, renderDate, renderNumber } from "../../common/tables/Table";


export default function BackTestTrades({backTestResultActions}){

    let data = backTestResultActions.map((row) => {
        var newrow = {
          actionDate: new Date(row.actionDate),
          actionType: row.actionType,
          symbol: row.stock.symbol,
          tradingVol: row.stats.postVolume - row.stats.preVolume,
          price: row.stock.price,
          //value: row.stock.price * (row.stats.postVolume - row.stats.preVolume),
          value: (row.stats.postValue-row.stats.preValue),
          prePercentage: row.stats.prePercentage,
          postPercentage:row.stats.postPercentage,
          preValue: row.stats.preValue,
          postValue: row.stats.postValue,
          preVol: row.stats.preVolume,
          postVol: row.stats.postVolume,
          currencyCode: row.stock.currencyCode,
          postInvestCash: row.stats.postInvestCash,
          postTotalCash: row.stats.postTotalCash,
        }
        return newrow
      })

    const columns = [
        { name: 'actionDate', label: 'Action Date',options: {customBodyRenderLite: (dataIndex) => { return renderDate(data,dataIndex,'actionDate') }}},
        { name: 'actionType', label: 'Action Type'},
        { name: 'symbol', label: 'Stock' },
        { name: 'tradingVol', label: 'Trade Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'tradingVol') }}},
        { name: 'price', label: 'Price',options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'price',data[dataIndex].currencyCode) }}},
        { name: 'value', label: 'Trade Val.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'value') }}},
        { name: 'postTotalCash', label: 'Total Cash',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postTotalCash') }}},
        { name: 'postInvestCash', label: 'Investable Cash',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postInvestCash') }}},
        { name: 'prePercentage', label: 'Pre %.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'prePercentage') }}},
        { name: 'postPercentage', label: 'Post %',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postPercentage') }}},
        { name: 'preValue', label: 'Pre Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preValue') }}},
        { name: 'postValue', label: 'Post Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postValue') }}},
        { name: 'preVol', label: 'Pre Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preVol') }} },
        { name: 'postVol', label: 'Post Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postVol') }}},
      
      ]

    return(
        <Paper>
        <MUIDataTable
            data={data}
            columns={columns}
            options={nonSelectTableOptions}
        />
        </Paper>
    )

}