import React, {useState,useContext} from "react"
import { useNavigate  } from "react-router-dom"


import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import {EquilyContext} from '../common/Context'

export default function DeleteRebalanceTracker(props){


    const [error, setError] = useState()
    const [deleting, setDeleting] = useState()

    const {getSession} = useContext(InvestorAccountContext)
    const navigate = useNavigate ();

    const {refreshReblanceTrackers} = useContext(EquilyContext);
    
    const confirmDelete = () => {
    
        setDeleting(true)   
        getSession().then( ({headers}) => { 
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/rebalancetracker/'+props.trackerName,{
            method: 'DELETE',
            headers,
          })
          .then( data =>{
              setDeleting(false)
              refreshReblanceTrackers()
              props.handleClose()
              navigate("/")
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not delete tracker</Alert>)
            setDeleting(false)
          })
        })       
    }

    const close = () => {
        setError()
        props.handleClose()
    }


    return(
        <Dialog
            open={props.open}
            onClose={close}
            aria-labelledby="confirm-tracker-delete"
            aria-describedby="confirm tracker delete"
        >
        <DialogTitle id="confirm-tracker-delete">{"Confirm Delete Rebalance Tracker?"}</DialogTitle>
        <DialogContent>
          {error}
          <DialogContentText id="onfirm-tracker-delete-description">
           Are you sure you want to delete this Tracker?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
              {deleting && <CircularProgress size={14} />}
              {!deleting && "Delete"}
          </Button>
        </DialogActions>
      </Dialog>



    )


}