import {useEffect, useState, useCallback,useRef} from 'react'
import Sunburst from '../../sunburst-chart'
import { Box,Paper } from '@mui/material'
import { infographicColour } from '../../common/ui/Colours'




export default function ModelAllocationChart(props){

    const modelAllocation = props.modelAllocation

    const [modelData, setModelData] = useState(null)
    const container = useRef()
    const sunburstLibrary = useRef(null)
   
    


    const buildAllocation = useCallback((allocation) => {
      
        var node = {}
        if(allocation){
            node.name = allocation.allocationName
            node.children = []
            if(allocation.childAllocations){
                allocation.childAllocations.forEach( (child) => {
                    var childnode = buildAllocation(child)
                    node.children.push(childnode)
                })
            }
            if(node.children.length===0){
                if(allocation.modelWeighting) node.value = allocation.modelWeighting
                else node.value = allocation.weighting
            }
        }

        return node;
    },[])
    useEffect(() => {
        
        var data = {}
        if(modelAllocation){
            
            if(modelAllocation.allocation){
                data = buildAllocation(modelAllocation.allocation)
                setModelData(data)
                
            }
        }       
    }, [modelAllocation,buildAllocation]);
    
    useEffect(() => {
            if(!sunburstLibrary.current){ 
                    sunburstLibrary.current = Sunburst()(container.current).imageSize(50).excludeRoot(true).width(window.innerWidth*0.6).height(window.innerHeight*0.75)
            }
            if(modelData && modelData.children.length===0){
                // just the parent at the moment. - so show the parent as a child to prevent empty chart
                sunburstLibrary.current.excludeRoot(false)
            }else{
                sunburstLibrary.current.excludeRoot(true)
            }

            if(modelData) sunburstLibrary.current.data(modelData).color(d => infographicColour(d.name))

    },[modelData])
   
    return(
        <Paper>
            <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}} ref={container}></Box>
        </Paper>
    )

}