import React, {useState} from "react";
import { useContext } from "react";
import { EquilyContext } from "../common/Context";
import BuildMenu from "./BuildMenu";
import OptimiseMenu from "./OptimiseMenu";
import ShareMenu from "./ShareMenu";

export default function BOSMenu({backTests,addBackTest,setBackTest,benchmarks}) {

        const {investor} = useContext(EquilyContext);
        const [selected,setSelected] = useState()
        return (
             <React.Fragment>
            {investor? 
            [<BuildMenu key="buildMenuGroup" open={selected === 1} setOpen={() => setSelected(1)} setClose={()=>setSelected(0)}/>,
            <OptimiseMenu key="optimiseMenuGroup" open={selected === 2} setOpen={() => setSelected(2) } setClose={()=>setSelected(0)} backTests={backTests} addBackTest={addBackTest} setBackTest={setBackTest} benchmarks={benchmarks}/>,
            <ShareMenu key="shareMenuGroup" open={selected === 3} setOpen={() => setSelected(3)} setClose={()=>setSelected(0)}/>,]
            : <div/>}
            </React.Fragment>
        )
   

}