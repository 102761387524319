
import { Icon } from '@mui/material'
import BuildIconGreyPNG from './images/Equily BUILD icon_grey.png'
import OptimiseIconGreyPNG from './images/Equily OPTIMIZE icon_grey.png'
import ShareIconGreyPNG from './images/Equily SHARE icon_grey.png'
import BuildIconWhitePNG from './images/Equily BUILD icon_white.png'
import OptimiseIconWhitePNG from './images/Equily OPTIMIZE icon_white.png'
import ShareIconWhitePNG from './images/Equily SHARE icon_white.png'

const width = 20
const height = 20

export const EquilyBuildIconGrey = () => (
    <Icon>
        <img alt="" src={BuildIconGreyPNG} height={width} width={height}/>
    </Icon>
)
export const EquilyOptimiseIconGrey = () => (
    <Icon>
        <img alt="" src={OptimiseIconGreyPNG} height={width} width={height}/>
    </Icon>
)
export const EquilyShareIconGrey = () => (
    <Icon>
        <img alt="" src={ShareIconGreyPNG} height={width} width={height}/>
    </Icon>
)
export const EquilyBuildIconWhite = () => (
    <Icon>
        <img alt="" src={BuildIconWhitePNG} height={width} width={height}/>
    </Icon>
)
export const EquilyOptimiseIconWhite = () => (
    <Icon>
        <img alt="" src={OptimiseIconWhitePNG} height={width} width={height}/>
    </Icon>
)
export const EquilyShareIconWhite = () => (
    <Icon>
        <img alt="" src={ShareIconWhitePNG} height={width} width={height}/>
    </Icon>
)