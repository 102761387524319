import React, {useState} from "react";

import Typography from '@mui/material/Typography'
import { Grid, CircularProgress,Button, Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'



export default function BenchmarkMap(props){
    
   
    
    const node = props.benchmarkAllocation
    const investments = props.investments

    const [investment, setInvestment] = useState("")

    const getLabel = (option) =>{
        var label = ""
        if(option.stock){
            label = option.stock.symbol + " - " + option.stock.name
        }else{
            label = "Cash - " + option.currencyCode
        }
        return label
    
    }

    const add = () => {
        var stockName = investment.currencyCode
        if(investment.stock) stockName = investment.stock.symbol    
        props.addMapping(node.uri,stockName)
    }
    return(
       
            

            <Grid container spacing={1} direction="column" justifyContent="space-between">
                <Grid item>
                <Typography variant="body1" color="primary" gutterBottom>
                Map Benchmark Allocation:
                </Typography>
                </Grid>
                <Grid item>
             <Autocomplete
                    id="invest-select"
                    style={{ width: 300 }}
                    options={investments}
                    autoHighlight
                    getOptionLabel={(option) => getLabel(option)}
                    onChange={(event, newValue) => {
                        setInvestment(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Choose an investment"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        />
                    )}
             />
             </Grid>
                <Grid item>
                <Button variant="contained" onClick={add} color="primary" autoFocus>
                     {props.actionActive && <CircularProgress />}
                     {!props.actionActive && "Map"}
                </Button>
                </Grid>
            </Grid>
        
    )

}