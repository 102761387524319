import { Grid, TextField } from "@mui/material";

import { useState } from "react";

export default function Verify({handleUpdate}){

    const [codeValues,setCodeValues] = useState([])
    const [focusIndex, setFocusIndex] = useState(0)
    const updateCodeValue = (index, value) => {
        if(onlyNumberCheck(value)){
            const newvalues = [...codeValues]
            newvalues[index] = value
            setCodeValues(newvalues)
            if(newvalues.length===6) handleUpdate(newvalues.join(''))
            setFocusIndex(index+1)
        }
        
    }
    const onlyNumberCheck = (value) =>{
        const re = /[0-9]+/g;
        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }
    
    
    return(
        <Grid container direction="row" spacing={1} >
                <Grid item xs={2}>
                    <TextField inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===0) {input.focus()}} } tabindex="1" inputProps={{ maxLength: 1 }} value={codeValues[0]} onChange={ (e) => updateCodeValue(0,e.target.value)}  id="v1" variant="outlined"/>
                </Grid>
                <Grid item xs={2}>
                    <TextField inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===1) {input.focus()}} } tabindex="2" inputProps={{ maxLength: 1 }} value={codeValues[1]} onChange={ (e) => updateCodeValue(1,e.target.value)}  id="v2" variant="outlined"/>
                </Grid>
                <Grid item xs={2}>
                    <TextField inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===2) {input.focus()}} }  tabindex="3" inputProps={{ maxLength: 1 }} value={codeValues[2]} onChange={ (e) => updateCodeValue(2,e.target.value)}  id="v3" variant="outlined"/>
                </Grid>
                <Grid item xs={2}>
                    <TextField inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===3) {input.focus()}} }  tabindex="4" inputProps={{ maxLength: 1 }} value={codeValues[3]} onChange={ (e) => updateCodeValue(3,e.target.value)}  id="v4" variant="outlined"/>
                </Grid>
                <Grid item xs={2}>
                    <TextField  inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===4) {input.focus()}} } tabindex="5" inputProps={{ maxLength: 1 }} value={codeValues[4]} onChange={ (e) => updateCodeValue(4,e.target.value)}  id="v5" variant="outlined"/>
                </Grid>
                <Grid item xs={2}>
                    <TextField inputRef={(input) => {if (input!=null && codeValues.length>0 && focusIndex===5) {input.focus()}} }  tabindex="6" inputProps={{ maxLength: 1 }} value={codeValues[5]} onChange={ (e) => updateCodeValue(5,e.target.value)}  id="v6" variant="outlined"/>
                </Grid>
            </Grid>
    )


}