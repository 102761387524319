
import { Paper, Grid, Container} from '@mui/material'
import React from 'react'
export function MainPaperItem(props){


    return(
        <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
            {props.children}
      </Paper>
      </Grid>


    )


}

export function MainItem(props){


  return(
    <React.Fragment>
      <Grid item xs={12} md={12} lg={12}>
      <Container>
          {props.children}
      </Container>
    </Grid>
    </React.Fragment>


  )


}