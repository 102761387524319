export const tourOptions = {
  defaultStepOptions: {
    classes: 'onboarding-card',
    cancelIcon: {
      enabled: true
    }
  },
  useModalOverlay: true
};


export const welcomeSteps = [
  
 

{
  id: "welcome0",
  buttons: [
    {
      classes: "shepherd-button-secondary",
      text: "Exit",
      type: "cancel",
    },
    {
      classes: "shepherd-button-primary",
      text: "Next",
      type: "next",
    },
  ],
  scrollTo: false,
  title: "Welcome to Equily!",
  text: [
    "Thank you for choosing to use <b>Equily</b>.</br></br> Getting started.</br></br> Click on the <b>Show me how</b> button at anytime to find all the support you wll need for using equily.",
  ],
},
  {
    id: "welcome1",
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    scrollTo: false,
    title: "Welcome to Equily!",
    text: [
      "We recommend you browse the <b>Portfolio Store</b> to get an idea of pre built portfolios and key financial metrics.</br> </br>Here you will find a collection of ‘smart portfolios’, built by the equily community that can be browsed to find portfolios that fit your risk appetite.",
    ],
  },
  {
    id: "welcome2",
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    scrollTo: false,
    title: "Welcome to Equily!",
    text: [
      "<p>Notice the risk adjusted return.</p><p>Trying filtering & sorting the Portfolio Store by different metrics. </p><p> Once you see a portfolio you like, click on the Detail button. </p><p>Simulate your potential returns in a simple calculator. Then hit the Subscribe button and give it a name and amount. This will create your first Smart Portfolio.</p> Congratulations!",
    ],
  },
];
