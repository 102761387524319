import React, {useContext,useState} from "react";
import { useNavigate  } from "react-router-dom";

import {Grid, Box,Fab,Button} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import Alert from '@mui/material/Alert'

import {DialogContentText,DialogTitle,DialogContent,DialogActions,Dialog,CircularProgress} from '@mui/material'

import { CallAPI } from '../common/services/api/CallAPI'
import {EquilyContext} from '../common/Context'
import {InvestorAccountContext} from '../auth/InvestorAccounts'

import Title from '../common/ui/Title'

import ModelAllocationChart from './chart/ModelAllocationChart'
import ModelAllocationEdit from "./ModelAllocationEdit";
import { CopyModelAllocation } from "./CopyModelAllocation";




export default function ModelAllocation(props){


    const {modelAllocation,selectModelAllocation,deleteModelAllocation,refreshBenchmarks} = useContext(EquilyContext);
    const {getSession} = useContext(InvestorAccountContext)
 
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [copyOpen, setCopyOpen] = useState(false)
    const [error,setError] = useState()

    const navigate = useNavigate ();


    const confirmDeleteModelAllocation = () => {
      setDeleting(true)
       
      getSession().then( ({headers}) => {
          CallAPI('/engine/models/'+modelAllocation.modelAllocationName,{
          method: 'DELETE',
          headers,
        })
        .then( data =>{
            setDeleting(false)
            setDeleteOpen(false)
            deleteModelAllocation(modelAllocation)
            navigate("/");
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setError(<Alert severity="error">Could not delete allocation mapping</Alert>)
          setDeleting(false)
        })
      })

    }

    const validate = () =>{

      getSession().then( ({headers}) => { 
          CallAPI('/engine/models/'+modelAllocation.modelAllocationName+"/validate",{
              method: 'GET', headers
          })
          .then( data =>{
            selectModelAllocation(data)
            refreshBenchmarks()
          })
          .catch((error) => {
              console.log("There was an error " + error)
              setError(<Alert severity="error">Could not validate Allocation</Alert>)
          })
       })
  }
   
    return(

        <React.Fragment>
          <Box sx={{display: 'flex', flexGrow: '1', 'flexDirection': 'column', 'alignItems': 'left'}}>

          <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
          <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
               <Title>
                {modelAllocation.modelAllocationName} : {modelAllocation.modelAllocationDescription}
                </Title>
                <Title>Status: {modelAllocation.modelAllocationStatus}  </Title>
                
                <Title>
                        Valid: {modelAllocation.validModel.toString()}
                </Title>
          </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
            <Grid item>
              <Tooltip title="Validate " aria-label="Validate">
                  <Fab color="primary" variant="extended" onClick={() => validate()}>
                              Validate
                  </Fab>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Copy Allocation" aria-label="Copy">
                <Fab  color="primary" aria-label="copy" zindex="tooltip" onClick={() => setCopyOpen(true)}>
                  <FileCopyIcon />
                </Fab>
              </Tooltip>
            </Grid>


            <Grid item>
                <Tooltip title="Delete Allocation" aria-label="Delete">
                <Fab color="secondary" aria-label="delete" zindex="tooltip" onClick={() => setDeleteOpen(true)}>
                      <DeleteForeverIcon />
                </Fab>
                </Tooltip>
            </Grid>


            </Grid>
          </Grid>
            
            
            
            
               
          </Grid>

            
          
            
          <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem'}}>
          <ModelAllocationEdit setModelAllocation={selectModelAllocation} modelAllocation={modelAllocation}></ModelAllocationEdit>
          </Box> 

          <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
          <ModelAllocationChart modelAllocation={modelAllocation}></ModelAllocationChart>
          </Box> 
        </Box>
        
        <CopyModelAllocation modelAllocationName={modelAllocation.modelAllocationName} open={copyOpen} handleClose={() => setCopyOpen(false)}></CopyModelAllocation>
        <Dialog
        open={deleteOpen}
        onClose={confirmDeleteModelAllocation}
        aria-labelledby="confirm-modelallocation-delete"
        aria-describedby="confirm model allocation delete"
      >
        <DialogTitle id="confirm-modelallocation-delete">{"Confirm Delete Allocation?"}</DialogTitle>
        <DialogContent>
          {error}
          <DialogContentText id="onfirm-backtest-delete-description">
           Are you sure you want to delete this Allocation? Any Benchmarks and Backtests associated with the model will also be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteModelAllocation} color="primary" autoFocus>
              {deleting && <CircularProgress size={14} />}
              {!deleting && "Delete"}
          </Button>
        </DialogActions>
      </Dialog>


    </React.Fragment>


    )

}