
import React, {} from 'react'

import {Typography} from '@mui/material'

export default function SummaryViewCardVolatility(props){

    const mp = props.modelPortfolio
   
    return(
        
        <React.Fragment>
        <Typography variant="overline">Volatility</Typography>
        <Typography  variant="subtitle2">
            {mp.volatility.toFixed(2)}%
          </Typography>
        </React.Fragment>

    )

}