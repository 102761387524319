import React, { } from 'react'

import { Grid, Paper, Typography } from '@mui/material'
import ModelPortfolioAllocationsChart from './ModelPortfolioAllocationsChart'
import ModelPortfolioAllocationsTable from './ModelPortfolioAllocationsTable'


export default function ModelPortfolioAllocations(props){


    const modelPortfolio = props.modelPortfolio

    return(
                <React.Fragment>
                    <Paper>
                        <Grid container direction="column" alignItems="center" justifyItems="center" sx={{"paddingLeft":2, paddingBottom:2}} spacing={2}>
                            <Grid item>
                            <Typography paragraph>
                             {modelPortfolio.description}
                        </Typography>
                                <Typography paragraph>
                                This shows where your investment pot would be allocated. You can click on the graph. Equily will tell you exactly what to buy to get this portfolio when you subscribe.​
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ModelPortfolioAllocationsChart modelPortfolio={modelPortfolio}/>
                            </Grid>
                            <Grid item>
                                <ModelPortfolioAllocationsTable modelPortfolio={modelPortfolio}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </React.Fragment>
    )
        
}