import { ListItem, ListItemIcon } from "@mui/material";
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import { useNavigate  } from "react-router-dom";
import { EquilyListItemText } from "../../common/Link";

export function BackTestMenuItem({backTest, setBackTest}){

  let navigate = useNavigate ();

  const selectBackTest = () => {
    setBackTest(backTest)
    navigate("/backtest/detail");
  }

  
    
    return(
      <ListItem key={backTest.backTestName} button sx={{ pl: 4 }} onClick={selectBackTest}>
        <ListItemIcon key={backTest.backTestName+"BenchIcon"}>
        <MultilineChartIcon />
        </ListItemIcon>
        <EquilyListItemText key={backTest.backTestName+"BenchText"} primary={backTest.backTestName} />
      </ListItem>
    )

}