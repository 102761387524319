
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material';


export const DecisionButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.decisionButton,
    fontWeight: 700,
    fontFamily: [
    'Source Sans Pro'
    ].join(','),
    '&:hover': {
      backgroundColor: theme.palette.primary.decisionButtonHover,
      boxShadow: 'none',
    },
  }));

  export const DiscoverButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.discoverButton,
    boxShadow: 'none',
    color: 'white',
    borderRadius: '15px',
    fontFamily: [
    'Source Sans Pro'
    ].join(','),
    '&:hover': {
      backgroundColor: theme.palette.primary.discoverButtonHover,
      boxShadow: 'none',
    },
  }));

  export const DiscoverButtonSelected = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.discoverButtonHover,
    boxShadow: 'none',
    pointerEvents: 'none',
    color: 'white',
    borderRadius: '15px',
    fontFamily: [
    'Source Sans Pro'
    ].join(','),
  }));


  export const ProgressButton = ({onClick, progressing, text}) =>{
    return(
          <Button onClick={onClick} color="primary" autoFocus>
                     {progressing && <CircularProgress size={14} />}
                     {!progressing && text}
          </Button>
    )
  }

  