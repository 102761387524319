import React, {useState,useEffect} from "react";

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import { Stack, Grid, CircularProgress,Button, FormControl } from '@mui/material'
import TextField from '@mui/material/TextField'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


import FormColumn from '../common/ui/FormColumn'



export default function ModelAllocationEditPanel(props){
    
  
    
    const allocation = props.allocation
    const isParent = props.isParent

    const [childName, setChildName] = useState("")
    const [childWeighting, setChildWeighting] = useState("")
    const [showEdit, setShowEdit] = useState(false)
    const [allocName, setAllocName] = useState("")
    const [allocWeighting, setAllocWeighting] = useState(0)


    const resetForm = () =>{
        setChildName("")
        setChildWeighting("")
    }

    useEffect( () => {
        if(allocation){
            setShowEdit(false)
            setAllocName(allocation.allocationName)
            setAllocWeighting(allocation.weighting)
            setChildName("")
            setChildWeighting("")
        }
    },[allocation])

    

    const add = () => {
        props.addAllocation(childName,allocation.uri,childWeighting)
        resetForm()
    }
    const remove = () => {
       props.removeAllocation(allocation.allocationName, allocation.uri)
       resetForm()
    }
   
    const editAllocation = () => {
        if(showEdit){
            return (

                <Stack sx={{ mb: 1 }}>       
               <FormColumn>
                <FormControl variant="outlined" >
                            <TextField autoFocus value={allocName} onChange={e => setAllocName(e.target.value)} id="childName" label="Allocation Name" type="text"  />
                </FormControl>
                {isParent ? <div></div> :
                <FormControl variant="outlined" >
                            <TextField  value={allocWeighting} onChange={e => setAllocWeighting(e.target.value)} id="backTestName" label="Weighting" type="number"   />
                </FormControl>
                }
                 <Button variant="contained" onClick={() => props.updateAllocation(allocName,allocation.uri,allocWeighting)} color="primary" autoFocus>
                    {props.actionActive && <CircularProgress size={14} />}
                    {!props.actionActive && "Update"}
                </Button>     
                </FormColumn>
                
                      
                </Stack>)
        }else{
            return <div></div>
        }
    }


    return(
        <React.Fragment>
            
            <Stack>
            
            <Grid container  direction="row" justifyContent="space-between">
                    <Grid item>
                    <Typography variant="h6" color="primary" gutterBottom>
                    {allocation.allocationName}
                    </Typography>
                    </Grid>   
                    <Grid item>
                        <IconButton variant="contained" color="primary" aria-label="delete" component="span" onClick={() => setShowEdit(true)}>
                            <EditIcon />
                        </IconButton>
                        {isParent ?  <div></div> :
                        <IconButton variant="contained" color="primary" aria-label="delete" component="span" onClick={remove}>
                            <DeleteForeverIcon />
                        </IconButton>}
                    </Grid>
            </Grid>


            <Grid container direction="row" justifyContent="space-between">
               
                <Typography variant="body1" color="primary" gutterBottom>
                Weighting: {allocation.weighting} %
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                Model Weighting: {allocation.modelWeighting? allocation.modelWeighting.toFixed(2) : ""} %
                </Typography>
               
            </Grid>

            {editAllocation()}
            
            
             <Divider variant="middle"></Divider>
            
            <Stack sx={{ mt: 1 }}>
                <Typography variant="body1" color="primary" gutterBottom>
                Add Child Allocation:
                </Typography>
                <FormColumn>
                <FormControl variant="outlined">
                            <TextField autoFocus value={childName} onChange={e => setChildName(e.target.value)} id="childName" label="Name" type="text" variant="outlined"  />
                </FormControl>
                <FormControl variant="outlined">
                            <TextField  value={childWeighting} onChange={e => setChildWeighting(e.target.value)} id="backTestName" label="Weighting" type="number" variant="outlined"  />
                </FormControl>
                
               
                <Button variant="contained" onClick={add} color="primary" autoFocus>
                     {props.actionActive && <CircularProgress size={14} />}
                     {!props.actionActive && "Add"}
                </Button>
                </FormColumn>
             </Stack>
                 
            </Stack>
        </React.Fragment>
    )

}