import React, {useState, useCallback, useEffect, useContext} from "react";

import Alert from '@mui/material/Alert';
import { Paper, Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import { CallAPI } from '../common/services/api/CallAPI'

import BenchmarkTree from "./tree/BenchmarkTree";
import BenchmarkEditPanel from "./BenchmarkEditPanel";
import {InvestorAccountContext} from '../auth/InvestorAccounts'


export default function BenchmarkEdit(props){
    
   
    
    const benchmarkData = props.benchmarkData
    const setBenchmark = props.setBenchmark
    const portfolio = props.portfolio
    const benchmarkName = props.benchmarkName

   
    const {getSession} = useContext(InvestorAccountContext)

    const initial = {name: "",cash: [], investments: []}
    const [error, setError] = useState("")
    const [selectedBenchmarkAllocation, setSelectedBenchmarkAllocation] = useState(initial)
    const [addingMapping, setAddingMapping] = useState(false)
    const [investments, setInvestments] = useState([])
    const [URI, setURI] = useState()
    const buildInvestments = useCallback((benchmarkData, invs) => {
        
        var list = [...invs]        
        if(benchmarkData.investments){
            if(benchmarkData.investments.length>0){
                list = list.filter( i => {
                    return !i.stock || !benchmarkData.investments.includes(i.stock.symbol)
                })
               
            }
        }
        if(benchmarkData.cash){
            if(benchmarkData.cash.length>0){
                list = list.filter(c => (!c.currencyCode || !benchmarkData.cash.includes(c.currencyCode)))
            }
        }
        if(benchmarkData.children){
            if(benchmarkData.children.length>0){
                benchmarkData.children.forEach(child => {
                    list = buildInvestments(child,list)
                })  
            }
        }
      
        return list
    },[])


 

    useEffect( () => {
        setURI(null)
    },[benchmarkName])

    const findBenchmarkAllocation = useCallback((node, allocationURI) => {
        var match
        if(checkAllocationMatch(node, allocationURI)){
            match = node
        } 
        else{  
            if(node.children){
                for( var i = 0; i < node.children.length; i++ ){
                    const child = node.children[i];   
                    if( checkAllocationMatch(child,allocationURI)){
                         match = child
                    }else{  
                        match = findBenchmarkAllocation(child, allocationURI)
                    }
                    if(match) break
                }
            }
        }
        return match
    },[])
    
    const checkAllocationMatch  = (node, allocationURI) => {
        if(node.uri===allocationURI) return true
        return false
    }

    const selectAllocationForURI = useCallback((allocationuri) => {
        var alloc = findBenchmarkAllocation(benchmarkData, allocationuri)
        setSelectedBenchmarkAllocation(alloc)
    },[benchmarkData,findBenchmarkAllocation])

    useEffect(() => {
        if(benchmarkData && portfolio){
            var invs = []
           // if(portfolio.investments) invs = portfolio.investments.map(i => i.stock.symbol)
            if(portfolio.investments) invs = portfolio.investments
            var cash = []
            //if(portfolio.cash) cash = portfolio.cash.map( c => c.currencyCode)
            if(portfolio.cash) cash = portfolio.cash
            invs = [...invs,...cash]
            //invs = [...portfolio.investments,...portfolio.cash]
            invs = buildInvestments(benchmarkData,invs)
            setInvestments(invs)
            if(URI){
                selectAllocationForURI(URI)
            }else setSelectedBenchmarkAllocation(benchmarkData)  
        }
    },[benchmarkData,buildInvestments,portfolio,URI,selectAllocationForURI])
  

  
   
    

    const addMapping = useCallback((uri, stock) => {

        setAddingMapping(true)
        var req = [{
            "stockName": stock,
            "allocationuri": uri,
        }]
        getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/benchmark/'+benchmarkName,{
                method: 'POST',
                headers,
                body: JSON.stringify(req)
            })
            .then( data =>{
            setAddingMapping(false)
            setBenchmark(data)
            })
            .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not map allocation</Alert>)
            setAddingMapping(false)
            })
        })

    },[benchmarkName, setBenchmark,getSession])

    const deleteMapping = ((uri, stock) => {

        setAddingMapping(true)
        var req = [{
            "stockName": stock,
            "allocationuri": uri,
        }]
        getSession().then( ({headers}) => {
        headers['Content-Type'] =  'application/json'
        CallAPI('/engine/benchmark/'+benchmarkName,{
          method: 'DELETE',
          headers,
          body: JSON.stringify(req)
        })
        .then( data =>{

          setAddingMapping(false)
          setBenchmark(data)
          setURI(uri)
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setError(<Alert severity="error">Could not delete allocation mapping</Alert>)
          setAddingMapping(false)
        })
    })


    })
   
    return(
        <React.Fragment>
       
           <Grid container spacing={2} direction="row" justifyContent="space-between"  sx={{'paddingBottom': '1rem','paddingTop': '1rem'}}>
             
                <Grid item xs={6}>
                    <Paper sx={{padding:1}}>
                    <Stack>
                        <Grid container direction="row" justifyContent="space-between">
                            <Typography  variant="h6" color="primary" gutterBottom>
                            Mapping Status: 
                            </Typography> 
                            <Typography  variant="h6" color="primary" gutterBottom>          
                                {(benchmarkData &&benchmarkData.fullyMapped) ? "Mapped" : "Incomplete "}
                            </Typography>
                        </Grid>
                                  
                        <BenchmarkTree selectAllocation={selectAllocationForURI} benchmarkData={benchmarkData}></BenchmarkTree>
                        
                    </Stack>
                    </Paper>
                </Grid>
           
                <Grid item xs={6}>
                <Paper sx={{padding:1}}>
                {error}
                   <BenchmarkEditPanel actionActive={addingMapping} deleteMapping={deleteMapping} addMapping={addMapping} investments={investments} benchmarkAllocation={selectedBenchmarkAllocation}></BenchmarkEditPanel>
                </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}