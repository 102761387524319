import React from 'react'


import NVD3Chart from 'react-nvd3'
import { renderCurrency } from "../../common/tables/Table";


export default function ModelPortfolioOverviewChart(props){

   
    const currencyCode = props.currencyCode
    const portfolioSize = props.portfolioSize
    const finishValue = props.finishValue
    const historyData = props.historyData
    
  
    return(
                <React.Fragment>
                               <NVD3Chart sx={{p:'1rem'}} id="linePortfolio" type="lineChart" datum={historyData} x="x" y="y"
                         xAxis={{
                            tickFormat: (d) => new Date(d).toLocaleDateString(),
                          }}
                          yAxis={{
                            tickValues: [portfolioSize,finishValue],
                            tickFormat: (d) => renderCurrency(d,currencyCode,0),
                          }}
                          margin={{ right: 50, left: 90}}
                                    height="500"
                                    />
                </React.Fragment>
    )
        
}