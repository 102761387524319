import React, {useCallback} from "react";

import Typography from '@mui/material/Typography'
import { CircularProgress,Button, Grid } from '@mui/material'



export default function BenchmarkDeleteMap(props){
    
    
    const node = props.benchmarkAllocation
    const deleteMapping = props.deleteMapping

    const getRowItem = useCallback((inv) => {
        return (
            <div key={inv} >
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Typography  variant="body1" color="primary">
                        {inv} 
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"  color="primary" autoFocus onClick={() => deleteMapping(node.uri,inv)}>
                            {props.actionActive && <CircularProgress/>}
                            {!props.actionActive && "Delete"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    },[props.actionActive,deleteMapping,node.uri])

    const deleteRows = useCallback( () => {
       var rows = []
       if(node.investments && node.investments.length>0) rows = node.investments.map( inv => getRowItem(inv))
       if(node.cash && node.cash.length>0)rows = node.cash.map( inv => getRowItem(inv))
       return rows

    },[node.investments,node.cash,getRowItem])
    
   



    return(
        <div>
            

             <div>
                <Typography variant="body1" color="primary" gutterBottom>
                Delete Mapping:
                </Typography>
            </div>
            {deleteRows()}
    
        </div>
    )

}