import { Button, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { InverseSignInBox } from "./SignInBox";
import { FBButton, FBIcon, GButton, GoogleIcon } from "./SocialButtons";

import { Box } from "@mui/system";


export default function RegisterContent({investorID, setInvestorID, password, setPassword, handleSignInClick,handleRegister, facebookSignIn,googleSignIn}){

   
   
    return(
        <InverseSignInBox>
                    <Grid sx={{mt:'3px',mb: '10px'}} container wrap="nowrap" spacing={2} justifyContent="space-between" alignItems="center">
                            <Grid item>
                            <Typography variant="h6">Register</Typography>
                            </Grid>
                            <Grid item>
                            <Button variant="outlined" color="primary">
                                <Typography variant="button" onClick={handleSignInClick}>Sign In</Typography>
                            </Button>
                            </Grid>
                    </Grid>
                    <Grid sx={{mb:'15px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
                        <Grid item sx={{width: '100%'}} >
                            <Typography align="center" variant="body1">Create your account and start using Equily for <b>FREE</b></Typography>
                        </Grid>
                    </Grid>
                    <Grid direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
                        <Grid item sx={{width: '100%'}} >
                            <FBButton onClick={()=>facebookSignIn()} fullWidth startIcon={<FBIcon width="26px" height="26px" color="white"/>}>Register with Facebook</FBButton>
                        </Grid>
                        <Grid item sx={{width: '100%'}}>
                            <GButton onClick={()=>googleSignIn()} variant="outlined" fullWidth startIcon={<GoogleIcon width="26px" height="26px" color="white"/>}>Register with Google</GButton>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt:'10px'}}><Typography variant="body2">or continue with</Typography></Divider>
                    <Grid sx={{mb:'15px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
                       
                        <Grid item sx={{width: '100%'}}>
                         <TextField  variant="standard" fullWidth value={investorID} onChange={e => setInvestorID(e.target.value)} id="reginvestorid" label="Email" type="text"  />  
                        </Grid>
                    </Grid>
                   
                  
                    <Grid sx={{mb:'25px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>
                            <Button variant="contained" onClick={handleRegister} color="primary">
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                    <Typography  variant="caption">
                        By clicking Register or Continue with Google or Facebook , you agree to Equily's <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://equily.io/terms-conditions">Terms of Use</Link> and <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://equily.io/privacy-policy">Privacy Policy</Link>. Equily may send you communications from time to time.
                      </Typography>
                      </Grid>
                    </Grid>

                    <Grid container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
                    <Grid item>
                    <Box
                      component="img"
                      sx={{
                        mt: 1,
                        height: 37,
                        width: 143.5,
                      }}
                      alt="Equily"
                      src="/Equily_Logo_Colour.png"
                    />
                      </Grid>
                    </Grid>  
                    </InverseSignInBox>

    )
}