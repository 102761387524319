import React, { useState, useContext, useEffect} from "react"
import {  Grid} from '@mui/material'
import { useNavigate  } from "react-router-dom";
import {InvestorAccountContext} from '../InvestorAccounts'
import ReactCardFlip from "react-card-flip";
import RegisterContent from "./RegisterContent";
import RegisterandVerify from "./RegisterandVerify";
import SignInCard from "./SignInCard";
import BannerAlert from "../../common/ui/BannerAlert";


export function SignIn(props) {
    
   
    const {authenticateInvestor,googleAuth,facebookAuth,user} = useContext(InvestorAccountContext)
  
    const [investorID, setInvestorID] = useState("")
    const [password, setPassword] = useState("")
    const [isRegistering,setIsRegistering] = useState(false)
    const [error, setError] = useState()
    const [isFlipped, setIsFlipped] = useState(false)
    const navigate = useNavigate ();
   
    useEffect(() => {
    if(user.username){
        navigate("/modelportfoliostore")   
    }
    }, [user,navigate])


    const login = () =>{
        setError()
        authenticateInvestor(investorID, password)
        .then( data => {
     //       navigate("/modelportfoliostore")   
        }).catch(err => {
            if(err.code === 'UserNotConfirmedException'){
                navigate("/auth/verify") 
            }else{
                setError(<BannerAlert severity="error" timed>{err.message}</BannerAlert>)
            }
        })
    }


    const handleRegister = (e) => {
        e.preventDefault()
        setIsRegistering(true)
    }

   

    const handleFlip = (e) => {
        e.preventDefault()
        setIsFlipped(!isFlipped)
        
    }


       return(
            
            <Grid container direction="column"  justifyContent="center" alignItems="center">
                {error}
                <Grid item>
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedBackToFront="2" flipSpeedFrontToBack="2">
                    <SignInCard handleStatus={setError} investorID={investorID} setInvestorID={setInvestorID} password={password} setPassword={setPassword} handleRegisterClick={handleFlip}  handleLogin={login} facebookSignIn={facebookAuth} googleSignIn={googleAuth}/>
                 {isRegistering? <RegisterandVerify  handleError={setError}  setPassword={setPassword} login={login} investorID={investorID} handleSignInClick={handleFlip}/>:
                  <RegisterContent isRegistering investorID={investorID} setInvestorID={setInvestorID} handleSignInClick={handleFlip} handleRegister={handleRegister} facebookSignIn={facebookAuth} googleSignIn={googleAuth}/>
                 }
                  </ReactCardFlip>
            </Grid>
          </Grid>
        )
    




}




   
                    

/*
 <FormControl sx={{width: '100%'}}>
                            <InputLabel sx={{fontSize: 15,height: 10}}>Password</InputLabel>
                            <Input margin="dense"  fullWidth size="small" id="passwordinput" onChange={e => setPassword(e.target.value)} type="password"/>
                            </FormControl>
 <Grid item sx={{width: '100%'}}>
                            <TextField  size="small" sx={{backgroundColor: 'white'}} fullWidth autoFocus value={investorID} onChange={e => setInvestorID(e.target.value)} id="email" type="text">
                            </TextField>
                            
                        </Grid>


 <React.Fragment>   
                    {error}
                <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Title>
                            Login to Equily
                        </Title>
                    </Grid> 
                    <Grid item xs>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={6}>
                                <GoogleLoginButton onClick={() => googleSignIn()}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FacebookLoginButton onClick={() => facebookLogin()}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Divider flexItem={true} style={{width:'100%'}}>
                            <Chip label="Or" />
                    </Divider>
                    <FormColumn>
                                <TextField fullWidth autoFocus value={investorID} onChange={e => setInvestorID(e.target.value)} id="investorid" label="Investor ID" type="text" />
                                <TextField fullWidth value={password} onChange={e => setPassword(e.target.value)} id="password" label="Password" type="password"  />
                                <Button variant="contained" onClick={login} color="primary">
                                {authenticating && <CircularProgress size={14} />}
                                {!authenticating && "Sign In"}
                                </Button> 

                    </FormColumn>        
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Typography component="h6" variant="h6" color="primary" gutterBottom>
                                 Don't have an account? <Link href="#" onClick={e => signup(e)}>Register</Link> 
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="h6" variant="h6" color="primary" gutterBottom>
                                    <Link href="#" onClick={e => forgotten(e)}>Forgotten Password?</Link> 
                                </Typography>
                            </Grid>
                        </Grid>
                   
                    
               </React.Fragment>

*/
