import React, {useState,useContext,useEffect} from "react"

import Alert from '@mui/material/Alert';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import FormColumn from "../common/ui/FormColumn";
import { EquilyContext } from "../common/Context";


export function EditRebalanceTracker(props) {

    

    const {rebalanceTracker} = useContext(EquilyContext);

    const {getSession} = useContext(InvestorAccountContext)
    const [error,setError] = useState()
    const [saving, setIsSaving] = useState(false)
  
    const [benchmarkMenuItems, setBenchmarkMenuItems] = useState([])
    const [benchmarkName, setBenchmarkName] = useState("")
    const [trackerName, setTrackerName] = useState()
    const [thresholdType, setThresholdType] = useState("")
    const [thresholdLevel, setThresholdLevel] = useState()
    const [maxPercentageCostOfTrade, setMaxPercentageCostOfTrade] = useState()
    const [costPerTrade, setCostPerTrade] = useState()
    const { benchmarks,setRebalanceTracker,refreshReblanceTrackers } = useContext(EquilyContext)

    useEffect(() => {
      if (benchmarks) {
          const menuItems = benchmarks.map((bm,index) =>
              <MenuItem key={index} value={bm.benchmarkName}>{bm.benchmarkName}</MenuItem>
          )
          setBenchmarkMenuItems(menuItems)
      }
      if(rebalanceTracker){
        setBenchmarkName(rebalanceTracker.benchmarkName)
        setTrackerName(rebalanceTracker.trackerName)
        setThresholdLevel(rebalanceTracker.threshold.value)
        setThresholdType(rebalanceTracker.threshold.thresholdType)
        setMaxPercentageCostOfTrade(rebalanceTracker.costEfficiency.maxPercentageCostOfTrade)
        setCostPerTrade(rebalanceTracker.costPerTrade)
      }
    }, [benchmarks,rebalanceTracker]);




    const updateRebalanceTracker = () => {
      setIsSaving(true)
      var trackerReq = {
        trackerName: trackerName,
        benchmarkName: benchmarkName,
        maxPercentageCostOfTrade: maxPercentageCostOfTrade,
        thresholdLevel: thresholdLevel,
        thresholdType: thresholdType,
        costPerTrade: costPerTrade,
      }
      
      getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          setIsSaving(true)
          CallAPI('/engine/rebalancetracker/'+rebalanceTracker.trackerName,{
            method: 'POST',
            headers,
            body: JSON.stringify(trackerReq)
          })
          .then( data =>{
            setRebalanceTracker(data)
            setIsSaving(false)
            refreshReblanceTrackers()
            props.handleClose()
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setIsSaving(false)
            setError(<Alert severity="error">Could not update rebalance tracker</Alert>)
          });
        })
    }


    const cancel = () => {
      props.handleClose()
    }
   
    return(

      <Dialog fullWidth={true} open={props.open} onClose={props.handleClose} aria-labelledby="Edit Rebalance Tracker">
      <DialogTitle id="add-tracker-title">Edit Rebalance Tracker</DialogTitle>
      {error}
      <DialogContent>
          <FormColumn>
              <FormControl variant="outlined" fullWidth  >
                  <TextField autoFocus value={trackerName} onChange={e => setTrackerName(e.target.value)} id="trackerName" label="Rebalance Tracker Name" type="text" />
              </FormControl>
              <FormControl variant="outlined" fullWidth  >
                  <InputLabel id="benchmark-label">Benchmark</InputLabel>
                  <Select
                      labelId="benchmark-label"
                      id="benhcmark--select"
                      value={benchmarkName}
                      onChange={e => setBenchmarkName(e.target.value)}
                      variant="outlined"
                      label="Benchmark"
                  >
                      {benchmarkMenuItems}
                  </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                  <InputLabel id="threshold-type-label">Threshold Type</InputLabel>
                  <Select
                      labelId="threshold-type-label"
                      id="threshold-type-select"
                      value={thresholdType}
                      onChange={e => setThresholdType(e.target.value)}
                      variant="outlined"
                      label="Threshold Type"
                  >
                      <MenuItem value="AbsolutePercentage">Absolute Percentage</MenuItem>
                      <MenuItem value="RelativePercentage">Relative Percentage</MenuItem>
                      <MenuItem value="Value">Value</MenuItem>
                      <MenuItem value="Time">Time</MenuItem>
                  </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth >
                  <TextField  value={thresholdLevel} onChange={e => setThresholdLevel(e.target.value)} id="price" label="Threshold Level" type="number"/>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                  <TextField  value={maxPercentageCostOfTrade} onChange={e => setMaxPercentageCostOfTrade(e.target.value)} id="price" label="Max Percentage Cost of Trade Level" type="number"/>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                  <TextField  value={costPerTrade} onChange={e => setCostPerTrade(e.target.value)} id="CostPerTrade" label="Cost Per Trade" type="number"/>
              </FormControl>
          </FormColumn>
      </DialogContent>

      <DialogActions>
          <Button autoFocus onClick={cancel} color="primary">
              Cancel
          </Button>
          <Button onClick={updateRebalanceTracker} color="primary" autoFocus>
               {saving && <CircularProgress size={14} />}
               {!saving && "Save"}
          </Button>
      </DialogActions>
     
  </Dialog>

    )


}