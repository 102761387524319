import { SignIn } from "./SignIn";
import SingleLayerContainer from "../../common/ui/SingleLayerContainer";



export function SingleLayerSignIn(props) {
    

       return(
        
            <SingleLayerContainer>
                <SignIn/>
            </SingleLayerContainer>
      
        )
    




}




   
                    

/*
   <Box  display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ 
            backgroundColor: "pantone.pantone7546",
            height: '100vh',
            width: '100%',
            margin: '-8px'
        }}>

*/
