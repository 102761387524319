import React, {useContext,useState, useEffect} from "react";


import {CircularProgress, List,ListSubheader} from '@mui/material'

import {EquilyContext} from '../common/Context'
import { PortfolioStoreMenu } from "../store/menu/PortfolioStoreMenu";
import {MyPortfoliosMenu} from "../subscriber/menu/MyPortfoliosMenu"


export default function InvestorMenu(props) {


    const {investor} = useContext(EquilyContext);
    const loader = <ListSubheader inset><CircularProgress /></ListSubheader>
    const [items, setItems] = useState(loader);

    useEffect(() => {
        if(investor){
                setItems( <React.Fragment>
                            <PortfolioStoreMenu/>
                            <MyPortfoliosMenu/>
                            </React.Fragment>)
        }
    },[investor])

    if(investor){
        return(
            <React.Fragment>
            <List key={investor.investorName}>
                <ListSubheader inset>{investor.firstName}&nbsp;{investor.lastName}</ListSubheader>
                {items}
            </List>
            </React.Fragment>
        )
    }

    return(
        <div/>
    )

}