import React from "react"
import {DialogContentText,DialogTitle,DialogContent,DialogActions,Dialog} from '@mui/material'
import { Button,CircularProgress } from '@mui/material'


export function DeletePortfolio(props) {
        
    return(

        <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="confirm-backtest-delete"
        aria-describedby="confirm backtest delete"
      >
        <DialogTitle id="confirm-backtest-delete">{"Confirm Portfolio Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="onfirm-backtest-delete-description">
           Are you sure you want to delete this Portfolio? Doing so will remove any associated benchmarks or backtests.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleDelete} color="primary" autoFocus>
                     {props.loading && <CircularProgress size={14} />}
                     {!props.loading && "Delete"}
          </Button>
        </DialogActions>
      </Dialog>




    )


}