import React, {useState,useEffect} from "react";

import { Paper } from "@mui/material";

import { nonSelectTableOptions,renderNumber, renderCurrencyNumber,renderDateTime} from "../common/tables/Table";
import MUIDataTable from "mui-datatables";



export default function RebalanceTrackerHistoricResults(props) {

    const historicResults = props.historicResults

    const [data,setData] = useState([])

    const columns = [
      { name: 'runDate', label: 'Run Date',options: {customBodyRenderLite: (dataIndex) => { return renderDateTime(data,dataIndex,'runDate') }}},
      { name: 'actionType', label: 'Action Type'},
      { name: 'actionType', label: 'Action Type'},
      { name: 'symbol', label: 'Stock' },
      { name: 'tradingVol', label: 'Trade Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'tradingVol') }}},
      { name: 'price', label: 'Price',options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'price',data[dataIndex].currencyCode) }}},
      { name: 'value', label: 'Trade Val.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'value') }}},
      { name: 'prePercentage', label: 'Pre %.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'prePercentage') }}},
      { name: 'postPercentage', label: 'Post %',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postPercentage') }}},
      { name: 'preValue', label: 'Pre Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preValue') }}},
      { name: 'postValue', label: 'Post Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postValue') }}},
      { name: 'preVol', label: 'Pre Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preVol') }} },
      { name: 'postVol', label: 'Post Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postVol') }}},
    
    ];
    
    useEffect(() => {
   
      if (historicResults) {
        var hdata = []
        historicResults.forEach( result => {
         
          if(result && result.actions){
            const calcs = result.actions.map((row) => {
              var newrow = {
                runDate: result.runDate,
                actionType: row.actionType,
                symbol: row.stock.symbol,
                tradingVol: row.postVolume - row.preVolume,
                price: row.stock.price,
                value: row.stock.price * (row.postVolume - row.preVolume),
                prePercentage: row.prePercentage,
                postPercentage:row.postPercentage,
                preValue: row.preValue,
                postValue: row.postValue,
                preVol: row.preVolume,
                postVol: row.postVolume,
                currencyCode: row.stock.currencyCode,
              }
              return newrow
            })
            hdata = hdata.concat(calcs)
          }
        })
        setData(hdata)
      }
    }, [historicResults])
   
    if(historicResults){
    return(
        <Paper>
          <MUIDataTable
            data={data}
            columns={columns}
            options={nonSelectTableOptions}
          />
       </Paper>
    )
    }else{
        return(<div></div>)
    }

}