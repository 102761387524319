import React from 'react';
import { TableBody,TableBodyCell, TableBodyRow } from 'mui-datatables';
import {
  CircularProgress,
  Grid,
  TableBody as MuiTableBody
} from '@mui/material';



const LoadingTableBody = ({ loading, options, columns, ...others }) => {
  // @ts-ignore
  const visibleColCnt = columns.filter(c => c.display === 'true').length;
  
  return loading ? (
    <MuiTableBody>
      <TableBodyRow options={options}>
        <TableBodyCell
          // @ts-ignore
          colSpan={
            options.selectableRows !== 'none' || options.expandableRows
              ? visibleColCnt + 1
              : visibleColCnt
          }
          options={options}
          colIndex={0}
          rowIndex={0}
        >
        
           <Grid container justifyContent="center" alignItems="center" >
            <Grid item >
              <CircularProgress />
            </Grid>
          </Grid>
          
        </TableBodyCell>
      </TableBodyRow>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...others} />
  );
 

};

export default LoadingTableBody;