import React, {useState,useContext, useEffect} from "react";



import { Button,FormControl,CircularProgress,Autocomplete} from '@mui/material'



import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import FormColumn from '../../common/ui/FormColumn'

export default function AddInvestment(props){

    const [error,setError] = useState()
    const [stocks, setStocks] = useState([])
    const [stock, setStock] = useState()
    const [adding,setAdding] = useState(false)
    const [stockSearch, setStockSearch] = useState("")
    const [quantity, setQuantity] = useState(1)
    const [averagePrice, setAveragePrice] = useState()
    const [searchOpen, setSearchOpen] = useState(false) 
    const [loading, setLoading] = useState(false)
    

    const {getSession} = useContext(InvestorAccountContext)
    
    const portfolio = props.portfolio
    
    
    const addInv = () => { 
        if (portfolio.investments && portfolio.investments.filter(i => i.stock.symbol === stock.symbol).length > 0) {
           setError(<Alert severity="error">Portfolio already contains investment {stock.symbol}</Alert>)
        }else{
          setAdding(true)
          const cashReq = [{
            "symbol": stock.symbol,
            "quantity": quantity,
            "averagePrice": averagePrice,
            "currencyCode": stock.currency,
          }]
          getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/portfolios/'+props.portfolio.portfolioName,{
              method: 'POST',
              headers,
              body: JSON.stringify(cashReq)
            })
            .then( data =>{
              props.setInvestorPortfolio(data)
              handleClose()
            })
            .catch((error) => {
              console.log("There was an error " + error)
              setError(<Alert severity="error">Could not add Investment</Alert>)
              setAdding(false)
            })
          })
        }
       
    }

    const handleClose = () => {
        setQuantity(1)
        setError()
        setAveragePrice()
        setAdding(false)
        setStocks([])
        setStock()
        props.closeForm()
     }

    const selectProps = {
        options: stocks,
        getOptionLabel: (option) => option.symbol + " : " + option.name,
    };

    useEffect(() => {
      if (!searchOpen) {
        setStocks([]);
      }
    }, [searchOpen]);

    useEffect(() => {
     
      if(stockSearch && stockSearch!==""){
        setError()
        setLoading(true)
        getSession().then( ({headers}) => {
          CallAPI('/engine/stocks/search/'+encodeURIComponent(stockSearch),{
            method: 'GET',
            headers
          })
          .then( data =>{
            setStocks(data)
            setLoading(false)
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setLoading(false)
            setError(<Alert severity="error">Could not search stocks</Alert>)
          })
        })
      }
    }, [getSession,stockSearch]);

    return(

        <Dialog fullWidth={true} open={props.open} onClose={handleClose} aria-labelledby="Add Investment">
        <DialogTitle id="add-cash-title">{"Add Investment to Portfolio"}</DialogTitle>
        {error}
        <DialogContent>
        <FormColumn >
                 
                
                <FormControl fullWidth> 
                  
                    <Autocomplete
                    id="investment-select"
                    {...selectProps}
                    autoHighlight
                    onChange={(event, newValue) => {
                        setStock(newValue);
                    }}
                    open={searchOpen}
                    onOpen={() => {
                      setSearchOpen(true);
                    }}
                    onClose={() => {
                      setSearchOpen(false);
                    }}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Choose an investment"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        onChange={ e=>setStockSearch(e.target.value)}
                        />
                    )}
                />  
              </FormControl>
                          
           
                      
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={stock ? stock.symbol : ""} disabled={true}  id="symbol" label="Symbol" type="text"  />
                </FormControl>
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={stock ? stock.name : ""} disabled={true}  id="symbol" label="Name" type="text"  />
                </FormControl>    
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={stock? stock.currency : ""} disabled={true}  id="currency" label="Currency Code" type="text"  />
                </FormControl>
                <FormControl variant="outlined" fullWidth> 
                    <TextField  value={quantity} onChange={ e=>setQuantity(e.target.value)} id="quantity" label="Quantity"  type="number"/>
                </FormControl>
                <FormControl variant="outlined" fullWidth> 
                    <TextField  helperText="Optional" value={averagePrice} onChange={ e=>setAveragePrice(e.target.value)}  id="averagePrice" label="Average Price" type="number"/>
                </FormControl>
          </FormColumn>
        </DialogContent>
       
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addInv} color="primary" autoFocus>
          {adding && <CircularProgress size={14} />}
                    {!adding && "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    )
}