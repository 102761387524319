import { Alert, Button,  Grid,  Typography } from "@mui/material";
import { InverseSignInBox } from "./SignInBox";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { InvestorAccountContext } from "../InvestorAccounts";
import RegisterForm from "./RegisterForm";
import VerifyForm from "./VerifyForm";
import Success from "./Success";


export default function RegisterandVerify({investorID,handleSignInClick,setPassword, login, handleError}){

    const [isRegistering,setIsRegistering] = useState(false)
    const [verify, setVerify] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const {createInvestor} = useContext(InvestorAccountContext)
    
    const register = (fname,lname,password) =>{
        setIsRegistering(true)
        createInvestor(investorID, fname, lname, password)
        .then( data => {
            setIsRegistering(false)
            setVerify(true)
            setPassword(password)
        }).catch(err => {
            setIsRegistering(false)
            handleError(<Alert severity="error">{err.message}</Alert>)
        })
    }

    const handleSuccessfulVerification = () =>{
        setIsVerified(true)
    }

    let item = <RegisterForm handleError={handleError} investorID={investorID} register={register} isRegistering={isRegistering}/>
    if(verify) item = <VerifyForm investorID={investorID} handleError={handleError} handleSuccess={handleSuccessfulVerification}/>
    if(isVerified) item = <Success login={login}/>

    return(
        <InverseSignInBox>
        <Grid sx={{mt:'3px',mb: '15px'}} container wrap="nowrap" spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h6">Register</Typography>
            </Grid>
            <Grid item>
            <Button variant="outlined" color="primary">
                <Typography variant="button" onClick={handleSignInClick}>Sign In</Typography>
            </Button>
            </Grid>
        </Grid>
        
        {item}

        <Grid container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
        <Grid item>
        <Box
          component="img"
          sx={{
            mt: 1,
            height: 37,
            width: 143.5,
          }}
          alt="Equily"
          src="/Equily_Logo_Colour.png"
        />
          </Grid>
        </Grid>  
        </InverseSignInBox>
    )
}