import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormColumn from "../common/ui/FormColumn";
import { useMemo, useState } from "react";
import { ProgressButton } from "../common/ui/Buttons";
import { useContext } from "react";
import { InvestorAccountContext } from "../auth/InvestorAccounts";
import { CallAPI } from "../common/services/api/CallAPI";
import BannerAlert from "../common/ui/BannerAlert";

export default function BackTestEdit({backTestName, benchmarkName, refreshBackTests,setBackTest, open, handleClose,handleStatus,benchmarks}){

    const [name,setName] = useState(backTestName)
    const [saving,setSaving] = useState(false)
    const [newbenchmarkName, setNewBenchmarkName] = useState(benchmarkName)
   
    const {getSession} = useContext(InvestorAccountContext)
    
   
    const updateBackTest = () =>{
        handleStatus()
        setSaving(true)
        const backtest = {
            "backTestName": name, 
            "benchmarkName": newbenchmarkName,
        } 
        getSession().then( ({headers}) => { 
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/backtest/'+backTestName,{
              method: 'POST',
              headers,
              body: JSON.stringify(backtest)
            })
            .then( data =>{
                setBackTest(data)
                refreshBackTests()
                setSaving(false)
                handleClose()
                handleStatus(<BannerAlert severity="success" timed>Backtest Updated</BannerAlert>)
            })
            .catch((error) => {
                setSaving(false)
              console.log("There was an error " + error)
              handleStatus(<BannerAlert severity="error" timed>Could not save BackTest</BannerAlert>)
            })
          })

    }

    const benchmarkMenuItems = useMemo( () => {
        return benchmarks.map((bm,index) =>
              <MenuItem key={index} value={bm.benchmarkName}>{bm.benchmarkName}</MenuItem>
          )
    },[benchmarks])


    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="BackTest">
      <DialogTitle id="edit-stock-title">Edit BackTest</DialogTitle>
      <DialogContent>
          <FormColumn>
              <FormControl variant="outlined" fullWidth  >
                  <TextField autoFocus value={name} onChange={e => setName(e.target.value)} id="backTestName" label="BackTest Name" type="text" />
              </FormControl>
              <FormControl variant="outlined" fullWidth  >
                  <InputLabel id="benchmark-label">Model Portfolio</InputLabel>
                  <Select
                      labelId="benchmark-label"
                      id="benhcmark--select"
                      value={newbenchmarkName}
                      onChange={e => setNewBenchmarkName(e.target.value)}
                      variant="outlined"
                      label="Model Portfolio"
                  >
                      {benchmarkMenuItems}
                  </Select>
              </FormControl>
           
              </FormColumn >
      </DialogContent>

      <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
              Cancel
          </Button>
          <ProgressButton onClick={updateBackTest} progressing={saving} text="Save"></ProgressButton>
      </DialogActions>
     
    </Dialog>
    )
}