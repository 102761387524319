import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useContext, useState } from "react";
import { ProgressButton } from "../common/ui/Buttons";
import { InvestorAccountContext } from "../auth/InvestorAccounts";
import { CallAPI } from "../common/services/api/CallAPI";
import BannerAlert from "../common/ui/BannerAlert";
import { useNavigate  } from "react-router-dom";

export default function BackTestDelete({backTestName, open, handleClose, handleStatus, refreshBackTests }){

    const {getSession} = useContext(InvestorAccountContext)
    const [deleting, setDeleting] = useState(false)

    let navigate = useNavigate ();
    const deleteBackTest = () => {
        setDeleting(false)
        handleStatus()
        getSession().then( ({headers}) => { CallAPI('/engine/backtest/' + backTestName, { method: 'DELETE',headers }).then(data => {
          setDeleting(false)
          handleClose()
          refreshBackTests()
          navigate("/")
        })
        .catch((error) => {
            console.log("There was an error " + error)
            console.log(error)
            handleStatus(<BannerAlert severity="error" timed>Could not delete BackTest</BannerAlert>)
            handleClose()
          })
        })
    }

    return(

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-backtest-delete"
        aria-describedby="confirm backtest delete"
      >
        <DialogTitle id="confirm-backtest-delete">{"Confirm BackTest Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="onfirm-backtest-delete-description">
           Are you sure you want to delete this BackTest?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ProgressButton text="Delete" progressing={deleting} onClick={deleteBackTest}/>
        </DialogActions>
      </Dialog>

    )
}