import { Box, Divider, Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import BackTestControls from "../BackTestControls";
import { TabButton, TabPanel } from "../../common/grids/TabPanel";
import EditBackTestDetail from "./EditBackTestDetail";
import BackTestResultSummary from "./BackTestResultsSummary";
import BackTestSummaryChartNVD3 from "./BackTestSummaryChartNVD3";
import { CallAPI, CallAPIPaginated } from "../../common/services/api/CallAPI";
import { InvestorAccountContext } from "../../auth/InvestorAccounts";
import BannerAlert from "../../common/ui/BannerAlert";
import { BackTestTitle } from "../BackTestTitles";
import BackTestVolatilityChart from "./BackTestVolatilityChart";
  

export default function BackTestSummary({backTest,refreshBackTests,setBackTest,benchmarks,handleResultSelect}){

    const [tabValue,setTabValue] = useState(0)
    
    const {getSession} = useContext(InvestorAccountContext)

    const [backTestResultData,setBackTestResultData] = useState([])
    const [backTestResults, setBackTestResults] = useState([])
    const [status,setStatus] = useState([])
    const resultsDataRef = useRef([]);
    const intervalRef = useRef();
   
    const [fetching,setFetching] = useState(false)

   

    let isProcessing = useMemo(() => {
        return backTestResults.some((result)=>result.status==="Processing" )
    },[backTestResults])
    
    let definedCorrectly = (backTest.threshold && backTest.backTestType)
    let backTestReadyToRun =  definedCorrectly && !isProcessing
    
    const getResultPerformanceData = useCallback((signal,getSession, backTestName,backTestResults) =>{
       // don't want to get result data if we already have it.....
       const resultsToUpdate = backTestResults.filter( (result) => {
            return !(result.status==="Processing" || resultsDataRef.current.some((resultData) => resultData.result.resultId===result.resultId))
       })
        getSession().then( ({headers}) => {
            const promises = resultsToUpdate.map( theresult => {
                 return CallAPIPaginated('/engine/backtest/'+backTestName+'/result/portfolio/'+theresult.resultId,{method: 'GET',headers}).then( data => {
                    return {portfolioData: data, result: theresult }
                 })
            })
            Promise.all(promises).then(data => {
                const abort = signal?.aborted
                if(!abort){
             
                    const updatedResultData = [...resultsDataRef.current, ...data]
                    setBackTestResultData(updatedResultData)
                    resultsDataRef.current = updatedResultData
                }   
            })
        })
    },[])
  
    const refreshBackTestResults = useCallback((signal) => {
        
        setFetching(true)
        getSession().then( ({headers}) => {
            CallAPI('/engine/backtest/'+backTest.backTestName+'/result',{method: 'GET',headers}).then( data =>{
                const abort = signal?.aborted
                if(!abort){
                    setBackTestResults(data)
                    setFetching(false)
                    getResultPerformanceData(signal,getSession,backTest.backTestName,data)  
                }
            })
            .catch((error) => {
              console.log("There was an error getting backtest results for investor error " + error)
              setFetching(false)
              setStatus(<BannerAlert severity="error">Could not get backtest results</BannerAlert>)
            })
          })
    },[backTest.backTestName, getResultPerformanceData, getSession])

    
    useEffect(()=>{
        const controller = new AbortController();
        const { signal } = controller;
        refreshBackTestResults(signal)          
        return () => { 
            controller.abort();
        }

    },[refreshBackTestResults])
   
    useEffect(() => {
        if(isProcessing){
            let intervalCall = setInterval(() => {
                      refreshBackTestResults()
            }, 10000)
            intervalRef.current = intervalCall
        }
        return () => {
            clearInterval(intervalRef.current)
        }
    },[isProcessing,refreshBackTestResults])
  




    const runBackTest = () => {
        setTabValue(1)
        getSession().then( ({headers}) => {CallAPI('/engine/backtest/' + backTest.backTestName + '/runbacktest/', {
            method: 'GET', headers
          })
            .then(data => {
                refreshBackTestResults()
            })
            .catch((error) => {
                refreshBackTestResults()
              if(error.statusText){
                setStatus(<BannerAlert severity="error" timed>Error: {error.statusText}</BannerAlert>)
              }
              else{
                error.text().then( r => {
                    setStatus(<BannerAlert severity="error" timed>Error: {r}</BannerAlert>)
                })
              }
            })
          })
    }

    const deleteBackTestResult = (resultId) => {
     
      
        getSession().then( ({headers}) => { CallAPI('/engine/backtest/' + backTest.backTestName +'/'+resultId, { method: 'DELETE',headers }).then( () => {
            refreshBackTestResults()
        })
        .catch((error) => {
            console.log("There was an error " + error)
            setStatus(<BannerAlert severity="error">Could not delete BackTest Result</BannerAlert>)
          })
        })
        
        
      }


    return(
        <React.Fragment>
        <Grid  container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={8}>
                <BackTestTitle backTestName={backTest.backTestName}/>
            </Grid>
            <Grid item xs={4}>
                <BackTestControls disabled={false} runnable={backTestReadyToRun} backTest={backTest} runBackTest={runBackTest} setBackTest={setBackTest} refreshBackTests={refreshBackTests} handleStatus={(status) => setStatus(status)} benchmarks={benchmarks}/>
            </Grid>
        </Grid>
       
        <Box sx={{ height: '100%' }}>
        <Grid container direction="column" spacing={1}> 
            <Grid item>{status}</Grid>
            <Grid item>
            <Grid container spacing={2}>
                <Grid item>
                    <TabButton value={tabValue} index={0} onClick={() => setTabValue(0)}>Settings</TabButton>
                </Grid>
                <Grid item>
                    <TabButton value={tabValue} index={1} onClick={() => setTabValue(1)}>Results</TabButton>
                </Grid>
                <Grid item>
                    <TabButton value={tabValue} index={2} onClick={() => setTabValue(2)}>Performance</TabButton>
                </Grid>
                <Grid item>
                    <TabButton value={tabValue} index={3} onClick={() => setTabValue(3)}>Volatility</TabButton>
                </Grid>
            </Grid>
            </Grid>
            <Grid item>
            <Divider/>
            </Grid>
            <Grid item>
                <TabPanel value={tabValue} index={0}>
                    <EditBackTestDetail backTest={backTest} refreshBackTests={refreshBackTests} setBackTest={setBackTest}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <BackTestResultSummary deleteBackTestResult={deleteBackTestResult} handleResultSelect={handleResultSelect} fetching={fetching} backTestResults={backTestResults}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                   <BackTestSummaryChartNVD3 backTestResultData={backTestResultData}/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                   <BackTestVolatilityChart backTestResultData={backTestResultData}/>
                </TabPanel>
            </Grid>
            </Grid>  
        </Box>
      
    </React.Fragment>

    )

}