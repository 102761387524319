import { Card, CardContent, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, {  useEffect, useState } from "react";
import NVD3Chart from 'react-nvd3'

import { renderCurrency } from "../common/tables/Table";

export default function MyPorfolioPerformance(props){

    const portfolio = props.portfolio
    const portfolioHistory = props.portfolioHistory


    const status = [
        {
            value: 'week',
            label: 'This Week'
        },
        {
            value: 'month',
            label: 'This Month'
        },
        {
            value: 'year',
            label: 'This Year'
        },
        {
            value: 'all',
            label: 'All'
        }
    ]
    
    const [period, setPeriod] = useState('all');
    const [currencyCode, setCurrencyCode] = useState()
    const [chartData, setChartData] = useState([])
    const [performanceData, setPerformanceData] = useState([])
    

    useEffect( () => {
        if(portfolioHistory){
            setCurrencyCode(portfolio.portfolioCurrencyCode)
            const values = portfolioHistory.values
            var pData = values.map((value) => {
                const portfolioValue = parseFloat(value.portfolioValue.toFixed(2))
                var dataItem = { x: value.valueDate, y: portfolioValue}
                return dataItem
            })
            setPerformanceData(pData)
        }

    },[portfolioHistory,portfolio])
  


    useEffect( () => {
        if(period && performanceData.length>0){
            const dataCount = performanceData.length
            const dataLast = new Date(performanceData[dataCount-1].x)
            var startDate
            switch(period){
                case 'all':
                   startDate = 0
                break
                case 'year':
                   startDate = dataLast.setFullYear(dataLast.getFullYear()-1)
                break
                case 'month':
                    startDate = dataLast.setMonth(dataLast.getMonth()-1)
                break
                case 'week':
                    startDate = dataLast.setDate(dataLast.getDate()-7)
                break
                default:
                    startDate = 0
            }
            var run = { key: portfolio.portfolioName}
            const filterValues =  performanceData.filter( d => d.x>=startDate)
            run.values = filterValues
            setChartData([run])
        }


    },[period,performanceData,portfolio])




    return(
        <React.Fragment>
           <Card sx={{
                   borderRadius: '16px'
                }}>
                <CardContent>
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">Portfolio Performance</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="standard-select-perio"
                                        select
                                        value={period}
                                        onChange={(e) => setPeriod(e.target.value)}
                                    >
                                        {status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        <NVD3Chart sx={{p:'1rem'}}  id="line" type="lineChart" datum={chartData} x="x" y="y"
            xAxis={{
              tickFormat: (d) => new Date(d).toLocaleDateString(),
              margin: 5
            }}
            yAxis={{
                tickFormat: (d) => renderCurrency(d,currencyCode,0),
                margin:5
            }}
            height="400"
            margin={{top: 10, right: 40, bottom: 20, left: 70}}
            />
                        </Grid>
                    </Grid>
                </CardContent>
           </Card>
        </React.Fragment>
    )

}