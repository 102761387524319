import {useEffect, useState, useContext} from "react";
import List from '@mui/material/List'

import {NestedParentListItemLink} from '../../common/Link'
import {EquilyContext} from '../../common/Context'
import {ModelAllocationMenuItem} from './ModelAllocationMenuItem'
import { AddModelAllocationMenuItem } from "./AddModelAllocationMenuItem";
import { EquilyBuildIconGrey } from "../../icons/EquilyIcons";

export function ModelAllocationMenu(props){

    const {modelAllocations} = useContext(EquilyContext)
    const [modelitems, setModelItems] = useState(null)




    useEffect(() => {
       if(modelAllocations){
            
            const items = modelAllocations.map((model,index) =>
                 <ModelAllocationMenuItem key={index} modelAllocation={model}/>
            )
            const addModel = <AddModelAllocationMenuItem key="addmodelitem"></AddModelAllocationMenuItem>
            items.push(addModel)
            

            const itemlist = 
            <List key="modellist" component="div" disablePadding>
                {items}
            </List>
            setModelItems(itemlist)
       }
      }, [modelAllocations]);
    


    return(

        
        <NestedParentListItemLink key="models" primary="Allocations" icon={<EquilyBuildIconGrey />} children={modelitems} />

    )

}


