import { Divider } from "@mui/material";
import React, {} from "react";
import { useContext } from "react";
import { InvestorAccountContext } from "../auth/InvestorAccounts";
import BOSMenu from "./BOSMenu";
import InvestorMenu from "./InvestorMenu";
import MainMenu from "./MainMenu";

export default function SideMenu({backTests,addBackTest,benchmarks,setBackTest}) {

    const {user} = useContext(InvestorAccountContext)
    
    let items = []

    if(user.username){
            items = [<MainMenu key="mainMenu"/>,
            <Divider key="menuDivider"/>,
            <InvestorMenu key="investorMenu"/>,
            <Divider key="menuDivider2"/>,
            <BOSMenu key="bosMenu" backTests={backTests} setBackTest={setBackTest} addBackTest={addBackTest} benchmarks={benchmarks}/>,
        ]
            
    }
    

        return (
             <React.Fragment>
                {items}
            </React.Fragment>
        )
   

}