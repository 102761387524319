import React, {} from "react"


import { Box, Grid, Stack } from "@mui/material";
import Title from "../common/ui/Title";
import MyPortfoliosCard from "./MyPortfoliosCard";
import StoreCard from "./StoreCard";




export function Dashboard(props){

    const dashBoard = (
        <React.Fragment>     
           <Box>
            <Title>
                Equily - Take control of your financial future
            </Title>
        </Box>

        <Stack spacing={2}>
           <Box sx={{paddingBottom: '16px'}}>
           <Grid  container direction="column" spacing={2}>
           <Grid item>
               <Grid container direction="row" spacing={2}>
               <Grid item xs={7}>
                <MyPortfoliosCard/>
                </Grid>
                <Grid item xs={5}>
                    <StoreCard/>
                </Grid>
               </Grid>
           </Grid>
       
          
       </Grid> 

           </Box>
       </Stack>
        </React.Fragment>   

    )


    return(
        <React.Fragment>
        {dashBoard} 
        </React.Fragment>      
    )
    

}