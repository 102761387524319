
import React, { useState, useEffect} from 'react'

import {Grid,Typography} from '@mui/material'

import { ScaledBar } from '../../common/ui/ScaleBars'

export default function SummaryViewCardARR(props){

    const mp = props.modelPortfolio

    const [ARRData, setARRData] = useState([])

    useEffect(() => {
        if(mp){
            var arr = [4]
            const arrs = mp.arrData
            if(arrs){
                const y1 = arrs.filter( a => a.period==="Y1")
                if(y1[0]){
                    arr[0] = y1[0].growth.toFixed(2)
                }else{
                    arr[0] = "-"
                }
                const y5 = arrs.filter( a => a.period==="Y5")
                if(y5[0]){
                    arr[1] = y5[0].growth.toFixed(2)
                }else{
                    arr[1] = "-"
                } 
                const y10 = arrs.filter( a => a.period==="Y10")
                if(y10[0]){
                    arr[2] = y10[0].growth.toFixed(2)
                }else{
                    arr[2] = "-"
                }  
                const ya = arrs.filter( a => a.period==="ALL")
                if(ya[0]){
                    arr[3] = ya[0].growth.toFixed(2)
                }else{
                    arr[3] = ""
                }
            } 
            setARRData(arr)
        }

    },[mp])

    return(
        
        <React.Fragment>
        
            <Grid item>
                    <Typography variant="overline">
                        Annual Rebalanced Return (%)
                    </Typography>
            </Grid>

            <Grid item>
                 <Grid container direction="row" spacing="2" justifyContent="space-between" alignItems="center">

                    <Grid xs={2} item spacing="2"  justifyContent="center" container direction="column">
                        <Grid item>
                            <Typography variant="subtitle2">
                                    1Y
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                    5Y
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                    10Y
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                    All
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid xs={8} item spacing="2" justifyContent="center" container direction="column">
                        <Grid item>
                            <ScaledBar value={ARRData[0]}/>
                        </Grid>
                        <Grid item>
                            <ScaledBar value={ARRData[1]}/>
                        </Grid>
                        <Grid item>
                            <ScaledBar value={ARRData[2]}/>
                        </Grid>
                        <Grid item>
                            <ScaledBar value={ARRData[3]}/>
                        </Grid>
                    </Grid>

                    <Grid xs={2} item spacing="2" container direction="column">
                        <Grid item>
                            <Typography variant="subtitle2">
                                {ARRData[0]}%
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                {ARRData[1]}%
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                {ARRData[2]}%
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                {ARRData[3]}%
                            </Typography>
                        </Grid>
                    </Grid>
                 </Grid>
            </Grid>

        </React.Fragment>

    )

}