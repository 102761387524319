
export const getSymbol = (currency) => {  
    const value = 1.0
    if(currency && value){
        const symbol = new Intl.NumberFormat('en', { style: 'currency', currency }).formatToParts(value).find(x => x.type === 'currency');   
        return symbol && symbol.value; 
    }
  }


export const toCurrency = (data, symbol) => {


    var start = ""
    var end = ""

    if(symbol==='GBX') end = "p"
  
    const s = getSymbol(symbol)
    if(s!==symbol) start = s

    const value = data.toLocaleString(undefined,{maximumFractionDigits: 2, minimumFractionDigits: 2}) 
  
    return start + value + end
}