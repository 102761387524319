import {useContext} from "react";
import {EquilyContext} from '../../common/Context'

import { useNavigate  } from "react-router-dom";
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'


import { EquilyListItemText } from "../../common/Link";
import { EquilyBuildIconGrey } from "../../icons/EquilyIcons";




export function ModelAllocationMenuItem(props){
    
   
    const {selectModelAllocation} = useContext(EquilyContext);
    let navigate = useNavigate ();

    const selectTheModelAllocation = () => {
        selectModelAllocation(props.modelAllocation)
        navigate("/modelallocation");
    }
    

    return(
        
            <ListItem key={props.modelAllocation.modelAllocationName+"Model"} button sx={{ pl: 4 }} onClick={selectTheModelAllocation}>
                <ListItemIcon key={props.modelAllocation.modelAllocationName+"ModelIcon"}>
                     <EquilyBuildIconGrey />
                </ListItemIcon>
                <EquilyListItemText key={props.modelAllocation.modelAllocationName+'ModelText'} primary={props.modelAllocation.modelAllocationName} />
            </ListItem>
       
    )

}