import React, {useState, useContext} from "react";

import Alert from '@mui/material/Alert';
import { Grid, Button, CircularProgress } from '@mui/material'

import { CallAPI } from '../common/services/api/CallAPI'

import {InvestorAccountContext} from '../auth/InvestorAccounts'


export default function BenchmarkUpdateModel(props){
    
   
    
    const benchmark = props.benchmark
    const setBenchmark = props.setBenchmark
    const {getSession} = useContext(InvestorAccountContext)
    const [updating, setUpdating] = useState(false)
    const [error,setError] = useState()
    

    const updateModel = () => {
        setUpdating(true)
        setError()
        getSession().then( ({headers}) => {
            CallAPI('/engine/benchmark/'+benchmark.benchmarkName+'/updateModel',{
                method: 'GET',
                headers,
            })
            .then( data =>{
                setUpdating(false)
                setBenchmark(data)
            })
            .catch((error) => {
                setError(<Alert severity="error">Could not update model - {error.statusText}</Alert>)
                setUpdating(false)
            })
        })
    }
   
    if(benchmark.latestModel){
        return( <React.Fragment></React.Fragment>)
    }else{

        return(
        <React.Fragment>
            {error}
           <Alert severity="warning">
            <Grid container spacing={10} alignItems="center" direction="row" justifyContent="space-between">
                <Grid item>
               This Model Portfolio is not based on the latest model version - updateModel
               </Grid>
               <Grid item>
               <Button  variant="contained" onClick={updateModel} color="secondary" autoFocus>
                     {updating && <CircularProgress />}
                     {!updating && "Update Model"}
                </Button>
                </Grid>
            </Grid>
           </Alert>
        </React.Fragment>
        )
    }
}