import {  Divider, Grid, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import BalanceIcon from '@mui/icons-material/Balance'
import TimelineIcon from '@mui/icons-material/Timeline'
import {badColour, goodColour, averageColour} from '../common/ui/Colours'

const ModelItemCard = (props) => {

    const mp = props.modelPortfolio

    const [iconStyles, setIconStyles] = useState({})
    const [riskRatings, setRiskRatings] = useState({})

    useEffect( () => {     
      if(mp){

        var styles = {rar: {color: '#9B3259'}, volatility: {color: '#9B3259'} }
        var ratings = {}
        const rar  = mp.rar
        if(rar<1.0){
          ratings.rar = "Poor"
          styles.rar = {color: badColour}
        }else if(rar>=1.0 && rar<=2.0){
          ratings.rar = "Good"
          styles.rar = {color: averageColour}
        }else if(rar>=2.0 && rar<=3.0){
          ratings.rar = "Very Good"
          styles.rar = {color: goodColour}
        }else if(rar>=3.0){
          ratings.rar = "Excellent"
          styles.rar = {color: goodColour}
        }
        const vol = mp.volatility
        if(vol<10.0){
          ratings.volatility = "Very Low"
          styles.volatility = {color: goodColour}
        }else if(vol>=10.0 && vol<=12.0){
          ratings.volatility = "Low"
          styles.volatility = {color: goodColour}
        }else if(vol>=12.0 && vol<=16.0){
          ratings.volatility = "Medium"
          styles.volatility = {color: averageColour}
        }else if(vol>=16.0){
          ratings.volatility = "High"
          styles.volatility = {color: badColour}
        }
        setRiskRatings(ratings)
        setIconStyles(styles)
      }
    },[mp])

    return(
           <React.Fragment>
       
            <Stack spacing={1}>
                <Typography variant="subtitle1" color="primary">
                    {mp.name}
                </Typography>
                <Grid container direction="row" justifyContent="space-between" spacing={1}>
                    <Grid item>
                        
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <PieChartOutlineIcon/>
                          </Grid>
                          <Grid item>
                              <Typography>
                              {mp.investments.length}
                              </Typography>
                          </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <BalanceIcon sx={iconStyles.rar}/>
                          </Grid>
                          <Grid item>
                              <Typography  sx={iconStyles.rar} >
                              {riskRatings.rar}
                              </Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                          <TimelineIcon sx={iconStyles.volatility}/>
                          </Grid>
                          <Grid item>
                              <Typography  sx={iconStyles.volatility} >
                              {riskRatings.volatility}
                              </Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                  
                </Grid> 
                <Divider sx={{ my: 1.5 }} />
            </Stack>    
        
        
        </React.Fragment>
    )
}

export default ModelItemCard