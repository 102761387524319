import React, {useState,useContext,useEffect} from "react";



import { Fab, Paper,Grid, Box} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'



import MUIDataTable from "mui-datatables";

import {EquilyContext} from '../../common/Context'
import {renderCurrencyNumber, renderNumber } from '../../common/tables/Table'
import Title from '../../common/ui/Title'
import AddInvestment from "./AddInvestment";
import EditInvestment from "./EditInvestment";

export default function Investments(props){

  
    const [open,setOpen] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)
    
    const [data,setData] = useState([]) 
    const [selectedInvestment, setSelectedInvestment] = useState()
    const {portfolio,setInvestorPortfolio, refreshBenchmarks} = useContext(EquilyContext)

    const columns = [
      { name: 'symbol', label: 'Stock Symbol',},
      { name: 'name', label: 'Stock Name',},
      { name: 'type', label: 'Stock Type',},
      { name: 'volume', label: 'Volume',options: {customBodyRenderLite: (dataIndex) => {return renderNumber(data,dataIndex,'volume')}}},
      { name: 'averagePrice', label: 'Average Price', options: {customBodyRenderLite: (dataIndex) => {return renderNumber(data,dataIndex,'averagePrice')}}},
      { name: 'price', label: 'Current Price',  options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'price',data[dataIndex].currencyCode)}}},
      { name: 'value', label: 'Value',options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'value',portfolio.portfolioCurrencyCode) }}},
    ];


    
    

    const closeAddForm = () => {
       setOpen(false) 
    }
    const closeEditForm = () => {
      setOpenEdit(false) 
   }
   


    useEffect(() => {
      if(props.investments){    

        const calcs = props.investments.map( (row) => {
           const newrow = {
            investmentId: row.investmentId,
            symbol: row.symbol,
            name: row.name,
            type: row.type,
            currencyCode: row.currencyCode,
            volume: Number(row.volume),
            averagePrice: Number(row.averagePrice),
            price: Number(row.price),
            value: Number(row.value),
           }
           return newrow;
        })

        setData(calcs)
      }
    },[props.investments])
    
    

   

    const selectInvestment = (row, rowmeta) => {
      setSelectedInvestment(data[rowmeta.dataIndex])
      setOpenEdit(true)
    }

    const tableOptions = {
      filterType: 'checkbox',
      filter: false,
      print: false,
      caseSensitive: false,
      resizableColumns: false,
      selectableRows: 'none',
      draggableColumns: {
        enabled: true,
        transitionTime: 1000
      },
      onRowClick: selectInvestment,
      searchProps: {
        spellcheck: "false",
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off"
      },
    }
    
   

    return( 
    <React.Fragment>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Title>
                    Investments
          </Title>
          <Fab color="primary" variant="extended" onClick={() => setOpen(true)}>
                <AddIcon/>
                Add Inv.
          </Fab>
        </Grid>
        <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
        <Paper>
                <MUIDataTable data={data} columns={columns} options={tableOptions}/> 
        </Paper>
        </Box>

        <AddInvestment open={open} closeForm={closeAddForm} setInvestorPortfolio={setInvestorPortfolio} portfolio={portfolio}></AddInvestment>
        <EditInvestment open={openEdit} closeForm={closeEditForm} investment={selectedInvestment} setInvestorPortfolio={setInvestorPortfolio} portfolioName={portfolio.portfolioName} refreshBenchmarks={refreshBenchmarks}></EditInvestment>
    </React.Fragment>
      
    )

}