import React, {} from "react";


import {List} from '@mui/material'

import {BackTestMenu} from '../backtests/menu/BackTestMenu'
import { RebalanceMenu } from "../rebalance/menu/RebalanceMenu";
import { ManagedNestedParentListItemLink } from "../common/Link";
import { EquilyOptimiseIconWhite } from "../icons/EquilyIcons";


export default function OptimiseMenu({open,setOpen,setClose,backTests,addBackTest,setBackTest,benchmarks}) {

    const children = [ <BackTestMenu key="backTestMenu" backTests={backTests} setBackTest={setBackTest} addBackTest={addBackTest} benchmarks={benchmarks}/>,
                         <RebalanceMenu key="rebalanceMenu"/>]


        return(
            <React.Fragment>
            <List key={'optimiseMenu'} sx={{pb: 0}}>
             <ManagedNestedParentListItemLink 
              sx={{
                backgroundColor: 'pantone.pantone7546', 
                color: 'text.light',
                '&:hover': {
                    backgroundColor: 'pantone.pantone7546Dark', 
                },
                }}
              primary="Power-Up"
              icon={<EquilyOptimiseIconWhite />} 
              children={children} 
              open={open}
              setOpen={setOpen} 
              setClose={setClose}/>
             </List>
            </React.Fragment>
        )
    

}