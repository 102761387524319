import {useContext} from "react";
import {EquilyContext} from '../../common/Context'

import { useNavigate  } from "react-router-dom";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import CameraIcon from '@mui/icons-material/Camera';
import { EquilyListItemText } from "../../common/Link";



export function BenchmarkMenuItem(props){
    
    
    const {selectBenchmark} = useContext(EquilyContext);
    let navigate = useNavigate ();

    const selectTheBenchmark = () => {
        selectBenchmark(props.benchmark)
        navigate("/benchmark");
    }


    return(
        
            <ListItem key={props.benchmark.benchmarkName+"Bench"} button sx={{ pl: 4 }} onClick={selectTheBenchmark}>
                <ListItemIcon key={props.benchmark.benchmarkName+"BenchIcon"}>
                     <CameraIcon />
                </ListItemIcon>
                <EquilyListItemText key={props.benchmark.benchmarkName+"BenchText"} primary={props.benchmark.benchmarkName} />
            </ListItem>
       
    )

}