import React, {createContext,useState} from 'react'
import {authUser,googleSignIn,createUser,updateUser,changePassword,getSession,logout as cognitoLogout,resendConfirmationCode, forgotPasswordReset,sendCode,verifyCode as cognitoVerifyCode, facebookSignIn, updateAttribute} from './cognito/Cognito'
import { useEffect } from 'react'


export const InvestorAccountContext = createContext()

export const InvestorAccount = (props) => {

    
    const [user,setUser] = useState({})
    const resendVerification  = resendConfirmationCode
    

    useEffect( ()=>{
      checkUser()
    },[])

    const authenticateInvestor = async (investorID, password) => {
      const auth = await authUser(investorID,password)
      setUser(auth)
      return auth
    }


    const logout = () => {
        cognitoLogout().then( (res) => {
          setUser({})
        })
    }
    
    const checkUser = () => {
      getSession().then( ({user}) => {
         setUser(user) 
      }).catch(e => {
          // no user - that's ok - not logged in yet
      })
    }

    const createInvestor = async (email, firstName, surname, password) => {
        return await new Promise((resolve, reject) => {   
            var attributes = {
                email: email,
                given_name: firstName,
                family_name: surname,
            }
            createUser(email, password,attributes).then( data => {
                resolve(data)
            }).catch( err => reject(err))
         })


     }

     const sendForgottenCode = async (username) => {
        try {
          await sendCode(username)
        } catch (err) {
          throw err
        }
      }
      const acceptTerms = async () => {
        try {
          const u = await updateAttribute({"custom:terms": "1"})
          setUser(u)
        } catch (err) {
          throw err
        }
      }

      const verifyCode = async (username,code) => {
        try {
          await cognitoVerifyCode(username,code)
        } catch (err) {
          throw err
        }
      }
      const forgotPassword = async (username, code, password)  =>{
        try {
          await forgotPasswordReset(username, code, password)
        } catch (err) {
          throw err
        }
      }
    
      const googleAuth = async () =>{
        try {
          await googleSignIn()
        } catch (err) {
          throw err
        }
      }

      const facebookAuth = async () =>{
        try {
          await facebookSignIn()
        } catch (err) {
          throw err
        }
      }
    return(
        <InvestorAccountContext.Provider value={{
            acceptTerms, authenticateInvestor,googleAuth,facebookAuth, checkUser, user,getSession, createInvestor,logout,resendVerification,updateUser,changePassword,sendForgottenCode,forgotPassword,verifyCode
        }}>
            {props.children}
        </InvestorAccountContext.Provider>
    )
}




