import React, {useContext,useEffect, useState} from "react";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Fab, Grid, Box} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert'

import {EquilyContext} from '../common/Context'
import {InvestorAccountContext} from '../auth/InvestorAccounts'

import Cash from './cash/Cash'
import Investments from './investments/Investments'
import { DeletePortfolio } from "./DeletePortfolio";
import { CallAPI } from '../common/services/api/CallAPI'
import Title from '../common/ui/Title'
import { useNavigate  } from "react-router-dom";


export default function PortfolioDetail(props){

    const {portfolio,refreshPortfolios,refreshBenchmarks, refreshBackTests,refreshModelAllocations} = useContext(EquilyContext);
    const {getSession} = useContext(InvestorAccountContext)

    const [investments, setInvestments] = useState([])
    const [cash, setCash] = useState([])
    const [openDeletePortfolio, setOpenDeletePortfolio] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()


    let navigate = useNavigate ();


    const toggleOpenDeleteConfirmation = () => {
        setOpenDeletePortfolio(!openDeletePortfolio)
    }

    const deletePortfolio = () => {
        
        setLoading(true)
        getSession().then( ({headers}) => { CallAPI('/engine/portfolios/' + portfolio.portfolioName, { method: 'DELETE', headers }).then(data => {
          setLoading(false)
          toggleOpenDeleteConfirmation()
          refreshPortfolios();
          refreshBenchmarks();
          refreshBackTests();
          refreshModelAllocations();
          navigate("/");
        })
        .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not delete Portfolio</Alert>)
            toggleOpenDeleteConfirmation()
        })
    })

    }

    const getSymbol = (currency) => {  
        const value = 1.0
        if(currency && value){
            const symbol = new Intl.NumberFormat('en', { style: 'currency', currency }).formatToParts(value).find(x => x.type === 'currency');   
            return symbol && symbol.value; 
        }
    }


    useEffect(() => {

        var investments = []
        if(portfolio.investments) investments = portfolio.investments

        var invs = investments.map( (investment) => {
            var avgPrice = ""    
            if(investment.averagePrice){
                avgPrice = investment.averagePrice.toFixed(2)
            }
            var investmentrow = {
                investmentId: investment.investmentId,
                symbol: investment.stock.symbol,
                currencyCode: investment.stock.currencyCode,
                name: investment.stock.name,
                type: investment.stock.type,
                price: investment.stock.price.toFixed(2),
                volume: investment.volume.toFixed(2),
                averagePrice: avgPrice,
                value: investment.value.toFixed(2),
            }
            return investmentrow
        })
            
        var cashes = []
        if(portfolio.cash) cashes = portfolio.cash
        var cashvalues = cashes.map( (c) => {
            var cashrow = {
                cashId: c.cashId,
                amount: c.amount.toFixed(2),
                currency: c.currencyCode,
                value: c.value.toFixed(2),
            }
            return cashrow
        })
       
        setInvestments(invs)
        setCash(cashvalues)
    }, [portfolio.investments,portfolio.cash])

   
    var value = 0.0;
    if(portfolio.portfolioValue) value = portfolio.portfolioValue

    return(


        <React.Fragment>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Title>
                {portfolio.portfolioName}
                </Title>
            
                <Title>
                    Portfolio Value: {getSymbol(portfolio.portfolioCurrencyCode)}{value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </Title>
                <Tooltip title="Delete BackTest" aria-label="Delete">
                    <Fab color="secondary" aria-label="delete" zindex="tooltip" onClick={toggleOpenDeleteConfirmation}>
                        <DeleteForeverIcon />
                    </Fab>
                </Tooltip>  
                {error}
            </Grid>
            
            <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
                <Investments investments={investments}></Investments>
            </Box>
            <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
                <Cash cash={cash}></Cash>
            </Box>
            <DeletePortfolio loading={loading} open={openDeletePortfolio} handleClose={toggleOpenDeleteConfirmation} handleDelete={deletePortfolio}></DeletePortfolio>
          
        </React.Fragment>


    )


}