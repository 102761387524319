
import {NestedParentListItemLink, SpinnerItem} from '../../common/Link'
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import { BackTestMenuItem } from './BackTestMenuItem';
import { AddBackTestMenuItem } from './AddBackTestMenuItem';
import { List } from '@mui/material';



export function BackTestMenu({backTests,addBackTest,setBackTest,benchmarks}){

   
    var items = []
    if(backTests){
            items = backTests.map((bt,index) =>
              <BackTestMenuItem key={index} backTest={bt} setBackTest={setBackTest}/>
            )    
    }else{
            const spinner = <SpinnerItem key='btspin'/>
            items.push(spinner)
    }
    const addBT = <AddBackTestMenuItem key="AddBackTest" addBackTest={addBackTest} benchmarks={benchmarks}></AddBackTestMenuItem>
    items.push(addBT)
    const backtestitems = 
             <List key="backtestlist" component="div" disablePadding>
                 {items}
             </List>
    
    return(
        <NestedParentListItemLink sx={{backgroundColor: 'pantone.pantone7546Light'}} primary="Testing" icon={<MultilineChartIcon />} children={backtestitems} />
    )

}