import React, {} from "react";

import {Route, Routes} from "react-router-dom";

import AddPortfolio from './AddPortfolio'
import PortfolioDetail from './PortfolioDetail'






export default function Portfolios(props){

    return(

            <Routes>
            <Route  exact path="/portfolios" element={
 <PortfolioDetail />
            }/>
            <Route exact path="/portfolios/addPortfolio" element={<AddPortfolio/>} />
               
            </Routes>
      
    )


}