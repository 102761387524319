import * as d3 from 'd3'


const infoColours = [
    "#f0c2b3",
    "#e8927c",
    "#e0bed3",
    "#9f3259",
    "#c9612e",
    "#f6b436",
    "#b1c0bc",
    "#7ba6bb",
    "#cfe0d8",
    "#4c868f",
    "#035157",
    "#002554"
]

export const goodColour = "#035157"
export const averageColour = "#f6b436"
export const badColour = "#9f3259"


export const infographicColour = d3.scaleOrdinal(infoColours);
   