import { Box, Card, CardContent, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { InvestorAccountContext } from "../auth/InvestorAccounts"
import { CallAPI } from "../common/services/api/CallAPI"
import ModelItemCard from "./ModelItemCard"



const StoreCard = (props) => {
    
    const [items, setItems] = useState([])
    
    const {getSession} = useContext(InvestorAccountContext)

    const [modelPortfolios, setModelPortfolios]  = useState([])  
  

    useEffect( () =>{            
        getSession().then( ({headers}) => {
            CallAPI('/store/modelportfolios',{method: 'GET',headers}).then( data =>{
            setModelPortfolios(data)
          })
          .catch((error) => {
            console.log("There was an error " + error)
            console.log(error)
          })
        })
    },[getSession])



    useEffect( () => {
        if(modelPortfolios && modelPortfolios.length>0){     
            const d = modelPortfolios.map( (mp) => {
                var item = <Grid item key={"storeCard" + mp.id}> <ModelItemCard modelPortfolio={mp}/></Grid>
                return item
            })
            setItems(d)
        }else{
            const noItem =  <Grid key="storeCardNoItem" container justifyContent="center" alignItems="center" >
            <Grid item >
              <CircularProgress />
            </Grid>
          </Grid>
          
            setItems([noItem])
        }
        
    },[modelPortfolios])
   

    return(
        <React.Fragment>
        <Card sx={{
             borderRadius: '16px'
          }}>
          <CardContent>
            <Stack spacing={1}>

           
                      <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                              <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                      <Typography variant="h6">Popular Model Portfolios</Typography>
                                  </Grid>
                              </Grid>
                          </Grid>
                          <Grid item>
                          </Grid>
                      </Grid>
                
                  <Divider/>
                  <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
                     {items}
                  </Box>
              
            </Stack>
          </CardContent>
          </Card>
  </React.Fragment>
    )
}


export default StoreCard