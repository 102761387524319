import React, {useContext,useState} from "react";


import TextField from '@mui/material/TextField'
import { Button,FormControl} from '@mui/material'
import Alert from '@mui/material/Alert';

import { useNavigate  } from "react-router-dom";

import { CallAPI } from '../common/services/api/CallAPI'
import {InvestorAccountContext} from '../auth/InvestorAccounts'

import {EquilyContext} from '../common/Context'
import Title from '../common/ui/Title'
import FormColumn from '../common/ui/FormColumn'
import {MainPaperItem} from '../common/ui/MainItem'
import CurrencySelect from "../common/ui/CurrencySelect";

export default function AddPortfolio(props){

   
    const [name,setName] = useState("")
    const [currency, setCurrency] = useState("")
    const [error, setError] = useState()
    const {setPortfolios,setInvestorPortfolio} = useContext(EquilyContext)
    const {getSession} = useContext(InvestorAccountContext)
    let navigate = useNavigate ();

    const addPortfolio = () => {
        const req = [{
            "portfolioCurrencyCode": currency, 
            "portfolioName": name,
        }]
        getSession().then( ({headers}) => {
            headers['Content-Type'] =  'application/json'
            CallAPI('/engine/portfolios/',{
                method: 'POST',
                headers,
                body: JSON.stringify(req)
                })
            .then( data =>{
                const ports = data
                const thePortfolio = ports.find( (portfolio) => portfolio.portfolioName===name)
                if(thePortfolio)setInvestorPortfolio(thePortfolio)
                setPortfolios(data)
                navigate("/portfolios");
            })
            .catch((error) => {
                if(error.statusText){
                    setError(<Alert severity="error">{error.statusText}</Alert>)
                   
                  }
                  else{
                    error.text().then( r => {
                      setError(<Alert severity="error">{r}</Alert>)
                    
                    })
                  }
            })
        })
      
      
    }


    return(
        <MainPaperItem>
       
            <Title>
                Add Portfolio
            </Title> 
            {error}
        
        
        <FormColumn>
                <FormControl variant="outlined"  > 
                    <TextField autoFocus value={name} onChange={ e=>setName(e.target.value)}  id="amount" label="Portfolio Name"  type="text"/>
                </FormControl>
                <FormControl variant="outlined" > 
                    <CurrencySelect setCurrency={setCurrency}/>
                </FormControl>    
                <Button  variant="contained" onClick={addPortfolio} color="primary" >
              
                    Save
                </Button> 
        </FormColumn>
       
        </MainPaperItem>
    )
}