import { Box, Typography } from "@mui/material";
import { averageColour, badColour, goodColour } from "./Colours";

export  function ScaledBar(props){

    const value = props.value
    const style = getStyles(value)
    
    if(!value || value==="" || value==="-"){
        return(
            <Box>
            <Typography variant="subtitle2">-</Typography>
           
        </Box>
        )
    }else{
        return(
            <Box sx={style}>
             <Typography variant="subtitle2"> &nbsp;</Typography>
            </Box>
        )
    }
    
    
}

const getStyles = (value) => {
    var style ={borderRadius: '10px'}
    style.bgcolor = badColour
    if(value>10){
        style.bgcolor = goodColour
    }else if(value>=5){
        style.bgcolor = averageColour
    }
    style.width = getWidth(value)

    return style
}

const getWidth = (value) =>{
    
    var size
    if(value>20){
        size = 120
    }else if(value<=0){
        size = 10
    }else{
        size = (value*100)/20
    }
   
    return size
}