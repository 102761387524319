


import {Button, CircularProgress, Grid } from "@mui/material"
import React, { useState, useContext } from "react"
import Title from "../common/ui/Title"
import { InvestorAccountContext } from "./InvestorAccounts"
import { useNavigate  } from "react-router-dom";
import { TermsText } from "../legal/TermsText";
import SingleLayerContainer from "../common/ui/SingleLayerContainer";

export function Terms(props){

    const [accepting, setAccepting] = useState(false)
    const {acceptTerms} = useContext(InvestorAccountContext)
    const navigate = useNavigate ();

    const setAcceptTerms = () => {
        setAccepting(true)
        acceptTerms().then( () => {
            setAccepting(false)
            navigate("/") 
        }
        )
    }

    return(
        <SingleLayerContainer>
           <Grid sx={{maxWidth: '70%'}} container direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                      <Title>
                            Terms and Conditions
                      </Title>
                    </Grid>
                    <Grid item sx={{marginLeft: '10px', backgroundColor: 'white', border:1, borderColor: 'pantone.pantone1235', borderWidth: '5px', borderRadius: '8px',overflow: 'auto',maxHeight: '75vh' }}>
                      <TermsText/>
                    </Grid>
                    <Grid item>
                    <Button variant="contained" onClick={setAcceptTerms} color="secondary" autoFocus>
                        {accepting && <CircularProgress size={20} />}
                        {!accepting && "Accept"}
                    </Button>
                    </Grid>
            </Grid> 
        </SingleLayerContainer>
    )
}