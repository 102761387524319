import React, { useContext, useEffect, useState } from 'react'

import {Button, Chip, Grid,  Menu,  MenuItem,  Tooltip } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'

import {InvestorAccountContext} from '../../auth/InvestorAccounts'
import { CallAPI } from '../../common/services/api/CallAPI'
import SummaryViewCardItem from './SummaryViewCardItem'
import { useMemo } from 'react'
import Title from '../../common/ui/Title'

export function ModelPortfolioDiscover(props){
   
    const {getSession} = useContext(InvestorAccountContext)

    const [modelPortfolios, setModelPortfolios]  = useState([])  
  
    const [filter, setFilter] = useState("")
    const [sort, setSort] = useState()

    const sortOptions = [
        {label:"Name" , key:"name",compare: (a,b)=>{ return a.name < b.name ? -1 : 1}}, 
        {label:"Risk Adjusted Return" , key:"rar", compare: (a,b)=>{return a.rar>b.rar? -1:1}},
        {label:"Number Of Allocations" , key:"allocs", compare: (a,b)=>{ return a.investments.length>b.investments.length? -1:1}},
        {label:"Volatility" , key:"Volatility",compare: (a,b)=>{return a.volatility<b.volatility? -1:1}},
        {label:"Rebalanced Return" , key:"return",compare: (a,b)=>{  return a.arrData[a.arrData.length-1].growth>b.arrData[b.arrData.length-1].growth ? -1:1}} 
    ]


    // sort button plumbing
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleSortClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleSortClose = () => {
      setAnchorEl(null);
    };



    useEffect( () =>{            
        getSession().then( ({headers}) => {
            CallAPI('/store/modelportfolios',{method: 'GET',headers}).then( data =>{
            setModelPortfolios(data)
          })
          .catch((error) => {
            console.log("There was an error " + error)
            console.log(error)
          })
        })
    },[getSession])

    const sortedModelPortfolios = useMemo( () => {
        var mps = [...modelPortfolios]
        if(sort){
             mps.sort(sort.compare)
        }
        return mps
    }, [sort,modelPortfolios])       

    let currencyFilters = []
    modelPortfolios.forEach( (mp) => {
        if(!currencyFilters.includes(mp.modelCurrencyCode))currencyFilters.push(
            mp.modelCurrencyCode
        )
    })

    let filters = currencyFilters.map( (curr => {
        return <Grid key={curr+"Chip"} item><Chip label={curr} color={curr===filter? "success" : "primary"} onClick={() => setFilterValue(curr)}/></Grid>
    }))

    let filteredPorfolios = sortedModelPortfolios.filter( (mp) => {
        if(filter!=="") return mp.modelCurrencyCode===filter
        else return true
    })

    let data = filteredPorfolios.map( (mp) => {
        var item =<Grid key={mp.id + "discoverCard"} container item xs={12} sm={12} md={4} lg={4}><SummaryViewCardItem selectModelPortfolio={() => props.selectModelPortfolio(mp)} modelPortfolio={mp}/></Grid>
        return item
    })
 
    const setFilterValue = (filtervalue) =>{
        if(filtervalue===filter)setFilter("")
        else setFilter(filtervalue)
    }
   
    const setSortField = (event, sortObj) => {
        setSort(sortObj)
        handleSortClose()
    }

    return(
                <React.Fragment>

                        <Grid spacing={2}  container direction="row" justifyContent="space-between" alignItems="flex-start" >
                        <Grid item id="storetitle"> 
                            <Title >Portolio Store</Title> 
                        </Grid>
                        <Grid item>
                                <Grid container direction="row" spacing={2} alignItems="center" >
                                {filters}
                                <Grid item>
                                    <Tooltip title="Sort">
                                    <Button variant="contained" endIcon={<SortIcon />}  onClick={handleSortClick}>
                                        Sort
                                    </Button>
                                    </Tooltip>
                                    </Grid>
                                    <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleSortClose}
                                    onClick={handleSortClose}
                                    PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                        },
                                        '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                        },
                                    },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >

                                {
                                    sortOptions.map( (option) =>{
                                        return  <MenuItem key={option.key} onClick={(event) => setSortField(event,option) }>{option.label}</MenuItem>
                                    })
                                }
                                </Menu>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        
                        <Grid spacing={2} sx={{p:1}} container direction="row" alignItems="stretch">
                         {data}
                        </Grid>
                    
                </React.Fragment>
    )
        
}