import { Alert, Button, CircularProgress, Grid, Link, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { InvestorAccountContext } from "../InvestorAccounts";
import BannerAlert from "../../common/ui/BannerAlert"
import Verify from "./Verify";

export default function VerifyForm({investorID,handleError,handleSuccess}){

    const [isVerifying, setIsVerifying] = useState(false)
    const [code, setCode] = useState()
    const {resendVerification,verifyCode} = useContext(InvestorAccountContext)
   

   

    const verify = () => {
        setIsVerifying(true)
        verifyCode(investorID,code).then( () => {
            setIsVerifying(false)
            handleSuccess()
        })
        .catch(err =>{
            console.log("Error", err)
            setIsVerifying(false)
            handleError(<Alert severity="error">{err.message}</Alert>)
        })
    }

    const resendCode = () => {
        resendVerification(investorID)
        .then( () => {
            handleError(
                <div>
                    <BannerAlert severity="success" timed>Code resent</BannerAlert>
                </div>
            )
        })
        .catch(err =>{
            console.log("Error", err)
            handleError(<Alert severity="error">{err.message}</Alert>)
        })
    }

    return(
        <>
        
        <Grid sx={{mb:'30px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
        <Grid item sx={{width: '100%'}} >
                <Typography align="center" variant="h5">Welcome To Equily</Typography>
            </Grid>
            <Grid item sx={{width: '100%'}} >
                <Typography align="center" variant="body1">One final step. We've emailed you a verification code. Enter that here to start taking control of your finances.</Typography>
            </Grid>
        </Grid>
        <Verify handleUpdate={setCode}/>
        <Grid sx={{mb:5}} container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
                <Typography variant="caption"><Link href="#" onClick={e => resendCode()}>Resend Verification Code</Link> </Typography>
            </Grid>
        </Grid>
        
        <Grid sx={{mb:'20px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button variant="contained" onClick={verify} color="primary">
                    {isVerifying && <CircularProgress size={14} />}
                    {!isVerifying && "Verify"}
                </Button>
            </Grid>
        </Grid>
        </>

        


         
         
    )
}