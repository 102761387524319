import {  useMemo } from "react";

import { Paper } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { renderDate, renderDateTime, renderInteger, renderNumber } from "../../common/tables/Table";
import LoadingTableBody from "../../common/tables/LoadingTableBody";




export default function BackTestResultSummary({fetching, backTestResults,handleResultSelect,deleteBackTestResult}){

   
    const handleSelectRun = (row,rowmeta) => {
        handleResultSelect(backTestResults[rowmeta.dataIndex])
    }

    const deleteRow = (rowsDeleted, newData) => {
      if(rowsDeleted && rowsDeleted.data){
         var index = rowsDeleted.data[0].dataIndex 
         deleteBackTestResult(backTestResults[index].resultId)
      }
  }

    
    const tableData = useMemo(() => {  
       
       return backTestResults.map((row) => {
          var newrow;
          if(row.status==="Complete"){
            newrow = {
                resultId: row.resultId,
                runId: row.runId,
                executionDate: new Date(row.executionDate),
                startDate: new Date(row.startDate),
                endDate: new Date(row.endDate),
                thresholdType: row.threshold? row.threshold.thresholdType : "",
                thresholdValue: row.threshold? Number(row.threshold.value.toFixed(2)) : "",
                maxPercentageCostOfTrade: row.costEfficiency? Number(row.costEfficiency.maxPercentageCostOfTrade).toFixed(2) : "",
                initialValue: Number(row.resultStats.initialValue.toFixed(2)),
                finalValue: Number(row.resultStats.finalValue.toFixed(2)),
                profit: Number(row.resultStats.profit.toFixed(2)),
                profitPercentage: Number(row.resultStats.profitPercentage.toFixed(2)),
                numberOfTrades: Number(row.resultStats.numberOfTrades.toFixed(2)),
                tradingCost: Number(row.resultStats.tradingCost.toFixed(2)),
                netProfit: Number(row.resultStats.netProfit.toFixed(2)),
                netProfitPercentage: Number(row.resultStats.netProfitPercentage.toFixed(2)),
              }
              if(row.rar) newrow.rar = Number(row.rar.toFixed(8))
              if(row.volatility) newrow.volatility = Number(row.volatility.toFixed(2))

             
          }else{
            newrow = {
                resultId: row.resultId,
                runId: row.runId,
                executionDate: row.executionDate? new Date(row.executionDate) : "",
                startDate: "",
                endDate: "",
                thresholdType: "",
                thresholdValue: "",
                maxPercentageCostOfTrade: "",
                initialValue: "",
                finalValue: "",
                profit: "",
                profitPercentage:"",
                rar:"",
                volatility:"",
                numberOfTrades: "",
                tradingCost: "",
                netProfit: "",
                netProfitPercentage: "",
            }
          }
            return newrow
          })

    },[backTestResults])




    const tableOptions = {
        filterType: 'checkbox',
        filter: false,
        print: false,
        caseSensitive: false,
        resizableColumns: false,
        selectableRows: 'single',
        draggableColumns: {
          enabled: true,
          transitionTime: 1000
        },
        onRowClick: handleSelectRun,
        onRowsDelete: deleteRow,
        searchProps: {
          spellcheck: "false",
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off"
        },
      }
    //,options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'maxPercentageCostOfTrade') }}
      const columns = [
        { name: 'runId', label: 'Run'},
        { name: 'executionDate', label: 'Execution Date',options: {customBodyRenderLite: (dataIndex) => { return renderDateTime(backTestResults,dataIndex,'executionDate') }}  },
        { name: 'startDate', label: 'Start Date',options: {customBodyRenderLite: (dataIndex) => { return renderDate(backTestResults,dataIndex,'startDate') }} },
        { name: 'endDate', label: 'End Date',options: {customBodyRenderLite: (dataIndex) => { return renderDate(backTestResults,dataIndex,'endDate') }}  },
        { name: 'thresholdType', label: 'Threshold Type'},
        { name: 'thresholdValue', label: 'Threshold Value'},
        { name: 'maxPercentageCostOfTrade', label: 'Max % Trade Cost',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'costEfficiency.maxPercentageCostOfTrade') }}},
        { name: 'initialValue', label: 'Initial Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.initialValue') }}},
        { name: 'finalValue', label: 'Final Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.finalValue') }}},
        { name: 'profit', label: 'Profit',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.profit') }}},
        { name: 'profitPercentage', label: 'Profit Percentage',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.profitPercentage') }}},
        { name: 'rar', label: 'RAR',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'rar') }}},
        { name: 'volatility', label: 'Std. Dev.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'volatility') }}},
        { name: 'numberOfTrades', label: 'No. of Trades',options: {customBodyRenderLite: (dataIndex) => { return renderInteger(backTestResults,dataIndex,'resultStats.numberOfTrades') }}},
        { name: 'tradingCost', label: 'Trading Cost',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.tradingCost') }}},
        { name: 'netProfit', label: 'Net Profit',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.netProfit') }} },
        { name: 'netProfitPercentage', label: 'Net Profit Per.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(backTestResults,dataIndex,'resultStats.netProfitPercentage') }}},
      ]

    const BodyComponent = (props) => <LoadingTableBody loading={!backTestResults.length>0 || fetching} {...props} />
 
    return(
        <Paper>
            <MUIDataTable
                onRowSelected={handleSelectRun}
                data={tableData}
                columns={columns}
                options={tableOptions} 
                components={{ TableBody: BodyComponent }}
            />
        </Paper>
    )
}


