import { Box } from "@mui/material";



export default function FormColumn(props){


    return(

        <Box
        component="form"
        display="flex" flexDirection="column"
        sx={{
            '& > :not(style)': { m:1, width: props.width },
        }}
        noValidate
        autoComplete="off"
        >
            {props.children}
        </Box>
    )

}