import { ListItem, ListItemIcon } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add'
import  AddBackTest  from "../AddBackTest";
import { EquilyListItemText } from "../../common/Link";
export function AddBackTestMenuItem({addBackTest,benchmarks}){

  
    const [open,setOpen] = useState(false)
  
    
    const handleAddBackTest = (backtest) => {
        addBackTest(backtest)
        setOpen(false)
    }
    
    return(
        <React.Fragment>
            <ListItem key='AddBackTest' id='AddBackTest' button sx={{ pl: 4 }}>
            <ListItemIcon>
                <AddIcon />
            </ListItemIcon>
            <EquilyListItemText key='AddBackTestText' primary='Add Test' onClick={() => setOpen(true)}/>
            </ListItem>
            <AddBackTest open={open} handleClose={()=> setOpen(false)} handleSave={handleAddBackTest} benchmarks={benchmarks}></AddBackTest>
         </React.Fragment>
    )

}