

import {ListItemLink} from '../../common/Link'
import StoreIcon from '@mui/icons-material/Store';
export function PortfolioStoreMenu(props){

    
    return(
        <ListItemLink key="portfoliostore" to="/modelportfoliostore" primary="Portfolio Store" icon={<StoreIcon />} />
    )

}