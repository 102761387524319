import React, { useEffect,useState} from 'react'

import {  Paper } from '@mui/material'
import BackTestSummaryChartNVD3 from '../../backtests/details/BackTestSummaryChartNVD3'

export default function ModelPortfolioPerformance(props){


    const modelPortfolio = props.modelPortfolio
    
    const [portfolioData, setPortfolioData] = useState([])


    useEffect( () => {

        if(modelPortfolio && modelPortfolio.backTestResult){
            var data = {
                portfolioData: modelPortfolio.backTestResult.portfolioHistory,
                result: modelPortfolio.backTestResult
            }
            setPortfolioData([data])
        }

    },[modelPortfolio])

    return(
                <React.Fragment>
                    <Paper>
                        <BackTestSummaryChartNVD3 backTestResultData={portfolioData}/>
                    </Paper>
                </React.Fragment>
    )
        
}