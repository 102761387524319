import { Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react"
import { EquilyContext } from "../common/Context";
import PortfolioCardItem from "./PortfolioCardItem";



const MyPortfoliosCard = (props) => {

    const {benchmarks, portfolios} = useContext(EquilyContext);
    const [items, setItems] = useState([])

    useEffect( () => {

        if(benchmarks && benchmarks.length>0 && portfolios){

            const list = benchmarks.map( (bm) => {
                const portfolioId = bm.portfolioId
                const p = portfolios.find( port => port.portfolioId===portfolioId)
                return <PortfolioCardItem key={bm.benchmarkName} benchmark={bm} portfolio={p}/>
            })
            setItems(list)
        }else{
            const noItem =  <Grid key="noItemPortolio" container justifyContent="center" alignItems="center" >
            <Grid item >
              <CircularProgress />
            </Grid>
          </Grid>
          
            setItems([noItem])
        }

    },[benchmarks, portfolios])
    

    return (

        <React.Fragment>
              <Card sx={{
                   borderRadius: '16px'
                }}>
                <CardContent>
                <Grid item xs={12}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6">My Portfolios</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        <Box sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
                           {items}
                        </Box>
                        </Grid>
                </CardContent>
                </Card>
        </React.Fragment>
    )
}

export default MyPortfoliosCard