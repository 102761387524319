import {useEffect, useState, useContext} from "react";

import List from '@mui/material/List'

import {NestedParentListItemLink} from '../../common/Link'
import {EquilyContext} from '../../common/Context'
import { AddRebalanceTrackerMenuItem } from "./AddRebalanceTrackerMenuItem";
import { RebalanceTrackerMenuItem } from "./RebalanceTrackerMenuItem";

import BalanceIcon from '@mui/icons-material/Balance';

export function RebalanceMenu(props){

    const {rebalanceTrackers} = useContext(EquilyContext)
    const [trackeritems, setTrackerItems] = useState(null)

    useEffect(() => {

       if(rebalanceTrackers){
            const items = rebalanceTrackers.map((t,index) =>
                <RebalanceTrackerMenuItem key={index} tracker={t}/>
            )
            const addTracker = <AddRebalanceTrackerMenuItem key="AddTracker"/>
            items.push(addTracker)

            const itemlist = 
            <List component="div" disablePadding>
                {items}
            </List>
            setTrackerItems(itemlist)
       }
      }, [rebalanceTrackers]);
    
    return(
        <NestedParentListItemLink sx={{backgroundColor: 'pantone.pantone7546Light'}} primary="Tracking" icon={<BalanceIcon />} children={trackeritems} />
    )

}