import { Paper, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, {useContext,useEffect,useState,useCallback} from "react";
import { EquilyContext } from "../common/Context";
import { nonSelectTableOptions, renderCurrencyNumber, renderNumber } from "../common/tables/Table";



export default function BenchmarkData(props){

  const {benchmark,portfolios} = useContext(EquilyContext);
       
  const [data, setData] = useState([])
  const [portfolio, setPortfolio] = useState([])

const colourWrap = (data,dataIndex,attr,renderFunc) => {
    var sxValue = {}
    if(data[dataIndex][attr]){
      var value = data[dataIndex][attr]
      if(value<0.0){
        sxValue = {color: '#FE2E2E'}
      }else{
        sxValue = {color: '#21610B'}
      } 
    }
    return(
      <Typography variant="body2" sx={sxValue}>{renderFunc}</Typography>
    )
  }

  const columns = [
    { name: 'investmentSymbol', label: 'Investment',},
    { name: 'name', label: 'Allocation',},
    { name: 'targetPercentage', label: 'Target %', options: {customBodyRenderLite: (dataIndex) => {return renderNumber(data,dataIndex,'targetPercentage')}}},
    { name: 'actualPercentage', label: 'Actual %', options: {customBodyRenderLite: (dataIndex) => {return renderNumber(data,dataIndex,'actualPercentage')}}},
    { name: 'absolutePercentage', label: 'Absolute % Diff.', options: {customBodyRenderLite: (dataIndex) => {return colourWrap(data,dataIndex,'relativePercentage',renderNumber(data,dataIndex,'absolutePercentage'))}}},
    { name: 'relativePercentage', label: 'Relative % Diff.', options: {customBodyRenderLite: (dataIndex) => {return colourWrap(data,dataIndex,'relativePercentage',renderNumber(data,dataIndex,'relativePercentage'))}}},
    { name: 'targetValue', label: 'Target Value',options: {customBodyRenderLite: (dataIndex) => {return renderCurrencyNumber(data,dataIndex,'targetValue',portfolio.portfolioCurrencyCode)}}},
    { name: 'actualValue', label: 'Actual Value',options: {customBodyRenderLite: (dataIndex) => {return renderCurrencyNumber(data,dataIndex,'actualValue',portfolio.portfolioCurrencyCode)}}},
    { name: 'valueDifference', label: 'Value Diff',options: {customBodyRenderLite: (dataIndex) => {return colourWrap(data,dataIndex,'valueDifference',renderNumber(data,dataIndex,'valueDifference',portfolio.portfolioCurrencyCode))}}},
   
  ]


  const buildBenchmarkTableData = useCallback((benchmarkAllocation,portfolio,tabledata) => {
    
    if(benchmarkAllocation){ 
      
        const investmentIds = benchmarkAllocation.investmentIds
        const cashIds = benchmarkAllocation.cashIds
        const containsCash = (cashIds && cashIds.length>0)
        const containsInvestments = (investmentIds && investmentIds.length>0)
        
        if(containsCash || containsInvestments){
          var node = {}  
          node.name = benchmarkAllocation.allocation.allocationName
          node.weighting = benchmarkAllocation.allocation.modelweighting
          const stats = benchmarkAllocation.benchmarkAllocationStats
         
          if(investmentIds){
            investmentIds.forEach( (id) => {
              
                var investment = portfolio.investments.find( inv => inv.investmentId===id)
                if(investment){
                   node.investmentSymbol = investment.stock.symbol
                }
            })
          }
          if(cashIds){
            cashIds.forEach( (id) => {
                  var cash = portfolio.cash.find( inv => inv.cashId===id)
                  if(cash){
                    node.investmentSymbol = (cash.currencyCode)
                  }
              })
          }
          node.targetValue = stats.targetValue
          node.actualValue = stats.actualValue
          node.valueDifference = stats.actualValue - stats.targetValue
          node.actualPercentage = stats.actualPercentage
          node.targetPercentage = stats.targetPercentage
          node.relativePercentage = (node.valueDifference/node.targetValue)*100.0
          node.absolutePercentage = node.actualPercentage-node.targetPercentage
          tabledata.push(node)
        }

        if(benchmarkAllocation.childBenchmarkAllocations){
            benchmarkAllocation.childBenchmarkAllocations.forEach( (child) => {
                buildBenchmarkTableData(child,portfolio,tabledata)
            })
        }
       
      }
      
  },[])



  useEffect(() => {
    if(benchmark){
        if(benchmark.benchmarkAllocation){
            var portfolio = portfolios.find( (p) => p.portfolioId===benchmark.portfolioId)
            setPortfolio(portfolio)
            var tabledata = []
            buildBenchmarkTableData(benchmark.benchmarkAllocation,portfolio,tabledata)
            setData(tabledata)
         }
    }       
  }, [benchmark,portfolios,buildBenchmarkTableData]);

   
    return(

    <React.Fragment>
        <Paper>
                <MUIDataTable data={data} columns={columns} options={nonSelectTableOptions}/> 
        </Paper>
        
    </React.Fragment>


    )

}