import React, {useState,useEffect} from "react";

import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider';
import {Stack, Grid} from '@mui/material'
import BenchmarkMap from "./BenchmarkMap";
import BenchmarkDeleteMap from "./BenchmarkDeleteMap";



export default function BenchmarkEditPanel(props){
    
   
    
    const node = props.benchmarkAllocation
   
    
    const [addable, setAddable] = useState(false)
    const [deleteable, setDeleteable] = useState(false)

    
    useEffect(() => {
          if(node){
            if( (node.children && node.children.length===0) && (node.cash && node.cash.length===0)  && (node.investments && node.investments.length===0) ){
                setAddable(true)
            }else{
                setAddable(false)
            }   
            if((node.cash && node.cash.length>0) || (node.investments && node.investments.length>0)){
                setDeleteable(true) 
            }else{
                setDeleteable(false)
            }
        }
    },[node])
    
   
  
   
    return(
        <React.Fragment>
            
        <Stack>
        
          
                    <Typography variant="h6" color="primary" gutterBottom>
                       {node.name}
                    </Typography>
            <Grid container direction="row" justifyContent="space-between">
                <Typography variant="body1" color="primary" gutterBottom>
                Weighting: {node.weighting} %
                </Typography>
                <Typography variant="body1" color="primary" gutterBottom>
                Model Weighting: {node.modelweighting? node.modelweighting.toFixed(2) : ""} %
                </Typography>
            </Grid>
             <Divider></Divider>
             <Stack sx={{ mt: 1 }}>
             {addable ? <BenchmarkMap actionActive={props.actionActive} addMapping={props.addMapping} investments={props.investments} benchmarkAllocation={props.benchmarkAllocation}></BenchmarkMap> : <div></div>}
             {deleteable ? <BenchmarkDeleteMap actionActive={props.actionActive} deleteMapping={props.deleteMapping} portfolio={props.portfolio} benchmarkAllocation={props.benchmarkAllocation}></BenchmarkDeleteMap> : <div></div>}
             </Stack>
        </Stack>
        </React.Fragment>
    )

}