import React, {useState, useContext,useEffect} from 'react';


import { EquilyContext } from '../common/Context';
import MyAccountSocial from './MyAccountSocial';
import MyAccountEquily from './MyAccountEquily';

export default function MyAccount(props){


    const {investor} = useContext(EquilyContext);
        
    const [social,setSocial] = useState(true)

    useEffect( () =>{
        if(investor){
            const id = investor.investorName
            if(!id.startsWith("google") && !id.startsWith("facebook")){
                setSocial(false)
            }
        }
    },[investor])

    if(social) return <MyAccountSocial investor={investor}/>
    else return <MyAccountEquily/>

}