import { Box, Grid, Paper, Typography } from "@mui/material"
import MUIDataTable from "mui-datatables"
import React, { } from "react";

import { nonSelectTableOptions,renderNumber, renderCurrencyNumber, renderDate} from "../../common/tables/Table";

export default function BackTestResultRebalancing({backTestResult}){

     
    const columns = [
      { name: 'allocationName', label: 'Allocation'},
      { name: 'benchmarkDate', label: 'Benchmark Date',options: {customBodyRenderLite: (dataIndex) => { return renderDate(data,dataIndex,'benchmarkDate') }}},
       { name: 'type', label: 'Type'},
       { name: 'symbol', label: 'Stock' },
       { name: 'price', label: 'Price',options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'price',data[dataIndex].stockCurrencyCode) }}},
       { name: 'currencyCode', label: 'Cash Currency'},
       { name: 'value', label: 'Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'value') }}},
       { name: 'volume', label: 'Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'volume') }}},
       { name: 'actualValue', label: 'Actual Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'actualValue') }}},
       { name: 'targetValue', label: 'Target Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'targetValue') }}},
       { name: 'actualPercentage', label: 'Actual %',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'actualPercentage') }}},
       { name: 'targetPercentage', label: 'Target %',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'targetPercentage') }}}, 
     ]
  
  
     
      const data = backTestResult.details.map((row) => {
          var newrow = {
            allocationName: row.allocationName,
            benchmarkDate: new Date(row.benchmarkDate),
            type: row.type,
            symbol: row.stock?.symbol,
            price: row.stock?.price,
            stockCurrencyCode: row.stock?.currencyCode,
            currencyCode: row.currencyCode,
            value: row.value,
            volume: row.volume,
            actualValue: row.actualValue,
            targetValue: row.targetValue,
            actualPercentage: row.actualPercentage,
            targetPercentage: row.targetPercentage,
          }
          return newrow
        })
      
    return(
        <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{'paddingBottom': '1rem'}}>
               <Grid item>
              
                <Typography  variant="h7" color="secondary" gutterBottom>
                    Date Limiting Stock - {backTestResult.limitingStockSymbol}
                </Typography> 
                      
               </Grid>
          </Grid>
        <Box>
        <Paper>
        <MUIDataTable
            data={data}
            columns={columns}
            options={nonSelectTableOptions}
        />
        </Paper>
        </Box>
        </>
   
    )
}