import { Grid} from '@mui/material'
import React, { useState } from 'react'

import { ModelPortfolioStoreSummary } from './ModelPortfolioStoreSummary';
import { ModelPortfolioStoreDetail } from './ModelPortfolioStoreDetail';


export function ModelPortfolioStore(props){

    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState()
   
    return(
     
        <React.Fragment>
          <Grid container direction="column" spacing={2}>
                <Grid item>
                  {selectedModelPortfolio? <ModelPortfolioStoreDetail modelPortfolio={selectedModelPortfolio} onClose={() => setSelectedModelPortfolio()}/> : <ModelPortfolioStoreSummary setSelectedModelPortfolio={setSelectedModelPortfolio} />}
               </Grid>
          </Grid>
        </React.Fragment>
    )

}