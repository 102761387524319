
import React, { useEffect, useState} from 'react'

import {Grid,Typography, Card, CardContent, CardActions, Collapse, IconButton, Tooltip, Box} from '@mui/material'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline'
import BalanceIcon from '@mui/icons-material/Balance'
import TimelineIcon from '@mui/icons-material/Timeline'
import SummaryViewCardARR from './SummaryViewCardARR'
import SummaryViewCardAllocation from './SummaryViewCardAllocation'
import SummaryViewCardVolatility from './SummaryViewCardVolatility'
import SummaryViewCardRisk from './SummaryViewCardRisk'
import {badColour, goodColour, averageColour} from '../../common/ui/Colours'
import { DecisionButton } from '../../common/ui/Buttons'


export default function SummaryViewCardItem(props){

    const mp = props.modelPortfolio
    const selectModelPortfolio = props.selectModelPortfolio

    const [expanded, setExpanded] = useState(false)
    const [iconStyles, setIconStyles] = useState({})
    const [riskRatings, setRiskRatings] = useState({})

    useEffect( () => {     
      if(mp){

        var styles = {rar: {color: '#9B3259'}, volatility: {color: '#9B3259'} }
        var ratings = {}
        const rar  = mp.rar
        if(rar<1.0){
          ratings.rar = "Poor"
          styles.rar = {color: badColour}
        }else if(rar>=1.0 && rar<=2.0){
          ratings.rar = "Good"
          styles.rar = {color: averageColour}
        }else if(rar>=2.0 && rar<=3.0){
          ratings.rar = "Very Good"
          styles.rar = {color: goodColour}
        }else if(rar>=3.0){
          ratings.rar = "Excellent"
          styles.rar = {color: goodColour}
        }
        const vol = mp.volatility
        if(vol<10.0){
          ratings.volatility = "Very Low"
          styles.volatility = {color: goodColour}
        }else if(vol>=10.0 && vol<=12.0){
          ratings.volatility = "Low"
          styles.volatility = {color: goodColour}
        }else if(vol>=12.0 && vol<=16.0){
          ratings.volatility = "Medium"
          styles.volatility = {color: averageColour}
        }else if(vol>=16.0){
          ratings.volatility = "High"
          styles.volatility = {color: badColour}
        }
        setRiskRatings(ratings)
        setIconStyles(styles)
      }
    },[mp])

    
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
   
   

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
      })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      }))




    return(
      <Grid container component={Card} variant="outlined"  >

          <Grid container item direction="row" justifyContent="space-between" alignItems="stretch" sx={{padding: 1}}>
            <Grid item>
            <Box
                      component="img"
                      sx={{'maxWidth': '100%', height: 'auto'}}
                      alt="Power up your financial future"
                      src="./Equily_Logo_Colour_Smallest.png"
                    />
                      
            </Grid>
            <Grid item>
            <DecisionButton size="small" variant="contained" onClick={selectModelPortfolio}>Detail</DecisionButton>
            </Grid>
          </Grid>
        
          <Grid container item sx={{padding: 1}}>
                    <Typography variant="h4" >
                    {mp.name}
                    </Typography>
          </Grid>
        
          <Grid container item direction="row" justifyContent="space-between" spacing={1} sx={{padding: 1}}>
                    <Grid item>
                      <Tooltip title={<SummaryViewCardAllocation modelPortfolio={mp}/>}>
                        
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <PieChartOutlineIcon/>
                          </Grid>
                          <Grid item>
                              <Typography>
                              {mp.investments.length}
                              </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<SummaryViewCardRisk  modelPortfolio={mp}/>}>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <BalanceIcon sx={iconStyles.rar}/>
                          </Grid>
                          <Grid item>
                              <Typography  sx={iconStyles.rar} >
                              {riskRatings.rar}
                              </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<SummaryViewCardVolatility  modelPortfolio={mp}/>}>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                          <TimelineIcon sx={iconStyles.volatility}/>
                          </Grid>
                          <Grid item>
                              <Typography  sx={iconStyles.volatility} >
                              {riskRatings.volatility}
                              </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    </Grid>
          </Grid>
          <Grid  container item direction="row" justifyContent="space-between" spacing={1} sx={{paddingLeft: 1, paddingRight: 1}}>
                  <Grid item><Typography variant="body1">Portfolio Currency</Typography></Grid>  
                  <Grid item><Typography variant="body1">{mp.modelCurrencyCode}</Typography></Grid>  
          </Grid>
          <Grid container direction="column" item sx={{padding: 1}}>
                   <SummaryViewCardARR modelPortfolio={mp}/>
          </Grid>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography  variant="body1" paragraph>
                {mp.description}
              </Typography>
            </CardContent>
          </Collapse>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
      </Grid>
    
    )

}