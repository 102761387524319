
import { useNavigate  } from "react-router-dom";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import AddIcon from '@mui/icons-material/Add'
import { EquilyListItemText } from "../../common/Link";


export function AddPortfolioMenuItem(props){
    
    
    let navigate = useNavigate ();

    const addPortfolio = () => {
        navigate("/portfolios/addPortfolio");
    }


    return(
        
            <ListItem key='AddPortfolio' id='AddPortfolio' button sx={{ pl: 4}}>
                <ListItemIcon>
                     <AddIcon />
                </ListItemIcon>
                <EquilyListItemText key='AddPortfolioText' primary='Add Portfolio' onClick={addPortfolio}/>
            </ListItem>
       
    )

}