import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { getStockHistory } from "../../common/services/api/StockAPI"
import { getSession } from "../../auth/cognito/Cognito"

import NVD3Chart from 'react-nvd3'
import * as d3 from 'd3'
import { infographicColour } from "../../common/ui/Colours";
import '../BackTestNVD3.css'
import BannerAlert from "../../common/ui/BannerAlert"


export default function BackTestResultPerformanceNVD3({backTestResultActions,backTestResult,handleStatus}){


    const [selectedStock,setSelectedStock] = useState("")
    const [stockCharts, setStockCharts] = useState([])
    const [chartItem,setChartItem] = useState(<DefaultMessage/>)


   
    const stockMenuItems = useMemo(() => {
        let items = []
        backTestResultActions.forEach( action => {
            var contains = items.some( item => item.props.value===action.stock.symbol)
            if(!contains){
            items.push( <MenuItem key={action.stock.symbol} value={action.stock.symbol}>{action.stock.symbol}</MenuItem>)
            } 
        })
        return items
    },[backTestResultActions])
    

    const buildChartDataForStock = (stock,history) => {
        var priceMap = new Map()    
        if(history){
          var stockLine = {key: history.stockSymbol + " Adj. Close Price"}
          var stockData = history.adjustedPriceHistoryData.map((hist) => {
              var dataItem = { x: hist.recordDate, y: hist.adjustedClose}
              priceMap.set(hist.recordDate,hist.adjustedClose)
              return dataItem
          })
          stockLine.values= stockData 
          const c = infographicColour(history.stockSymbol)
          stockLine.color = c
        }
        var sellActionLine = {key: stock + " sell actions"}
        var sellStockActions = backTestResultActions.filter( (a) => (a.stock.symbol===stock && a.actionType.toUpperCase()==="SELL"))
        var sellActionData = sellStockActions.map( act => {
          var item = { x: act.actionDate, y: priceMap.get(act.actionDate)}
          return item
        })
        
        sellActionLine.values = sellActionData
        sellActionLine.color = "red"
       
        var buyActionLine = {key: stock + " buy actions"}
        var buyStockActions = backTestResultActions.filter( (a) => (a.stock.symbol===stock && a.actionType.toUpperCase()==="BUY"))
        var buyActionData = buyStockActions.map( act => {
          var item = { x: act.actionDate, y: priceMap.get(act.actionDate)}
          return item
        })
        
        buyActionLine.values = buyActionData
        buyActionLine.color = "blue"
      
        return [stockLine,sellActionLine,buyActionLine]
    }



    const handleStockSelect = (stock) => {
        setSelectedStock(stock)
        handleStatus()
        // chart will be {stock: SYMBOL, chartData: chartData}
        let chart = stockCharts.find( (chart) => {
            return chart.stock===stock
        })
        if(chart){
            // had the chart before to render
            setChartItem(<StockChart data={chart.chartData}/>)
        }else{
            // need to get the chart
           
            setChartItem(<Loader/>)
            getStockHistory(getSession,stock).then( (historydata) => {
                let chartData = buildChartDataForStock(stock, historydata)
                let stockchart = {stock: stock, chartData: chartData}
                setStockCharts([...stockCharts,stockchart])
                setChartItem(<StockChart data={stockchart.chartData}/>)
            }).catch((error) => {
                console.log("There was an error " + error)
                setChartItem(<DefaultMessage/>)
                handleStatus(<BannerAlert severity="error" timed>Could not load stock history</BannerAlert>)
            })
            
        }
       
    }  

    return(
       <Grid container spacing={2} direction="column">
       
       <Grid item>
       
          <FormControl fullWidth>
          <InputLabel id="Investment">Investment</InputLabel>
          <Select
              labelId="investment-select-label"
              id="investment-select"
              value={selectedStock}
              label="Investment"
              displayEmpty={false}
              onChange={e => {handleStockSelect(e.target.value)}}
            >
           
              {stockMenuItems}

          </Select>
           
          </FormControl>
        
        </Grid>
        
        <Grid item id="chart">
            {chartItem}
        </Grid>
        </Grid>
    )
}

const setBlobs = (chart) => {
    d3.select('chart')
}

const StockChart = ({data}) =>{
    return(
        <NVD3Chart renderEnd={setBlobs} sx={{p:'1rem'}} id="line" showValues={true} type="lineWithFocusChart" datum={data} x="x" y="y"
        xAxis={{
          tickFormat: (d) => new Date(d).toLocaleDateString(),
          margin: 20,
        }}
        x2Axis={{
          tickFormat: (d) => new Date(d).toLocaleDateString(),
        }}
        height="500"
        margin={{top: 10, right: 50, bottom: 10, left: 90}}
        pointSize="50"
        />
    )
}

const Loader = () =>{
    return(
        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
            <Typography>Loading History Data....</Typography>
            </Grid>
            <Grid>
            <CircularProgress/>
            </Grid>

        </Grid>
    )
}

const DefaultMessage = () =>{
    return(
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
        <Grid item>
            <Typography>Select an investment to chart the performance</Typography>
        </Grid>
        </Grid> 
    )
}