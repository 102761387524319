import {  useEffect, useMemo, useState } from "react"


import NVD3Chart from 'react-nvd3'
import { infographicColour } from "../../common/ui/Colours";
import Loader from "../../common/ui/Loader";
import { Paper } from "@mui/material";

export default function BackTestVolatilityChart({backTestResultData}){
    
    const [chartData, setChartData] = useState([])

    
    useEffect( () => {
        if(backTestResultData.length>0){
           
            let runs = []
            backTestResultData.forEach( (pdata) => {
                const result = pdata.result  
                const portfolioHistory = pdata.portfolioData
                if(portfolioHistory[0].portfolioVolatility){
            
                var run = { key: "Run " + result.runId}
                if(portfolioHistory){
                    var runData = portfolioHistory.map((hist) => {
                        const portfolioVolatility = parseFloat((hist.portfolioVolatility*100.0).toFixed(2))
                        var dataItem = { x: hist.portfolioDate, y: portfolioVolatility}
                        return dataItem
                    })
                    run.values= runData 
                    const c = infographicColour(result.runId)
                    run.color = c
                }
                runs.push(run)
                }
            })
                setChartData(runs)
        }
    },[backTestResultData])





    

    const item = useMemo( ()=>{
        if(chartData.length===0){
             return <Loader/>
        }else{
            return <NVD3Chart sx={{p:'1rem'}} color={infographicColour("test")} id="line" type="lineChart" datum={chartData} x="x" y="y"
            xAxis={{
            tickFormat: (d) => new Date(d).toLocaleDateString(),
            margin: 20,
            }}
            x2Axis={{
            tickFormat: (d) => new Date(d).toLocaleDateString(),
            }}
            height="500"
            margin={{top: 10, right: 50, bottom: 50, left: 50}}
            />
        }

   },[chartData])
   
        return(
          <Paper>
           {item}
         </Paper>
        )
    

}