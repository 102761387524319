import { Button, Grid, Link, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { InvestorAccountContext } from "../InvestorAccounts"
import BannerAlert from "../../common/ui/BannerAlert"
import Verify from "./Verify"
import { useNavigate  } from "react-router-dom";

//eslint-disable-next-line
const PASSWORD_REGEX  = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/)

export default function ForgottenPasswordCard({ initialEmail,handleStatus}){

    const [email, setEmail] = useState(initialEmail)
    const [password, setPassword] = useState("")
    const [code, setCode] = useState("")
    const {sendForgottenCode,forgotPassword} = useContext(InvestorAccountContext)
    const [isVerify, setIsVerify] = useState(false)
    const [isResetting, setIsResetting] = useState(false)
    const [isComplete,setIsComplete] = useState(false)
    const [validPassword, setValidPassword] = useState(false)
    const navigate = useNavigate ();
    
    const login = () => {
        navigate("/") 
    }

    const updatePassword = (value) => {
        console.log(PASSWORD_REGEX.test(value))
        setValidPassword(PASSWORD_REGEX.test(value))
        setPassword(value)
        
    }
    const resetPassword = () => {
             handleStatus(null)
            
                       
                setIsResetting(true)
                forgotPassword(email, code, password).then( ()=>{
                    setIsComplete(true)
                    setIsResetting(false)
                }).catch(err => {
                    console.log("ERRRR")
                    console.log(err)
                    if (err instanceof Error) {
                        handleStatus(<BannerAlert severity="error" timed>Could not reset password: {err.message}</BannerAlert>)
                    }else{
                        handleStatus(<BannerAlert severity="error" timed>Could not reset password: {err}</BannerAlert>)
                
                    }
                    setIsResetting(false)
                })
             
            
    }


    const sendCode = () =>{
        handleStatus(null)
        sendForgottenCode(email).then( () => {
            handleStatus(<BannerAlert type="success" timed>Verification Code Sent</BannerAlert>)
            setIsVerify(true)
        })
    }


    let item = <SendContent email={email} setEmail={setEmail} sendCode={sendCode}/>
    if(isVerify) item= <VerifyContent setEmail={setEmail} validPassword={validPassword} isResetting={isResetting} resetPassword={resetPassword} code={code} setCode={setCode} password={password} setPassword={updatePassword} email={email} sendCode={sendCode} />
    if(isComplete) item = <Success login={login}></Success>
    return(
        <>
            {item}
        </>
    )


}

const Success = ({login}) =>{
    return(
        <>
       <Grid sx={{mt: '10px', mb:'20px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
       <Grid item sx={{width: '100%'}} >
               <Typography align="center" variant="h5">Password Reset</Typography>
           </Grid>
           <Grid item sx={{width: '100%'}} >
               <Typography align="left" variant="body1">
                    Your password has been updated
                </Typography>
           </Grid>
       </Grid>

      
      <Grid sx={{mb:'20px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
           <Grid item>
               <Button variant="contained" onClick={login} color="primary">
                   Login
               </Button>
           </Grid>
       </Grid>
       </>

    )
}

const VerifyContent = ({validPassword, isResetting,password, setPassword,email,setEmail, sendCode,setCode, code,resetPassword}) => {
   
    
    
    return(
        <>
         <Grid sx={{mt: '1px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
        <Grid item sx={{width: '100%'}} >
                <Typography align="center" variant="h5">Password Reset</Typography>
            </Grid>
            <Grid item sx={{width: '100%'}} >
                <Typography align="left" variant="body1">Select a new password and update using the verification code we've sent to your email.
                 </Typography>
            </Grid>
        </Grid>

        <Grid sx={{mb:'15px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
           <Grid item sx={{width: '100%'}}>
               <TextField   variant="standard" fullWidth value={email} id="investorid" label="Email" onChange={e => setEmail(e.target.value)} />  
            </Grid>
        </Grid>
        <Grid container sx={{}}>
            <Verify handleUpdate={setCode}/>
        </Grid>
        
        <Grid sx={{mb:1}} container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
                <Typography variant="caption"><Link href="#" onClick={e => sendCode()}>Resend Verification Code</Link> </Typography>
            </Grid>
        </Grid>
        <Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item sx={{width: '100%'}}>
             <TextField  autofocus  variant="standard" fullWidth value={password} onChange={e => setPassword(e.target.value)} id="password" label="Password" type="password"  />
            </Grid>
        </Grid>
        <Grid sx={{mb:1}} container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
                <Typography variant="caption">Password must be greater than 8 characters in length and contain an uppercase letter, special character number </Typography>
            </Grid>
        </Grid>
      
      
        <Grid sx={{mb:'20px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button disabled={!validPassword || isResetting || password==="" || code.length!==6} variant="contained" onClick={resetPassword} color="primary">
                    Reset Password
                </Button>
            </Grid>
        </Grid>
        </>
        
    )
}

const SendContent = ({email,setEmail,sendCode}) => {
    return(
        <>
        <Grid sx={{mt: '10px', mb:'20px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
        <Grid item sx={{width: '100%'}} >
                <Typography align="center" variant="h5">Password Reset</Typography>
            </Grid>
            <Grid item sx={{width: '100%'}} >
                <Typography align="left" variant="body1">To reset your password, enter your email below and submit. An email will be sent to you with a verification code to complete the process
                 </Typography>
            </Grid>
        </Grid>

        <Grid sx={{mb:5}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
           <Grid item sx={{width: '100%'}}>
               <TextField   variant="standard" fullWidth value={email} onChange={e => setEmail(e.target.value)} id="investorid" label="Email" />  
               </Grid>
        </Grid>

      
        
        <Grid sx={{mb:'20px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button variant="contained" onClick={sendCode} color="primary">
                    Send Code
                </Button>
            </Grid>
        </Grid>
        </>
    )
}