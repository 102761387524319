import React, {useState,useContext,useEffect} from "react";

import {Button,FormControl,CircularProgress} from '@mui/material'
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


import FormColumn from '../../common/ui/FormColumn'
import { CallAPI } from '../../common/services/api/CallAPI'
import {InvestorAccountContext} from '../../auth/InvestorAccounts'


export default function EditCash(props){

    const cash = props.cash

    const [amount,setAmount] = useState()
    const [error, setError] = useState()
    const [adding, setAdding] = useState(false)
    const {getSession} = useContext(InvestorAccountContext)

    const handleClose = () => {
        setAdding(false)
        setAmount()
        setError()
        props.closeForm()
     }


    const updateCash = () => {
        setAdding(true)
        const cashReq = [{
            "currencyCode": cash.currency, 
            "amount": parseFloat(amount),
        }]
        getSession().then( ({headers}) => {
          headers['Content-Type'] =  'application/json'
          CallAPI('/engine/portfolios/'+props.portfolioName+'/cash/'+cash.cashId,{
            method: 'POST',
            headers,
            body: JSON.stringify(cashReq)
          })
          .then( data =>{
            props.setInvestorPortfolio(data)
            handleClose()
          })
          .catch((error) => {
            console.log("There was an error " + error)
            setError(<Alert severity="error">Could not update Cash</Alert>)
            setAdding(false)
          })
        })
      
        
    }

    const deleteCash = () => {

      setAdding(true)
      
      getSession().then( ({headers}) => {
        CallAPI('/engine/portfolios/'+props.portfolioName+'/cash/'+cash.cashId,{
          method: 'DELETE',
          headers,
        })
        .then( data =>{
          props.setInvestorPortfolio(data)
          props.refreshBenchmarks()
          handleClose()
        })
        .catch((error) => {
          console.log("There was an error " + error)
          setError(<Alert severity="error">Could not delete Cash</Alert>)
          setAdding(false)
        })
      })
    
    }

    useEffect(() => {
      if(cash){
        setAmount(cash.amount)
      }
     },[cash])
     
    return(

        <Dialog  open={props.open} onClose={handleClose} aria-labelledby="Edit Cash">
        <DialogTitle id="add-cash-title">{"Edit Cash in Portfolio"}</DialogTitle>
        {error}
        <DialogContent>
            <FormColumn>
                <FormControl variant="outlined" > 
                    <TextField autoFocus value={amount} onChange={ e=>setAmount(e.target.value)} margin="dense" id="amount" label="Amount"  type="number"/>
                </FormControl>
                <FormControl variant="outlined" > 
                    <TextField autoFocus value={cash? cash.currency  :""} disabled={true} margin="dense" id="currency" label="Currency Code" type="text" />
                </FormControl>
           </FormColumn>
        </DialogContent>
       
        <DialogActions>
          <Button onClick={updateCash} color="primary" autoFocus>
          {adding && <CircularProgress size={14} />}
                    {!adding && "Update"}
          </Button>
          <Button onClick={deleteCash} color="primary" autoFocus>
          {adding && <CircularProgress size={14} />}
                    {!adding && "Delete"}
          </Button>
        </DialogActions>
      </Dialog>


    )

}