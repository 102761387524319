
import { Box } from "@mui/material";
import { DiscoverButton, DiscoverButtonSelected } from "../ui/Buttons";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
          <Box sx={{ p: 3 }}>
            {children}
          </Box> 
      </div>
    );
  }

export const TabButton = (props) =>{
  const {children,onClick, index, value} = props;
  if(value===index){
    return(
      <DiscoverButtonSelected variant="contained">{children}</DiscoverButtonSelected>
    )
  }else{
    return(
      <DiscoverButton variant="contained" onClick={onClick}>{children}</DiscoverButton>
    )
  } 
 
}
  
  export function tabbyProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }