import {  useEffect, useMemo, useState } from "react"


import NVD3Chart from 'react-nvd3'
import { infographicColour } from "../../common/ui/Colours";
import Loader from "../../common/ui/Loader";
import { Paper } from "@mui/material";

export default function BackTestResultVolatilityChart({backTestPortfolioData}){
    
    const [chartData, setChartData] = useState([])
     
    useEffect( () => {
        if(backTestPortfolioData.length>0){
            if(backTestPortfolioData[0].portfolioVolatility){
               var volData = { key: "Volatility"}
                var runData = backTestPortfolioData.map((hist) => {
                        const portfolioVolatility = parseFloat((hist.portfolioVolatility*100.0).toFixed(2))
                        var dataItem = { x: hist.portfolioDate, y: portfolioVolatility}
                        return dataItem
                })
                volData.values= runData 
                const c = infographicColour("Volatility")
                volData.color = c        
                setChartData([volData])
            }
        }
    },[backTestPortfolioData])





    

    const item = useMemo( ()=>{
        if(chartData.length===0){
             return <Loader/>
        }else{
            return <NVD3Chart sx={{p:'1rem'}} color={infographicColour("test")} id="line" type="lineChart" datum={chartData} x="x" y="y"
            xAxis={{
            tickFormat: (d) => new Date(d).toLocaleDateString(),
            margin: 20,
            }}
            height="500"
            margin={{top: 10, right: 50, bottom: 50, left: 50}}
            />
        }

   },[chartData])
   
        return(
          <Paper>
           {item}
         </Paper>
        )
    

}