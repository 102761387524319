import {useEffect, useState, useContext} from "react";

import CameraIcon from '@mui/icons-material/Camera';
import List from '@mui/material/List'

import {NestedParentListItemLink} from '../../common/Link'
import {EquilyContext} from '../../common/Context'
import {BenchmarkMenuItem} from './BenchmarkMenuItem'
import { AddBenchmarkMenuItem } from "./AddBenchmarkMenuItem";



export function BenchmarkMenu(props){

    const {benchmarks} = useContext(EquilyContext)
    const [benchmarkitems, setBenchmarkItems] = useState(null)

    useEffect(() => {

       if(benchmarks){
            const items = benchmarks.map((bm,index) => <BenchmarkMenuItem key={index} benchmark={bm}/>)
            const addBenchmark = <AddBenchmarkMenuItem key="AddModelPortfolio"></AddBenchmarkMenuItem>
            items.push(addBenchmark)

            const itemlist = 
            <List key="benchmarklist" component="div" disablePadding>
                {items}
            </List>



            setBenchmarkItems(itemlist)
       }
      }, [benchmarks]);
    
    return(
        <NestedParentListItemLink primary="Map Portfolio" icon={<CameraIcon />} children={benchmarkitems} />
    )

}