import {  Grid,Link } from "@mui/material";
import Title from "../common/ui/Title";
export function BackTestTitle({backTestName}) {
    
    return(
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
        <Title>
        {backTestName}
        </Title>
         </Grid>
    )
}

export function BackTestResultTitle({backTestName,backTestResult,clearResult}) {
    
    const clear = (e) =>{
        e.preventDefault()
        clearResult()
    }
    
    return(
        <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
        <Title>
        <Link key="select" color="inherit" href="/backtests" onClick={clear}>{backTestName}</Link> - {backTestResult.runId}
        </Title>
         </Grid>
    )
}