import React,{} from "react";
import {TreeView,TreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';




export default function BenchmarkTree(props){
    
    
    const benchmarkData = props.benchmarkData

    const label = (data) => {
      var root = data.label + " - " + data.weighting+"%"
      if(data.modelweighting) root = root + " (" + data.modelweighting.toFixed(2) + "%)"
      return root
    }

    const renderTree = (data) => {

        if(data){ 
          return(
            <TreeItem  key={data.uri} nodeId={data.uri} label={label(data)}>
              {Array.isArray(data.children) ? data.children.map((node) => renderTree(node)) : null}
            </TreeItem>
          )
        }        
    }

    const selectNode = ((event, allocationURI) => {
       props.selectAllocation(allocationURI)
    }
    )

    return(
            <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={selectNode}
            >
             {renderTree(benchmarkData)}
            </TreeView>
    )

}