import { Box } from "@mui/material";

export function SignInBox({children}){

    return(
        <Box sx={{ 
            flexGrow: 1, 
            overflow: 'hidden', 
            px: 5, 
            maxWidth: 350,
            minWidth: 350,
            borderRadius: '25px', 
            border: "5px",
            borderColor: "pantone.pantone1235",   
            backgroundImage: "url('/Equily_Faded_Icon_P125.png')",
            backgroundPosition: "left 100px top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px 300px",
            backgroundColor: "pantone.pantone1235"
        }}>
            {children}
        </Box>
    )

    
}

export function InverseSignInBox({children}){

    return(
        <Box sx={{ 
            flexGrow: 1, 
            overflow: 'hidden', 
            px: 5, 
            maxWidth: 350,
            minWidth: 350,
            borderRadius: '25px',    
            backgroundColor: "white",
            border: "5px",
            borderColor: "pantone.pantone1235",
            borderStyle: "solid",
            backgroundImage: "url('/Equily_Icon_Grey.png')",
            backgroundPosition: "left 115px top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px 300px",
        }}>
            {children}
        </Box>
    )

    
}