import React, {useContext,useEffect,useState,useCallback} from "react";

import { Box} from '@mui/material'


import {EquilyContext} from '../common/Context'
import BenchmarkChart from "./chart/BenchmarkChart";
import BenchmarkEdit from "./BenchmarkEdit";
import BenchmarkUpdateModel from "./BenchmarkUpdateModel";


export default function BenchmarkConfigure(props){


    const {benchmark,selectBenchmark,portfolios} = useContext(EquilyContext);
    const [benchmarkData, setBenchmarkData] = useState()
    const [portfolio, setPortfolio] = useState()
   
   

    const addInvestmentsToLabel = useCallback((invs) => {
      var label = ""
      if(invs){
          if(invs.length>0) label = label + " ["
          var first = true
          for (var y =0; y<invs.length; y++) {
              if(first){
                  label = label + invs[y]
                  first = false
              }
              else label = label + "," +  invs[y]
          }   
          if(invs.length>0) label = label + "]"
      }
      return label
  },[])

  const labelBenchmark = useCallback((node) => {
      var label = node.name
      if(node.cash.length>0) {
          label = label + addInvestmentsToLabel(node.cash)
      }
      if(node.investments.length>0) label = label + addInvestmentsToLabel(node.investments)
      return label
  },[addInvestmentsToLabel])


    const buildBenchmark = useCallback((benchmarkAllocation,portfolio) => {
      
      var node = {}
     
      if(benchmarkAllocation){ 
        
          node.name = benchmarkAllocation.allocation.allocationName
          node.weighting = benchmarkAllocation.allocation.weighting
          
          node.modelweighting = benchmarkAllocation.allocation.modelWeighting
          node.uri = benchmarkAllocation.uri
          node.children = []
          node.investments = []
          node.cash = []
          if(benchmarkAllocation.childBenchmarkAllocations){
              benchmarkAllocation.childBenchmarkAllocations.forEach( (child) => {
                  var childnode = buildBenchmark(child,portfolio)
                  node.children.push(childnode)
              })
          }
          if(benchmarkAllocation.investmentIds){
              benchmarkAllocation.investmentIds.forEach( (id) => {
                
                  var investment = portfolio.investments.find( inv => inv.investmentId===id)
                  if(investment){
                      node.investments.push(investment.stock.symbol)
                  }
              })
          }
          if(benchmarkAllocation.cashIds){
              benchmarkAllocation.cashIds.forEach( (id) => {
                  var cash = portfolio.cash.find( inv => inv.cashId===id)
                  if(cash){
                      node.cash.push(cash.currencyCode)
                  }
              })
          }
          node.label = labelBenchmark(node)
          if(node.children.length===0)node.value = benchmarkAllocation.allocation.modelWeighting
      }
      return node;
      },[labelBenchmark])

    

    useEffect(() => {
        if(benchmark){
            if(benchmark.benchmarkAllocation){
              // get the portfolio for the benchmark     
                var portfolio = portfolios.find( (p) => p.portfolioId===benchmark.portfolioId)
                var benchmarkdata = buildBenchmark(benchmark.benchmarkAllocation,portfolio)
                benchmarkdata.fullyMapped = benchmark.fullyMapped
                setBenchmarkData(benchmarkdata) 
                setPortfolio(portfolio)
            }
        }       
    }, [benchmark,buildBenchmark,portfolios]);

   
    return(

      <React.Fragment>
        <Box  sx={{'paddingBottom': '1rem', 'alignItems': 'left'}}>
              <BenchmarkUpdateModel benchmark={benchmark} setBenchmark={selectBenchmark}></BenchmarkUpdateModel>
              <BenchmarkEdit benchmarkName={benchmark.benchmarkName} setBenchmark={selectBenchmark} benchmarkData={benchmarkData} portfolio={portfolio}></BenchmarkEdit>
              </Box>
              <BenchmarkChart benchmarkData={benchmarkData}></BenchmarkChart>
        
    </React.Fragment>


    )

}