import { Grid, Divider, Link} from '@mui/material'
import React, { useState, useEffect,useContext} from 'react'

import { TabButton, TabPanel } from "../common/grids/TabPanel";
import ModelPortfolioAllocations from './detail/ModelPortfolioAllocations';
import  ModelPortfolioOverview  from './detail/ModelPortfolioOverview';
import ModelPortfolioPerformance from './detail/ModelPortfolioPerformance';
import {InvestorAccountContext} from '../auth/InvestorAccounts'
import { CallAPI } from '../common/services/api/CallAPI'

import ModelPortfolioSubscribe from './detail/ModelPortfolioSubscribe';
import { DecisionButton } from '../common/ui/Buttons';
export function ModelPortfolioStoreDetail({modelPortfolio, onClose}){

    const [tabValue,setTabValue] = useState(0)
    const [modelPortfolioDetail, setModelPortfolioDetail] = useState(null)
    const {getSession} = useContext(InvestorAccountContext)
    const [subscribeOpen,setSubscribeOpen] = useState(false)
   
    useEffect( () => {
      if(modelPortfolio){
        // get the detail....
        getSession().then( ({headers}) => {
          CallAPI('/store/modelportfolios/'+modelPortfolio.id,{method: 'GET',headers}).then( data =>{
            setModelPortfolioDetail(data)
        })
        .catch((error) => {
          console.log("There was an error " + error)
          console.log(error)
        })
      })
      }
    },[modelPortfolio,getSession])

   
  return(
        
        <React.Fragment>
            <Grid container direction="column" spacing={1}> 
                <Grid item><Link onClick={onClose}>Portfolio Store</Link>  - {modelPortfolio.name}</Grid>
                <Grid item>
                  
                  <Grid container direction="row" alignItems="center"  justifyContent="space-between">
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                              <TabButton value={tabValue} index={0} onClick={() => setTabValue(0)}>Allocations</TabButton>
                          </Grid>
          
                          <Grid item>
                              <TabButton value={tabValue} index={1} onClick={() => setTabValue(1)}>Performance</TabButton>
                          </Grid>
                          <Grid item>
                              <TabButton value={tabValue} index={2} onClick={() => setTabValue(2)}>Calculate</TabButton>
                          </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                    <DecisionButton size="small" variant="contained" onClick={() => setSubscribeOpen(true)}>
                        Subscribe
                    </DecisionButton>
                    </Grid>
                  </Grid>

                </Grid>
          
                <Grid item>
                  <Divider/>
                </Grid>

                <Grid item>
                <TabPanel value={tabValue} index={2}>
                    <ModelPortfolioOverview modelPortfolio={modelPortfolio} modelPortfolioDetail={modelPortfolioDetail}/>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                  <ModelPortfolioPerformance modelPortfolio={modelPortfolioDetail}/>
                  </TabPanel>
                  <TabPanel value={tabValue} index={0}>
                    <ModelPortfolioAllocations modelPortfolio={modelPortfolio}/>
                  </TabPanel>
                </Grid>
          </Grid>
          <ModelPortfolioSubscribe open={subscribeOpen} modelPortfolio={modelPortfolio} handleClose={() => setSubscribeOpen(false)}/>
        </React.Fragment>
    )

}

