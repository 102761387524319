
import React, {} from 'react'

import {Typography} from '@mui/material'

export default function SummaryViewCardRisk(props){

    const mp = props.modelPortfolio
   
    return(
        
        <React.Fragment>
        <Typography variant="overline">Risk Adjusted Return</Typography>
        <Typography  variant="subtitle2">
            {mp.rar.toFixed(2)}
          </Typography>      
        </React.Fragment>

    )

}