import React, {}  from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { InvestorAccountContext } from "./InvestorAccounts";




export function AuthenticatedRoute({ children, ...rest }) {
    const {user} = useContext(InvestorAccountContext)

  
    return user.username ? <>{children}</> : <Navigate to="/auth/login" />
}
  

  