import React, {useState, useEffect, useContext} from "react";

import {Grid, Fab, Paper, Box} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'


import MUIDataTable from "mui-datatables";

import {renderNumber } from "../../common/tables/Table";
import Title from '../../common/ui/Title'
import AddCash from "./AddCash";
import {EquilyContext} from '../../common/Context'
import EditCash from "./EditCash";


export default function Cash(props){

   
   
    const [open,setOpen] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)
    const [data,setData] = useState([])
    const [selectedCash,setSelectedCash] = useState()
    const {portfolio,setInvestorPortfolio, refreshBenchmarks} = useContext(EquilyContext)


    const cashcolumns = [
      { name: 'currency', label: 'Currency'},
      { name: 'amount', label: 'Amount',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'amount') }}},
      { name: 'value', label: 'Value in Portfolio',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'value') }}},
    ];
    
    
    const closeForm = () => {
       setOpen(false)
    }
    const closeEditForm = () => {
      setOpenEdit(false)
    }
    const selectCash = (row, rowmeta) => {
      setSelectedCash(data[rowmeta.dataIndex])
      setOpenEdit(true)
    }
    

    useEffect(() => {
      if(props.cash){    

        const calcs = props.cash.map( (row) => {
           const newrow = {
            cashId: row.cashId,
            currency: row.currency,
            amount: Number(row.amount),
            value: Number(row.value),
           }
           return newrow;
        })
        setData(calcs)
      }
    },[props.cash])
    
    const tableOptions = {
      filterType: 'checkbox',
      filter: false,
      print: false,
      caseSensitive: false,
      resizableColumns: false,
      selectableRows: 'none',
      draggableColumns: {
        enabled: true,
        transitionTime: 1000
      },
      onRowClick: selectCash,
      searchProps: {
        spellcheck: "false",
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off"
      },
    }

    return( 
    <React.Fragment>
       
       <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Title>
                Cash
            </Title> 
            <Fab color="primary" variant="extended" onClick={() => setOpen(true)}>
                <AddIcon/>
                Add Cash
            </Fab>
        </Grid>
        <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
          <Paper>
              <MUIDataTable data={data} columns={cashcolumns} options={tableOptions}/> 
          </Paper>
        </Box>
        <AddCash closeForm={closeForm} open={open} setInvestorPortfolio={setInvestorPortfolio} portfolio={portfolio}/>
        <EditCash closeForm={closeEditForm} open={openEdit} cash={selectedCash} setInvestorPortfolio={setInvestorPortfolio} portfolioName={portfolio.portfolioName} refreshBenchmarks={refreshBenchmarks}/>


    </React.Fragment>
      
    )

}