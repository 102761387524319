import React, {useState, useContext,useEffect} from 'react';

import { Alert, Grid, Fab, Box, Paper , Button, FormControl, TextField,CircularProgress} from '@mui/material'
import {DialogTitle,DialogContent,DialogActions,Dialog} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PasswordIcon from '@mui/icons-material/Password'

import Title from '../common/ui/Title'
import { InvestorAccountContext } from '../auth/InvestorAccounts';

import { CallAPI } from '../common/services/api/CallAPI'
import FormColumn from '../common/ui/FormColumn';
import { EquilyContext } from '../common/Context';

export default function MyAccountEquily(props){


    const {updateUser,getSession,changePassword} = useContext(InvestorAccountContext)
    const {investor} = useContext(EquilyContext);
    

  
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [edit, setEdit] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
   
    const [oldPassword, setOldPassword]  = useState("")
    const [newPassword, setNewPassword]  = useState("")
    const [confirmNewPassword, setConfirmNewPassword]  = useState("")
    


    const [updating, setUpdating] = useState()
    const [error, setError] = useState()
    const [passwordError, setPasswordError] = useState()
   
    useEffect( () =>{
        if(investor){
            setFirstName(investor.firstName)
            setSurname(investor.lastName)
            setEmail(investor.email)
        }
        

    },[investor])

    const updateInvestorAccount = () =>{
        setUpdating(true)
        setError()
        updateUser(firstName, surname).then( result => {
            getSession().then( (session) => { 
                var headers = session.headers
                const invdata = {
                    "firstName": firstName,
                    "lastName": surname, 
                }
                headers['Content-Type'] =  'application/json'
                CallAPI('/engine/investors/',{method: 'PUT',headers, body: JSON.stringify(invdata)}).then( data =>{
                    setUpdating(false)
                    setEdit(false)
                })
                .catch((error) => {
                    console.log("There was an error updating user" + error)
                    setError(<Alert severity="error">{error.message}</Alert>)
                });
            })
          
        }).catch( err => {
            console.log(err)
            setError(<Alert severity="error">{err.message}</Alert>)
            setUpdating(false)
        })
       
    }


    const updatePassword = () =>{
        setPasswordError()
        if(newPassword!==confirmNewPassword) setPasswordError(<Alert severity="error">New Passwords Do Not Match</Alert>)
        else{
           changePassword(oldPassword, newPassword).then( result => {
                setShowChangePassword(false)
                setPasswordError()
            }).catch( err => {
                console.log(err)
                setPasswordError(<Alert severity="error">{err.message}</Alert>)
            })
        }
    }
    return(
        <React.Fragment>

        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={9}>
          <Title>
           My Investor Account - {firstName}&nbsp;{surname}
          </Title> 

        {error}
          </Grid>
          <Grid item xs={3}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                
                <Tooltip title="Edit Account Details" aria-label="Edit">
                    <Fab  color="secondary" aria-label="run" zindex="tooltip" onClick={() => setEdit(!edit)}>
                        <EditIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title="Change Password" aria-label="ChangePassword">
                    <Fab  color="secondary" aria-label="run" zindex="tooltip" onClick={() => setShowChangePassword(true)}>
                        <PasswordIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title="Delete Account" aria-label="Delete">
                    <Fab  color="" aria-label="delete" zindex="tooltip" >
                        <DeleteForeverIcon />
                    </Fab>
                </Tooltip>
             
            </Grid>
          </Grid>
        </Grid>

        <Box  sx={{'paddingBottom': '1rem','paddingTop': '1rem', 'alignItems': 'left'}}>
          <Paper>
          <Box
                component="form"
                display="flex" flexDirection="column"
                sx={{
                    '& > :not(style)': { m:1, width: props.width },
                }}
                noValidate
                autoComplete="off"
                >
                    
                    <FormControl>
                        <TextField   disabled={!edit} value={firstName} onChange={e => setFirstName(e.target.value)} id="firstName" label="First Name" type="text" />
                    </FormControl>
                    <FormControl>
                        <TextField   disabled={!edit}  value={surname} onChange={e => setSurname(e.target.value)} id="surname" label="Family Name" type="text"  />
                   </FormControl>
                    <FormControl>
                        <TextField  disabled={true} value={email} id="email" label="Email" type="text"  />
                    </FormControl>
                    {edit ? 
                    <Button disabled={!edit} variant="contained" onClick={updateInvestorAccount} color="primary" tabIndex={5}>
                      {updating && <CircularProgress size={14} />}
                      {!updating && "Update Account"}
                    </Button>
                    : <div/>}
             </Box>
          </Paper>
        </Box>
   
    
        <Dialog
        open={showChangePassword}
        onClose={()=>setShowChangePassword(false)}
        aria-labelledby="confirm-change-password"
        aria-describedby="confirm change password"
      >
        <DialogTitle id="confirm-change-password">{"Change Password"}</DialogTitle>
        <DialogContent>
                    {passwordError}
                    <FormColumn>
                    <FormControl variant="outlined" fullWidth  >
                        <TextField value={oldPassword} onChange={e => setOldPassword(e.target.value)} id="currentPassword" label="Current Password" type="password" />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth  >
                        <TextField value={newPassword} onChange={e => setNewPassword(e.target.value)} id="newPassword" label="New Password" type="password" />
                    </FormControl>
                    <FormControl variant="outlined" fullWidth  >
                        <TextField value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} id="confirmNewPassword" label="Confirm New Password" type="password" />
                    </FormControl>
                    </FormColumn>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowChangePassword(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => updatePassword()} color="primary" autoFocus>
            Change
          </Button>
        </DialogActions>
      </Dialog>
     
    </React.Fragment>

    )



}