import React, {useState,useEffect} from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";

import { nonSelectTableOptions,renderNumber, renderCurrencyNumber} from "../common/tables/Table";
import MUIDataTable from "mui-datatables";



export default function RebalanceTrackerCurrentResult(props) {

    const result = props.currentResult
    const loading = props.loading
    const [data,setData] = useState([])

    const columns = [
      { name: 'actionType', label: 'Action Type'},
      { name: 'symbol', label: 'Stock' },
      { name: 'tradingVol', label: 'Trade Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'tradingVol') }}},
      { name: 'price', label: 'Price',options: {customBodyRenderLite: (dataIndex) => { return renderCurrencyNumber(data,dataIndex,'price',data[dataIndex].currencyCode) }}},
      { name: 'value', label: 'Trade Val.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'value') }}},
      { name: 'prePercentage', label: 'Pre %.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'prePercentage') }}},
      { name: 'postPercentage', label: 'Post %',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postPercentage') }}},
      { name: 'preValue', label: 'Pre Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preValue') }}},
      { name: 'postValue', label: 'Post Value',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postValue') }}},
      { name: 'preVol', label: 'Pre Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'preVol') }} },
      { name: 'postVol', label: 'Post Vol.',options: {customBodyRenderLite: (dataIndex) => { return renderNumber(data,dataIndex,'postVol') }}},
    
    ];
   
    useEffect(() => {
   
      if (result && result.actions) {
        const calcs = result.actions.map((row) => {
          var newrow = {
            actionType: row.actionType,
            symbol: row.stock.symbol,
            tradingVol: row.postVolume - row.preVolume,
            price: row.stock.price,
            value: row.stock.price * (row.postVolume - row.preVolume),
            prePercentage: row.prePercentage,
            postPercentage:row.postPercentage,
            preValue: row.preValue,
            postValue: row.postValue,
            preVol: row.preVolume,
            postVol: row.postVolume,
            currencyCode: row.stock.currencyCode,
          }
          return newrow
        })
        setData(calcs)
      }
    }, [result])
   
    if(result && !loading){
    return(
      <React.Fragment>
      <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item>
      <Typography variant="h6" gutterBottom component="div">
       Run Date
        </Typography>
        </Grid>
        <Grid item>
        <Typography variant="body1" gutterBottom>
        {new Date(result.runDate).toLocaleString()}
      </Typography>
      </Grid>
      </Grid>
        <Paper>
          <MUIDataTable
            data={data}
            columns={columns}
            options={nonSelectTableOptions}
          />
       </Paper>
      </React.Fragment>
    )
    }else if(loading){
        return(<div><CircularProgress size={24} /></div>)
    }else{
        return(<div>No results</div>)
    }

}