import { Button, Divider, Grid, Link, TextField, Typography } from "@mui/material";

import { FBButton, FBIcon, GButton, GoogleIcon } from "./SocialButtons";



export default function SignInContent({investorID, setInvestorID, password, setPassword, handleForgottenClick, handleLogin, facebookSignIn,googleSignIn }){

   
    return(
        <>
        <Grid sx={{mt:'1px'}} direction="column" container wrap="nowrap" spacing={2} alignItems="center" >
            <Grid item sx={{width: '100%'}} >
                <FBButton onClick={()=>facebookSignIn()} fullWidth startIcon={<FBIcon width="26px" height="26px" color="white"/>}>Sign in with Facebook</FBButton>
            </Grid>
            <Grid item sx={{width: '100%'}}>
                <GButton onClick={()=>googleSignIn()} variant="outlined" fullWidth startIcon={<GoogleIcon width="26px" height="26px" color="white"/>}>Sign in with Google</GButton>
            </Grid>
        </Grid>
        <Divider sx={{mt:'7px',mb: '7px'}}><Typography variant="body2">or continue with</Typography></Divider>
        <Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
           
            <Grid item sx={{width: '100%'}}>
                <TextField   variant="standard" fullWidth value={investorID} onChange={e => setInvestorID(e.target.value)} id="investorid" label="Email" />  
                </Grid>
            </Grid>
        <Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item sx={{width: '100%'}}>
             <TextField   variant="standard" fullWidth value={password} onChange={e => setPassword(e.target.value)} id="password" label="Password" type="password"  />
            </Grid>
           
        </Grid>
           
       
        <Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
                <Typography variant="caption"><Link href="#" onClick={e => handleForgottenClick(e)}>Forgot your Password</Link> </Typography>
            </Grid>
        </Grid>
        <Grid sx={{mb:'7px'}} container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Button variant="contained" onClick={handleLogin} color="primary">
                    Sign In
                </Button>
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
        <Typography  variant="caption">
            By clicking Sign in or Continue with Google or Facebook , you agree to Equily's <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://equily.io/terms-conditions">Terms of Use</Link> and <Link color="inherit" target="_blank" rel="noopener noreferrer" href="https://equily.io/privacy-policy">Privacy Policy</Link>. Equily may send you communications from time to time.
          </Typography>
          </Grid>
        </Grid>

        </>
    )
}