
import React,{useState} from "react";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import AddIcon from '@mui/icons-material/Add'
import { AddRebalanceTracker } from "../AddRebalanceTracker";
import { EquilyListItemText } from "../../common/Link";



export function AddRebalanceTrackerMenuItem(props){
    
   
    const [open,setOpen] = useState(false)
       
    return(
        <React.Fragment>
            <ListItem key='AddRebalanceTracker'  id='AddRebalanceTracker' button sx={{ pl: 4 }}>
                <ListItemIcon>
                     <AddIcon />
                </ListItemIcon>
                <EquilyListItemText key='AddRebalanceTrackerText' primary='Add Tracker' onClick={() => setOpen(true)}/>
            </ListItem>
      
            <AddRebalanceTracker open={open} handleClose={ () => setOpen(false)}/>

      </React.Fragment>
    )

}