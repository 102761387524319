
import React, { useEffect, useState } from 'react'

import {Typography} from '@mui/material'

export default function SummaryViewCardAllocation(props){

    const mp = props.modelPortfolio

    const [length, setLength] = useState("-")

    useEffect( () =>{
        if(mp && mp.investments){
            setLength(mp.investments.length)
        }
    },[mp])
   
    return(
        
        <React.Fragment>
        <Typography variant="overline">Number of Allocations</Typography>
        <Typography  variant="subtitle2">
            {length}
          </Typography>
        </React.Fragment>

    )

}