import React, {useState} from "react";

import { Divider, Grid } from "@mui/material";

import RebalanceTrackerCurrentResult from "./RebalanceTrackerCurrentResult";
import RebalanceTrackerHistoricResults from "./RebalanceTrackerHistoricResults";
import { TabButton, TabPanel } from "../common/grids/TabPanel";



export default function RebalanceTrackerResults(props) {

    const [tabValue,setTabValue] = useState(0)
  
  
    return(

        <Grid container direction="column" spacing={1}> 
        <Grid item>
          <Grid container spacing={2}>
              <Grid item>
                  <TabButton value={tabValue} index={0} onClick={() => setTabValue(0)}>Current</TabButton>
              </Grid>
              <Grid item>
                  <TabButton value={tabValue} index={1} onClick={() => setTabValue(1)}>History</TabButton>
              </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider/>
        </Grid>
        <Grid item>
        <TabPanel value={tabValue} index={0}>
        <RebalanceTrackerCurrentResult loading={props.loading} currentResult={props.currentResult}/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <RebalanceTrackerHistoricResults loading={props.loading} historicResults={props.historicResults}/>
      </TabPanel>
        </Grid>
        </Grid>


    )


}