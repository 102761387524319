import React,{useContext} from "react";
import {EquilyContext} from '../../common/Context'

import { useNavigate  } from "react-router-dom";

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { EquilyListItemText } from "../../common/Link";




export function MyPorfolioMenuItem(props){


    const {selectBenchmark} = useContext(EquilyContext);
    let navigate = useNavigate ();

   
    const selectTheModelPortfolioDash = () => {
        selectBenchmark(props.benchmark)
        navigate("/myportfolios");
    }

    return(
            <React.Fragment>
            <ListItem key={props.benchmark.benchmarkName+"MyPortfolio"} button sx={{ pl: 4 }} onClick={selectTheModelPortfolioDash}>
                <ListItemIcon key={props.benchmark.benchmarkName+"BenchIcon"}>
                     <AssessmentOutlinedIcon />
                </ListItemIcon>
                <EquilyListItemText key={props.benchmark.benchmarkName+"MyPortfolioText"} primary={props.benchmark.benchmarkName} />
            </ListItem>
            </React.Fragment>
       
    )

}