
import React, { useState } from "react";
import { Grid, Typography,Avatar ,Divider, Tooltip, Collapse} from "@mui/material"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import EditIcon from '@mui/icons-material/Edit';
import { toCurrency } from "../../common/ui/StringUtils";
import MyPortfolioEditInvestment from "./MyPortfolioEditInvestment";

const MyPortfolioInvestmentItem = (props) => {

    const investment = props.investment
    const portfolioCurrencyCode = props.portfolioCurrencyCode
    const portfolioName = props.portfolioName
    const updatePortfolio = props.updatePortfolio


    const [expanded,setExpanded] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

   
    return(

        <React.Fragment>
        <Grid container direction="column">
        <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={5}>
                    <Typography variant="subtitle1" color="primary">
                        {investment.stock.symbol}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                        <Tooltip title="Price">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="inherit">
                            { toCurrency(Number(investment.stock.price.toFixed(2)),investment.stock.currencyCode)}
                            </Typography>
                        </Grid>
                        </Tooltip>
                        <Tooltip title="Volume">
                        <Grid item xs={3}>
                                     <Typography variant="subtitle2" color="inherit">
                                { Number(investment.volume.toFixed(2)).toLocaleString(undefined,{maximumFractionDigits: 0, minimumFractionDigits: 0}) }
                                    </Typography>
                         
                        </Grid>
                        </Tooltip>
                        <Tooltip title="Value">
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="inherit">
                            { toCurrency(Number(investment.value.toFixed(2)),portfolioCurrencyCode)}
                            </Typography>
                        </Grid>
                        </Tooltip>
                        <Grid item xs={2}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '5px',
                                    marginLeft: 1.875
                                }}
                                onClick={ () => setExpanded(!expanded)}
                            >
                                {expanded? <KeyboardArrowUpOutlinedIcon fontSize="small" color="inherit" />: <KeyboardArrowDownOutlinedIcon fontSize="small" color="inherit" />}
                            </Avatar>
                        </Grid>
                        <Grid item xs={1}>
                            <EditIcon   sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '5px',
                                    marginTop: '5px'
                                }}
                                onClick={ () => setEditOpen(true)}variant="rounded"/>
                           
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item>
            <Typography variant="subtitle2" color="secondary" sx={{  }}>
                {investment.stock.type}
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="subtitle2" color="secondary" sx={{  }}>
                {investment.stock.name}
            </Typography>
        </Grid>
        </Collapse>
    </Grid>
    <Divider sx={{ my: 1.5 }} />
    <MyPortfolioEditInvestment open={editOpen} closeForm={() => setEditOpen(false)} investment={investment} portfolioName={portfolioName} updatePortfolio={updatePortfolio}/>
    </React.Fragment>
    )


}

export default MyPortfolioInvestmentItem