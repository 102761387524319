import { Fab, Grid, Tooltip } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';
import BackTestCopy from "./BackTestCopy";
import { useState } from "react";
import BackTestDelete from "./BackTestDelete";
import BackTestEdit from "./BackTestEdit";


export default function BackTestControls({backTest, runBackTest, runnable,setBackTest,refreshBackTests,handleStatus,benchmarks,disabled}){

const [copyOpen, setCopyOpen] = useState(false)
const [deleteOpen, setDeleteOpen] = useState(false)
const [editOpen, setEditOpen] = useState(false)




return(
    <Grid container direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
        <Grid item>
            <Tooltip title="Run BackTest" aria-label="Run">
                <span>
                    <Fab disabled={(!runnable || disabled)} color="secondary" aria-label="run" zindex="tooltip" onClick={runBackTest}>
                        <PlayArrowIcon />
                    </Fab>
                </span>
            </Tooltip>
        </Grid>
    <Grid item>
        <Tooltip title="Copy BackTest" aria-label="Copy">
        <span>
            <Fab  disabled={disabled} color="secondary" aria-label="copy" zindex="tooltip" onClick={()=> setCopyOpen(true)}>
                <FileCopyIcon />
            </Fab>
        </span>
        </Tooltip>
    </Grid>
    <Grid item>
        <Tooltip title="Edit BackTest" aria-label="Edit">
        <span>
        <Fab  disabled={disabled} color="secondary" aria-label="edit" zindex="tooltip" onClick={() =>  setEditOpen(true)}>
            <EditIcon />
        </Fab>
        </span>
        </Tooltip>
    </Grid>
    <Grid item>
        <Tooltip title="Delete BackTest" aria-label="Delete">
            <span>
            <Fab disabled={disabled} color="secondary" aria-label="delete" zindex="tooltip" onClick={() => setDeleteOpen(true)}>
                <DeleteForeverIcon />
            </Fab>
            </span>
        </Tooltip>
    </Grid>
    {!disabled && (<>
        <BackTestCopy open={copyOpen} handleClose={() => setCopyOpen(false)} backTestName={backTest.backTestName} handleStatus={handleStatus} setBackTest={setBackTest} refreshBackTests={refreshBackTests}/>
        <BackTestDelete open={deleteOpen} handleClose={() => setDeleteOpen(false)} backTestName={backTest.backTestName} handleStatus={handleStatus}  refreshBackTests={refreshBackTests}/>
        <BackTestEdit open={editOpen} handleClose={() => setEditOpen(false)} benchmarkName={backTest.benchmarkName} backTestName={backTest.backTestName} handleStatus={handleStatus} refreshBackTests={refreshBackTests} benchmarks={benchmarks} setBackTest={setBackTest}/>
        </>)}
    </Grid>
)

}