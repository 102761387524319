import React, { useEffect, useState, useContext , useMemo} from "react";


import { Grid} from '@mui/material'
import Alert from '@mui/material/Alert'
import MUIDataTable from "mui-datatables"

import { CallAPI } from '../common/services/api/CallAPI'
import LoadingTableBody from "../common/tables/LoadingTableBody";

import Title from '../common/ui/Title'

import {InvestorAccountContext} from '../auth/InvestorAccounts'

const columns = [
  { name: 'investorName', label: 'Investor Name'},
  { name: 'firstName', label: 'First name'},
  { name: 'lastName', label: 'Last name'},
  { name: 'dateOfBirth', label: 'DOB'},
  { name: 'joinedDate', label: 'Joined Date'},
];

/*
const columns = [
  { field: 'investorName', headerName: 'Investor Name', flex: 1,},
  { field: 'firstName', headerName: 'First name',flex: 1,},
  { field: 'lastName', headerName: 'Last name',flex: 1,},
  { field: 'dateOfBirth', headerName: 'DOB',flex: 1,type: 'date'},
  { field: 'joinedDate', headerName: 'Joined Date',flex: 1,type: 'date'},
]*/



function Investors(props) {



  const { getSession } = useContext(InvestorAccountContext)
  const [loading, setLoading] = useState(true)

  const tableOptions = {
    filterType: 'checkbox',
    filter: false,
    print: false,
    caseSensitive: false,
    resizableColumns: false,
    draggableColumns:{
      enabled:true, 
      transitionTime: 1000
    },
    searchProps: {
      spellcheck: "false",
      autocomplete: "off",
      autocorrect: "off",
      autocapitalize: "off"
    },
  }
  
  
  const [error, setError] = useState(); 
  const [investors, setInvestors] = useState([])
  
  
  const [investorData, setInvestorData] = useState([])



  useEffect(() => {

    getSession().then(({headers}) => CallAPI('/engine/investors/investors',{method: 'GET',headers}).then( data =>{
      data.forEach(inv => {
        inv.joinedDate = new Date(inv.joinedDate)
        inv.dateOfBirth = new Date(inv.dateOfBirth)
      })
      setInvestors(data)
      setLoading(false)
     
    }))
    .catch((error) => {
      console.log("There was an error " + error)
      setError(<Alert severity="error">Could not fetch Investors</Alert>)
    })

  }, [getSession]);

  useEffect(() => {

    if(investors){

      
      //mui-datatables
      const data = investors.map( row => {
        var newrow = {
          investorName: row.investorName,
          firstName: row.firstName,
          lastName: row.lastName,
          dateOfBirth: row.dateOfBirth.toLocaleDateString(),
          joinedDate: row.joinedDate.toLocaleDateString(),
        }
        return newrow
      })
     /*
      const data = investors.map( row => {
        var newrow = {
          id: row.investorName,
          investorName: row.investorName,
          firstName: row.firstName,
          lastName: row.lastName,
          dateOfBirth: row.dateOfBirth.toLocaleDateString(),
          joinedDate: row.joinedDate.toLocaleDateString(),
        }
        return newrow
      })

      */
      setInvestorData(data)
    }
    

  }, [investors]);

  
  

  const BodyComponent = useMemo(
    () => (props) => (
      <LoadingTableBody loading={loading} {...props} />
    ),
    [loading]
  )
   /*
 const selectInvestor = (investorId) => {
   var inv = investors.find( i => i.investorName.trim()===investorId[0])
   setEquilyInvestor(inv)
 
       
         <DataGrid rows={investorData} columns={columns}  onSelectionModelChange={itm => selectInvestor(itm)} />
       */


  return (

    <React.Fragment>
      
        
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {error}
        <Title>
            Investors
        </Title>      
      </Grid>
      
      <div style={{ height: 300, width: '100%' }}>
      <MUIDataTable 
         data={investorData} 
         columns={columns} 
         options={tableOptions}
         components={{ TableBody: BodyComponent }}
        /> 
       </div>
     
    </React.Fragment>




  );


}


export default Investors;